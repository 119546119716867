import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';

const ServiceStagePrintInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = ServicesApi.update(data.id, "stage_print_invoice");

    const handlePrintInvoice = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/proformainvoices/${data.proforma_invoice_id}/invoice/${action}`);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"print_invoice"}
            heading={"Print Proforma Invoice"}
            editCaps={["stage_print_invoiceServices"]}
            editable={false}
            showForm={data.print_invoice !== "yes"}
            showMeta={data.print_invoice === "yes"}
            passed={data.print_invoice === "yes"}
            showSave={form.changed}
            columnA={[

            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <ButtonRow>

                                <Button
                                    label={"Print Invoice"}
                                    type={data.print_invoice !== "yes" ? "primary" : "faded"}
                                    size={'regular'}
                                    onClick={handlePrintInvoice.bind(null, "print")}
                                />

                                <Button
                                    label={"Preview"}
                                    type={"ghost"}
                                    size={'regular'}
                                    onClick={handlePrintInvoice.bind(null, "preview")}
                                />

                            </ButtonRow>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStagePrintInvoice;
