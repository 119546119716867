import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldCheckbox, FieldRelationship, FieldSelect, FieldUpload, useApp, useUpdateEffect } from '@dex/bubl-dash';
import MerchantsApi from '../../Merchants/MerchantsApi';

const HelpdeskTicketsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const merchant = MerchantsApi.getOne(":id");

    useUpdateEffect(() => {

        if (!form.values.merchant_id) return;

        merchant.run({
            url: merchant.options.url?.replace(":id", form.values.merchant_id),
            onComplete: (data) => {

                form.handleChange({ name: "person", value: data.person, silent: true, reset: true });
                form.handleChange({ name: "phone", value: data.phone, silent: true, reset: true });

            }
        });

    }, [form.values.merchant_id]);

    return useMemo(() => (

        <Panel
            heading={"Ticket Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            {mode === "create" && (
                                <>

                                    <Col col={{ xs: 24 }}>

                                        <FieldRadio
                                            button
                                            label='Request By'
                                            form={form}
                                            name='by'
                                            required={true}
                                            defaultValue={form.defaults.by}
                                            options={[
                                                { value: "merchant", label: "Merchant" },
                                                { value: "other", label: "Other" },
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ xs: 24 }}>

                                        {form.values.by === "merchant" &&
                                            <FieldRelationship
                                                form={form}
                                                label={"Merchant (Company Name | Status |Serial Number | TID)"}
                                                name='merchant_id'
                                                required={true}
                                                model={'Merchants'}
                                                preload={true}
                                                defaultValue={form.defaults.merchant_id}
                                            />
                                        }

                                    </Col>
                                </>

                            )}

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    dynamicKey
                                    form={form}
                                    label={"Contact Person"}
                                    name='person'
                                    required={true}
                                    defaultValue={form.values.person}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    dynamicKey
                                    form={form}
                                    label={"Phone"}
                                    name='phone'
                                    required={true}
                                    defaultValue={form.values.phone}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Topic"}
                                    name='topic'
                                    required={true}
                                    defaultValue={form.defaults.topic}
                                    options={[
                                        { value: "service_request", label: app.intl("helpdesk.topic.service_request") },
                                        { value: "paper_roll", label: app.intl("helpdesk.topic.paper_roll") },
                                        { value: "training", label: app.intl("helpdesk.topic.training") },
                                        { value: "general_inquiry", label: app.intl("helpdesk.topic.general_inquiry") },
                                        { value: "installation", label: app.intl("helpdesk.topic.installation") },
                                        { value: "sales", label: app.intl("helpdesk.topic.sales") },
                                        { value: "finance", label: app.intl("helpdesk.topic.finance") },
                                    ]}
                                />

                            </Col>

                            {form.values.topic && (form.values.topic === "general_inquiry" ||
                                form.values.topic === "installation" ||
                                form.values.topic === "sales" ||
                                form.values.topic === "finance") && (
                                    <Col col={{ xs: 24 }}>

                                        <FieldText
                                            form={form}
                                            label={"Subject"}
                                            name='subject'
                                            required={true}
                                            defaultValue={form.defaults.subject}
                                        />

                                    </Col>
                                )}

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldUpload
                            form={form}
                            label={"Attachments"}
                            name='attachments'
                            required={false}
                            defaultValue={form.defaults.attachments}
                            limit={5}
                            folder={'admin-media'}
                        />

                    </Col>

                </Row>

                {form.values.topic === "service_request" && (

                    <>
                        <Line />

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Issue"}
                                    name='issue'
                                    required={false}
                                    list
                                    defaultValue={form.defaults.issue}
                                    options={[
                                        { value: "no_power", label: app.intl("helpdesk.issue.no_power") },
                                        { value: "no_line", label: app.intl("helpdesk.issue.no_line") },
                                        { value: "chip_malfunction", label: app.intl("helpdesk.issue.chip_malfunction") },
                                        { value: "carrier_lost", label: app.intl("helpdesk.issue.carrier_lost") },
                                        { value: "carrier_timeout", label: app.intl("helpdesk.issue.carrier_timeout") },
                                        { value: "cannot_print", label: app.intl("helpdesk.issue.cannot_print") },
                                        { value: "printout_not_clear", label: app.intl("helpdesk.issue.printout_not_clear") },
                                        { value: "credit_card_declined", label: app.intl("helpdesk.issue.credit_card_declined") },
                                        { value: "software_update", label: app.intl("helpdesk.issue.software_update") },
                                        { value: "none_of_the_above", label: app.intl("helpdesk.issue.none_of_the_above") },
                                    ]}
                                />

                            </Col>

                            {form.values.issue === "no_power" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.no_power'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.no_power}
                                        options={[
                                            { value: "a) Press the ON button in the terminal", label: "a) Press the ON button in the terminal" },
                                            { value: "b) Check to ensure plug is properly connected to socket.", label: "b) Check to ensure plug is properly connected to socket." },
                                            { value: "c) Check to ensure the power connector is properly connected to terminal.", label: "c) Check to ensure the power connector is properly connected to terminal." },
                                            { value: "d) Change the power plug to a different power socket.", label: "d) Change the power plug to a different power socket." },
                                            { value: "e) No solution - Assign onsite technician", label: "e) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "no_line" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.no_line'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.no_line}
                                        options={[
                                            { value: "a) Check the telephone connector to ensure it is securely connected to the telephone splitter, and splitter securely connect to the telephone socket on the wall.", label: "a) Check the telephone connector to ensure it is securely connected to the telephone splitter, and splitter securely connect to the telephone socket on the wall." },
                                            { value: "b) Use your fixed line to call your mobile.", label: "b) Use your fixed line to call your mobile." },
                                            { value: " - b1) Phone Line Works? Proceed.", label: " - b1) Phone Line Works? Proceed." },
                                            { value: " - b2) Not Working? fixed line having problem, call TM to check.", label: " - b2) Not Working? fixed line having problem, call TM to check." },
                                            { value: "c) Is there someone talking on the fixed phone when you are making a credit card transaction?", label: "c) Is there someone talking on the fixed phone when you are making a credit card transaction?" },
                                            { value: "d) Did you connect your terminal to a new telephone socket? - Connect back the terminal to the original telephone socket.", label: "d) Did you connect your terminal to a new telephone socket? - Connect back the terminal to the original telephone socket." },
                                            { value: "e) No solution - Assign onsite technician", label: "e) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "chip_malfunction" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.chip_malfunction'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.chip_malfunction}
                                        options={[
                                            { value: "a) Ask customer for another credit card.", label: "a) Ask customer for another credit card." },
                                            { value: "b) If customer does not have another card. Please use your own credit/ATM card to insert into the terminal.", label: "b) If customer does not have another card. Please use your own credit/ATM card to insert into the terminal." },
                                            { value: " - b1) Terminal shows no problem, the problem is with customer's credit card.", label: " - b1) Terminal shows no problem, the problem is with customer's credit card." },
                                            { value: " - b2) Not Working? Proceed.", label: " - b2) Not Working? Proceed." },
                                            { value: "c) Turn off the terminal, and turn on back. Try to read the card again.", label: "c) Turn off the terminal, and turn on back. Try to read the card again." },
                                            { value: "d) No solution - Assign onsite technician", label: "d) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "carrier_lost" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.carrier_lost'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.carrier_lost}
                                        options={[
                                            { value: "a) Check whether another person talking on the same telephone?", label: "a) Check whether another person talking on the same telephone?" },
                                            { value: " - a1) Yes? Ask the person to hangup the phone and redo the credit card transaction.", label: " - a1) Yes? Ask the person to hangup the phone and redo the credit card transaction." },
                                            { value: "b) Check the telephone wire at both ends properly connected to wall socket and terminal.", label: "b) Check the telephone wire at both ends properly connected to wall socket and terminal." },
                                            { value: "c) No solution - Assign onsite technician", label: "c) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "carrier_timeout" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.carrier_timeout'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.carrier_timeout}
                                        options={[
                                            { value: "a) Try another transaction again.", label: "a) Try another transaction again." },
                                            { value: "b) No solution - Assign onsite technician or PMA Inform Acquirer", label: "b) No solution - Assign onsite technician or PMA Inform Acquirer" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "cannot_print" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.cannot_print'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.cannot_print}
                                        options={[
                                            { value: "a) Turn off the terminal, and turn on back. Try to print a new receipt.", label: "a) Turn off the terminal, and turn on back. Try to print a new receipt." },
                                            { value: "b) Ensure printer cover close properly with 1 cm paper clamp in between.", label: "b) Ensure printer cover close properly with 1 cm paper clamp in between." },
                                            { value: "c) No solution - Assign onsite technician", label: "c) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "printout_not_clear" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.printout_not_clear'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.printout_not_clear}
                                        options={[
                                            { value: "a) Ensure printer cover close properly with 1com paper clamp in between.", label: "a) Ensure printer cover close properly with 1com paper clamp in between." },
                                            { value: "b) Change a new paper roll.", label: "b) Change a new paper roll." },
                                            { value: "c) No solution - Assign onsite technician", label: "c) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "credit_card_declined" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.credit_card_declined'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.credit_card_declined}
                                        options={[
                                            { value: "a) Card Expired or credit limit exceeded.", label: "a) Card Expired or credit limit exceeded." },
                                            { value: " - a1) Ask customer for another credit card.", label: " - a1) Ask customer for another credit card." },
                                            { value: " - a2) Ask customer to contact their bank that issue them their card.", label: " - a2) Ask customer to contact their bank that issue them their card." },
                                        ]}
                                    />

                                </Col>

                            )}

                            {form.values.issue === "none_of_the_above" && (

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={app.intl(`helpdesk.issue.${form.values.issue}`)}
                                        name='issues.none_of_the_above'
                                        required={false}
                                        list
                                        defaultValue={form.defaults.issues?.none_of_the_above}
                                        options={[
                                            { value: "a) Turn off the terminal, and turn on back. Try to print a new receipt.", label: "a) Turn off the terminal, and turn on back. Try to print a new receipt." },
                                            { value: "b) No solution - Assign onsite technician", label: "b) No solution - Assign onsite technician" },
                                        ]}
                                    />

                                </Col>

                            )}

                        </Row>


                        {mode === "create" &&
                            <>
                                <Line />

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 24, md: 16 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Next Step"}
                                            name='next_step'
                                            required={false}
                                            defaultValue={form.defaults.next_step}
                                            options={[
                                                { value: "open", label: "Open" },
                                                { value: "resolved", label: "Resolved" },
                                                { value: "assign_technician", label: "Assign Technician" },
                                            ]}
                                        />

                                    </Col>

                                </Row>
                            </>
                        }

                    </>

                )}

                {form.values.topic === "paper_roll" && (
                    <>
                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Roll Size"}
                                    name='roll_size'
                                    required={false}
                                    defaultValue={form.defaults.roll_size}
                                    options={[
                                        { value: "5", label: "5" },
                                        { value: "10", label: "10" },
                                        { value: "20", label: "20" },
                                        { value: "40", label: "40" },
                                        { value: "100", label: "100" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </>
                )}

                {form.values.topic !== "service_request" && (
                    <>
                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Message"}
                                    name='message'
                                    required={false}
                                    defaultValue={form.defaults.message}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Priority"}
                                    name='priority'
                                    required={true}
                                    defaultValue={form.defaults.priority}
                                    options={[
                                        { value: "normal", label: "Normal" },
                                        { value: "high", label: "High" },
                                        { value: "urgent", label: "Urgent" },
                                    ]}
                                />

                            </Col>

                        </Row>

                    </>
                )}

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [form.hash, process.loading]);

}

export default HelpdeskTicketsCreateEditForm;
