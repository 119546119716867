import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import ProformaInvoicesApi from '../ProformaInvoicesApi';
import Stage from '../../../elements/Stage/Stage';
import { Alert, FieldRadio, FieldRelationship, ModelLink } from '@dex/bubl-dash';

const ProformaInvoicesStagesLinkToInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ProformaInvoicesApi.update(data.id, "stage_link_to_invoice");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"link_to_invoice"}
            heading={"Link to Invoice"}
            editCaps={["stage_link_to_invoiceProformaInvoices"]}
            editable={false}
            showForm={!data.link_to_invoice}
            showMeta={data.link_to_invoice}
            showSave={form.changed}
            passed={data.link_to_invoice}
            columnA={[

                {
                    key: "invoice_id",
                    label: "Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="Invoices" />
                    )
                },
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name='link_to_invoice'
                                label={"Link to Invoice"}
                                defaultValue={form.defaults.link_to_invoice}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />
                        </Col>

                        {form.values.link_to_invoice === "yes" &&
                            <>
                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Link To Invoice No"}
                                        name={"linked_invoice_id"}
                                        defaultValue={form.defaults.linked_invoice_id}
                                        required
                                        model={"Invoices"}
                                        preload={true}
                                        where={{ status: { inq: ["paid", "partially_paid"] } }}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Alert
                                        type='info'
                                        message=''
                                        description={"Only Invoice ID and Proforma ID will be linked upon saving."}
                                    />

                                </Col>


                            </>
                        }

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ProformaInvoicesStagesLinkToInvoice;
