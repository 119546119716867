import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import { DataTable, FieldNumber, FieldTextArea, InputTableCell, InputTableRow, useApp } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import { merchantMidTidList } from '../../../functions/sharedMerchant';

const MerchantsMidTidForm: React.FC<any> = (props: any) => {

    const { form, data, } = props;

    const app = useApp();

    const [settings] = app.store("appSettings");

    const idList = useMemo(() => {

        return merchantMidTidList(settings, data);

    }, []);

    const columns = [
        {
            label: "Scheme",
            key: "scheme",
        },
        {
            label: "MID",
            key: "mid"
        },
        {
            label: "TID",
            key: "tid"
        },
        {
            label: "Min Amount",
            key: "min"
        },
        {
            label: "Max Amount",
            key: "max"
        },
        {
            label: "Remarks",
            key: "remarks"
        }
    ];

    return (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"mid_prefix"}
                                label={"8 Digit MID Prefix"}
                                defaultValue={form.defaults.mid_prefix}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"amex_prefix"}
                                label={"AMEX EzPay MID Prefix"}
                                defaultValue={form.defaults.amex_prefix}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"mid"}
                                required={true}
                                label={"Primary MID"}
                                defaultValue={form.defaults.mid}
                                maxLength={15}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"tid"}
                                required={true}
                                label={"Primary TID"}
                                defaultValue={form.defaults.tid}
                                maxLength={8}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"wifi_password"}
                                label={"Wifi Password"}
                                defaultValue={form.defaults.wifi_password}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldText
                                form={form}
                                name={"qr_master_key"}
                                label={"QR Master Key"}
                                defaultValue={form.defaults.qr_master_key}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <FieldTextArea
                        form={form}
                        name={"config_notes"}
                        label={"Config Notes"}
                        defaultValue={form.defaults.config_notes}
                        rows={6}
                    />


                </Col>

                <Col col={{ xs: 24 }}>

                    <DataTable
                        data={null}
                        columns={columns}
                        className={"mini-table input-table"}
                    >

                        {idList.map((row: any, index: number) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ width: 160, paddingLeft: 12 }}>
                                    {row.label}
                                </InputTableCell>

                                <InputTableCell style={{ width: 160 }}>

                                    <FieldText
                                        key={row.usePrimary ? form.values.mid : "mid"}
                                        form={form}
                                        name={`midtid.${row.key}.mid`}
                                        defaultValue={row.usePrimary ? form.values.mid : get(form.defaults, `midtid.${row.key}.mid`, '')}
                                        disabled={row.usePrimary}
                                        hidden={row.usePrimary}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 160 }}>

                                    <FieldText
                                        key={row.usePrimary ? form.values.tid : "tid"}
                                        form={form}
                                        name={`midtid.${row.key}.tid`}
                                        defaultValue={row.usePrimary ? form.values.tid : get(form.defaults, `midtid.${row.key}.tid`, '')}
                                        disabled={row.usePrimary}
                                        hidden={row.usePrimary}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 160 }}>

                                    {row.hasLimits &&
                                        <FieldNumber
                                            form={form}
                                            name={`midtid.${row.key}.min`}
                                            defaultValue={get(form.defaults, `midtid.${row.key}.min`, 0.0)}
                                        />
                                    }

                                </InputTableCell>

                                <InputTableCell style={{ width: 160 }}>

                                    {row.hasLimits &&
                                        <FieldNumber
                                            form={form}
                                            name={`midtid.${row.key}.max`}
                                            defaultValue={get(form.defaults, `midtid.${row.key}.max`, 0.0)}
                                        />
                                    }

                                </InputTableCell>
                                <InputTableCell style={{ width: 260 }}>

                                    {row.hasRemarks &&
                                        <FieldText
                                            form={form}
                                            name={`midtid.${row.key}.remarks`}
                                            defaultValue={get(form.defaults, `midtid.${row.key}.remarks`, "")}
                                        />
                                    }

                                </InputTableCell>

                            </InputTableRow>

                        ))}

                    </DataTable>

                </Col>

            </Row>

        </>

    );

}

export default MerchantsMidTidForm;
