import React, { useEffect } from 'react';
import { Modal, Panel } from '@dex/bubl-dash';
import StockApi from '../StockApi';

const StockSingleDepartmentHistory: React.FC<any> = (props: any) => {

    const { data, department, onClose } = props;

    const history = StockApi.getHistory(data.id, department);

    useEffect(() => {

        history.run();

        return () => {
            history.cancel();
        }

    })

    return (

        <Modal
            show={true}
            width={720}
            onClose={onClose}
        >

            <Panel
                heading={department}
            >
            </Panel>

        </Modal>

    );

}

export default StockSingleDepartmentHistory;
