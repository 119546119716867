import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import TerminalsCreateEditForm from "./Components/TerminalsCreateEditForm";
import TerminalsApi from "./TerminalsApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: TerminalsCreateEditForm,
                getCreate: TerminalsApi.create,
                getClone: TerminalsApi.getOne,
                defaults: {
                    type: "desktop"
                },
                successCallback: (response, app, nav) => {
                    const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { batch: response.batch } };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

}