import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ReplacementsApi from '../ReplacementsApi';
import { FieldDate } from '@dex/bubl-dash';

const ReplacementStagesDelivery: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ReplacementsApi.update(data.id, "stage_delivery");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"delivery"}
            heading={"Delivery"}
            editable={false}
            showForm={data.delivery_date == null}
            showMeta={data.delivery_date}
            showSave={form.changed}
            passed={data.delivery_date}

            columnA={[
                {
                    label: "Delivered Date",
                    key: "delivery_date",
                    format: 'date'
                },
            ]}
            columnB={[
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldDate
                                form={form}
                                name={"delivery_date"}
                                required={true}
                                label={"Delivery Date"}
                                defaultValue={form.defaults.delivery_date || ""}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ReplacementStagesDelivery;
