import SettingsApplications from "./Components/SettingsApplications";
import SettingsChecklists from "./Components/SettingsChecklists";
import SettingsGeneral from "./Components/SettingsGeneral";
import SettingsDepartments from "./Components/SettingsDepartments";

export default function (app) {

    app.mods.Settings = {
        groups: [
            // {
            //     key: "general",
            //     label: "General Settings",
            //     caps: ["saveSettings"],
            //     panel: SettingsGeneral
            // },
            {
                key: "applications",
                label: "Merchant Applications",
                caps: ["saveSettings"],
                panel: SettingsApplications
            },
            {
                key: "checklists",
                label: "Checklists",
                caps: ["saveSettings"],
                panel: SettingsChecklists
            },
            {
                key: "departments",
                label: "Departments",
                caps: ["saveSettings"],
                panel: SettingsDepartments
            },
        ]
    }

}