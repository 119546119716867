import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServiceStageAssignServiceProvider from './ServiceStageAssignServiceProvider';
import ServiceStageMileageClaim from './ServiceStageMileageClaim';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServiceStageRequestAccepted from './ServiceStageRequestAccepted';
import ServiceStageTrainingDetails from './ServiceStageTrainingDetails';
import ServiceStageReviewFailed from './ServiceStageReviewFailed';
import ServiceStageCheckComplete from './ServiceStageCheckComplete';

const ServiceStagesTraining: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>
            {data.record_verified === "yes" && (

                <ServiceStageMileageClaim
                    data={data}
                />

            )}

            <ServiceStageAssignServiceProvider
                data={data}
            />

            {data.assign_service_provider === "yes" && (

                <ServiceStageRequestAccepted
                    data={data}
                />

            )}

            {data.request_accepted === "yes" && data.status !== "failed" && data.status !== "cancelled" && (

                <ServiceStageTrainingDetails
                    data={data}
                />

            )}

            {(data.status === "failed") && (
                <ServiceStageReviewFailed
                    data={data}
                />
            )}

            {data.training_complete === "yes" && (
                <ServiceStageCheckComplete
                    data={data}
                />
            )}

            {data.training_complete === "yes" && (data.check_complete === "yes") && (

                <ServiceStageRecordVerified
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ServiceStagesTraining;
