import React, { useCallback } from 'react';
import { SidebarMenu, useApp, useForm } from '@dex/bubl-dash';
import { get, hashCode } from '@dex/bubl-helpers';

const OpsMenu: React.FC<any> = (props: any) => {

    const { zone, user } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const currentRoute = nav.currentRoute.state;

    const setFilters = useCallback((name, value) => {

        const params = { ...currentRoute.params };

        let where = get(params, "where", "{}");

        if (where && typeof where === "string") where = JSON.parse(where);

        if (value && value[0] === "-") delete where[name];
        else where[name] = { inq: value };

        params.where = JSON.stringify(where);

        app.navigate(nav, 'update', params);

    }, [hashCode(currentRoute)]);

    return (

        <>

            {zone.menus.map((menu, index) => {

                if (menu.path !== "ops" && currentRoute.routeName.indexOf(menu.path) === -1) {
                    return <React.Fragment key={hashCode(menu)}></React.Fragment>;
                }

                menu.setFilters = setFilters;

                return (
                    <>
                        <SidebarMenu
                            key={hashCode(menu)}
                            menu={menu}
                        />
                    </>
                )

            })}

        </>
    )

}

export default OpsMenu;
