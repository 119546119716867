import React, { useCallback, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { FieldDate, Line } from '@dex/bubl-dash';
import { userHasCap } from '@dex/bubl-dash/src/lib/functions/useUser';
import ServiceStageAttemptFailed from './ServiceStageAttemptFailed';
import { validateDatePassed } from '../../../functions/sharedValidation';
import ServiceStageIncompleteMessages from './ServiceStageIncompleteMessages';

const ServiceStageServiceComplete: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_service_detail_programming",);

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasCap(["technician"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"service_complete"}
            heading={"Service Detail (Onsite Update)"}
            editCaps={["stage_service_detail_programmingServices"]}
            // editable={true}
            showForm={data.service_complete !== "yes"}
            showMeta={data.service_complete}
            passed={data.service_complete === "yes"}
            showSave={form.changed}
            reverse={data.service_complete === "yes" && isStaff ? null : reverse}
            last_stage={"accepted_service_request"}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Service Complete",
                    key: "service_complete",
                    format: "titleCase"
                },
                data.service_complete === "yes" && {
                    label: "Technician",
                    key: "technician",
                    format: "modelLink"
                },
                data.service_complete === "yes" && {
                    label: "Service Date",
                    key: "service_date",
                    format: "date"
                },
            ].filter(Boolean)}
            columnB={[
                {
                    label: "Service Report Photo",
                    key: "service_photo",
                    format: "file",
                },
                {
                    label: "Terminal Photo Back",
                    key: "terminal_photo_back",
                    format: "file",
                },
                {
                    label: "Terminal Photo Front",
                    key: "terminal_photo_front",
                    format: "file",
                },
            ].filter(Boolean)}
            columnC={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>


                            <FieldRadio
                                form={form}
                                label={"Service Complete"}
                                name='service_complete'
                                required={true}
                                defaultValue={form.defaults.service_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" },
                                ]}
                            />

                        </Col>

                        {form.values.service_complete === "yes" &&
                            <>
                                <Col col={{ xs: 24, md: 8 }}>
                                </Col>
                                {!isTechnician &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            form={form}
                                            label="Technician"
                                            name="technician_id"
                                            required={true}
                                            model={"Users"}
                                            preload={true}
                                            where={{ roles: "technician", provider_id: data.provider_id }}
                                            defaultValue={form.defaults.technician_id}
                                        />

                                    </Col>
                                }
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldDate
                                        form={form}
                                        name={"service_date"}
                                        required={true}
                                        label={"Service Date"}
                                        defaultValue={form.defaults.service_date || ""}
                                        disabledDate={isDateDisabled}
                                    />

                                </Col>

                            </>
                        }

                    </Row>

                    {form.values.service_complete === "failed" &&
                        <>
                            <ServiceStageAttemptFailed
                                form={form}
                                data={data}
                            />
                        </>
                    }

                    {form.values.service_complete === "yes" &&
                        <>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Service Report Photo"}
                                        name={'service_photo'}
                                        required
                                        defaultValue={form.defaults.service_photo}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Back"}
                                        name={'terminal_photo_back'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_back}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Front"}
                                        name={'terminal_photo_front'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_front}
                                        limit={2}
                                    />

                                </Col>

                            </Row>

                        </>
                    }

                    {form.values.service_complete !== "failed" &&
                        <>
                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <MetaTable
                                        columnSize={{ xs: 8 }}
                                        data={data}
                                        columnA={[
                                            {
                                                label: "Software Version",
                                                key: "software_version",
                                            },
                                            {
                                                label: "Standard Applications",
                                                key: "standard_applications",
                                            },
                                        ]}

                                        columnB={[
                                            {
                                                label: "OS Version",
                                                key: "os_version",
                                            },
                                            {
                                                label: "Optional Applications",
                                                key: "optional_applications",
                                            },
                                        ]}

                                        columnC={[
                                            {
                                                label: "Installation Address",
                                                key: "installation_address",
                                                format: "address",
                                            },
                                        ]}

                                    />

                                    <Space height={"small"} />

                                    <MerchantsMidTidView
                                        data={data}
                                    />

                                </Col>

                            </Row>

                        </>
                    }

                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageServiceComplete;
