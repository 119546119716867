import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ReplacementsApi from '../ReplacementsApi';
import { FieldRelationship } from '@dex/bubl-dash';

const ReplacementStageAssignReplaceTerminal: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ReplacementsApi.update(data.id, "stage_assign_terminal");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"assign_terminal"}
            heading={"Assign Replacement Terminal"}
            editable={false}
            showForm={data.assign_terminal !== 'yes'}
            showMeta={data.assign_terminal === 'yes'}
            showSave={form.changed}
            passed={data.assign_terminal === 'yes'}

            columnA={[
                {
                    label: "New Terminal",
                    key: "new_terminal",
                    format: "modelLink"
                }
            ]}
            columnB={[
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRelationship
                                form={form}
                                name={"new_terminal_id"}
                                required={false}
                                label={"Serial Number"}
                                model={'Terminals'}
                                preload={true}
                                defaultValue={form.defaults.new_terminal_id}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ReplacementStageAssignReplaceTerminal;
