import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ClaimsEntrySalesApi from './ClaimsEntrySalesApi';
import { ModelLink, Status, WhiteSpace } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'claimsentrysales',
    zone: 'finance',
    path: '/claimsentrysales',
    title: "ClaimsEntrySales",
    endpoint: ClaimsEntrySalesApi.endpoint,
    modelName: 'ClaimsEntrySales',
    idKey: 'id',
    primaryKey: 'addon_name',
    indexCaps: ["viewClaimsEntrySales"],
    viewCaps: ["viewClaimsEntrySales"],
    createCaps: ["createClaimsEntrySales"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
            },
            {
                label: "Disabled",
                path: settings.path + "/status/disabled",
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ClaimsEntrySalesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {}, include: [
                'engagementPlan',
                {
                    relation: 'merchant',
                    preset: "link"
                },
            ], order: ['created.date DESC'], limit: 10, skip: 0
        };

        let { status } = params || {};

        if (status) filters.where.status = { inq: [status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id',];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [

            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
            {
                label: "status",
                key: "status",
                type: "choice",
                options: [
                    { value: "active", label: "Active" },
                    { value: "disabled", label: "Disabled" },
                ]
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Merchant",
                key: "merchant",
                format: "ModelLink",
                elipsis: true,
                style: { maxWidth: "150px" },
                render: (value: any, row: any) => (
                    <>
                        <ModelLink data={{ id: row.merchant_id }} modelName="Merchants" />
                        <br />
                        <small style={{ fontSize: ".88em" }}>{row.merchant_name}</small>
                    </>
                )
            },
            {
                label: "Location Type",
                key: "merchant_location",
                format: "titleCase"
            },
            // {
            //     label: "Claim Status",
            //     key: "status",
            //     format: "status",
            // },
            {
                label: "Engagement Plan",
                key: "engagementPlan",
                format: "ModelLink",
            },
            {
                label: "Date Installed",
                key: "date_installed",
                format: "dateDay",
            },
            {
                label: "Notes",
                key: "notes",
                style: { maxWidth: "200px", width: "200px" },
            },
            {
                label: "Commission",
                key: "amount",
                format: "number",
                prefix: "RM ",
                alignEnd: true
            },
        ];

        return columns;

    },
    singleFetch: ClaimsEntrySalesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                'engagementPlan',
                {
                    relation: 'merchant',
                    preset: "link"
                },

            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}  <WhiteSpace /> {data && <Status status={data?.status} prefix='claimsentrysales.status' />}</>;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};