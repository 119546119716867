import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import MigrationDataApi from './MigrationDataApi';
import { ModelLink } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'migrationdata',
    zone: 'utils',
    path: '/migrationdata',
    title: "Migration Data",
    endpoint: MigrationDataApi.endpoint,
    modelName: 'MigrationData',
    idKey: 'id',
    primaryKey: 'type',
    indexCaps: ["viewMigrationData"],
    viewCaps: ["viewMigrationData"],
    createCaps: ["createMigrationData"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: MigrationDataApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['type', 'old_id', 'new_id', 'status', 'message'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Type",
                key: "type",
                type: "text"
            },
            {
                label: "Status",
                key: "status",
                type: "text"
            },
            {
                label: "Old ID",
                key: "old_id",
                type: "number"
            },
            {
                label: "New ID",
                key: "new_id",
                type: "text"
            },
            {
                label: "Message",
                key: "message",
                type: "text"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "WP ID",
                key: "old_id",
            },
            routeName === "migrationdata" ? {
                label: "Type",
                key: "type",
            } : null,
            {
                label: "Status",
                key: "status",
            },
            {
                label: "New ID",
                key: "new_id",
                render: (value, record) => (
                    <ModelLink
                        modelName={record.newModelName}
                        data={{ id: record.new_id }}
                    />
                )
            },
            {
                label: "Date",
                key: "updated.date",
                format: "date",
            },
            {
                label: "Message",
                key: "message",
            },
        ].filter(Boolean);

        return columns;

    },
    singleFetch: MigrationDataApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};