import { Link, Modal, ModelLink, Panel } from '@dex/bubl-dash';
import React, { useState } from 'react';
import MerchantsMidTidView from '../../views/Merchants/Components/MerchantsMidTidView';
import { merchantMidPrefix } from '../../functions/sharedMerchant';

const MidTidLink: React.FC<MidTidLinkProps> = (props) => {

    const { data } = props;

    const [show, setShow] = useState(false);

    let mid = data.mid || "-";

    if (mid !== "-") {
        mid = merchantMidPrefix(mid, data.mid_prefix, 15);
    }

    return (

        <>
            <Link
                onClick={setShow.bind(null, true)}
            >
                {mid}
            </Link>

            {show &&
                <Modal
                    show={show}
                    onClose={setShow.bind(null, false)}
                    width={960}
                >

                    <Panel
                        heading={"Mid Tid"}
                    >

                        <MerchantsMidTidView
                            data={data}
                        />

                    </Panel>

                </Modal>
            }
        </>

    )

}

interface MidTidLinkProps {
    data: any,
    [key: string]: any,
}

export default MidTidLink;