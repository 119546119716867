import React, { useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import PanelTabs from '@dex/bubl-dash/src/lib/components/PanelTabs/PanelTabs';
import InventoryStatusHistory from './InventoryStatusHistory';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';


const InventoriesSingleSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tab, setTab] = useState("status_history");

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Inventory Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Serial Number",
                                key: "serial",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Type",
                                key: "type",
                                format: "intl",
                                prefix: "inventories.type"
                            },
                            {
                                label: "Owner",
                                key: "owner",
                            },

                        ]}
                        columnC={[
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },

                        ]}
                    />

                    {data.status === "in_stock" && data.in_stock === "write_off" && (
                        <>
                            <Seperator
                                heading={"Write-Off Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Write-Off Notes",
                                        key: "write_off_notes",
                                        format: "nl2br"

                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Faults",
                                        key: "parts_faulty",
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Parts Recovered",
                                        key: "parts_recovered",
                                    },
                                ]}
                            />



                        </>
                    )}

                </Panel>

            </Col>

            <Col col={{ xs: 24 }}>

                <PanelTabs
                    activeTab={tab}
                    setTab={setTab}
                    tabs={[
                        { name: "status_history", label: "Status History" },
                        { name: "deployment", label: "Deployment" },
                    ]}
                />

                <Space />

                {tab === "status_history" && (

                    <InventoryStatusHistory
                        data={data}
                    />

                )}

                {tab === "deployment" && (

                    <InventoryStatusHistory
                        data={data}
                        filters={"deployed"}
                    />

                )}

            </Col>


        </Row>

    ), [data, tab]);

}

export default InventoriesSingleSingleView;
