import HelpdeskTicketsCreate from "./HelpdeskTicketsCreate";
import HelpdeskTicketsIndex from "./HelpdeskTicketsIndex";
import HelpdeskTicketsSettings from "./HelpdeskTicketsSettings";
import HelpdeskTicketsSingle from "./HelpdeskTicketsSingle";


export default function (app) {

    const settings = HelpdeskTicketsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Tickets",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Open",
                path: settings.path + "/status/open",
                caps: settings.indexCaps,
            },
            {
                label: "Resolved",
                path: settings.path + "/status/resolved",
                caps: settings.indexCaps,
            },
            {
                label: "Unresolved",
                path: settings.path + "/status/unresolved",
                caps: settings.indexCaps,
            },
            {
                label: "Closed",
                path: settings.path + "/status/closed",
                caps: settings.indexCaps,
            },
        ],
    });

    HelpdeskTicketsIndex(app, settings);

    HelpdeskTicketsCreate(app, settings);

    HelpdeskTicketsSingle(app, settings);

}