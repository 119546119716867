import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import InstallationStages from '../Stages/InstallationStages';
import MidTidLink from '../../../elements/MidTidLink/MidTidLink';
import ServicesSingleHistory from '../../Services/Components/ServicesSingleHistory';
import InstallationStageActions from '../Stages/InstallationStageActions';
import { formatTime } from '../../../functions/sharedMerchant';

const InstallationsSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Installation Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Merchant ID",
                                key: "merchant",
                                format: "ModelLink"
                            },
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },
                            {
                                label: "Trading Name",
                                key: "trading_name",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "ModelLink"
                            },
                            {
                                label: "MID",
                                key: "merchant.mid",
                                render: (value) => <MidTidLink data={data} />
                            },
                            {
                                label: "TID",
                                key: "merchant.tid",
                            },
                            {
                                label: "Device Type",
                                key: "device_type",
                                format: "intl",
                                prefix: "terminals.type"
                            },
                            {
                                label: "Addons",
                                key: "merchant.selected_addons_label",
                            },
                            {
                                label: "Stage Time",
                                key: "stage_time",
                                format: "number",
                                render: formatTime
                            },
                        ]}
                        columnC={[

                            {
                                label: "Standard Applications",
                                key: "merchant.standard_applications",
                            },
                            {
                                label: "Optional Applications",
                                key: "merchant.optional_applications",
                            },
                            {
                                label: "QR Applications",
                                key: "merchant.qr_applications",
                            },
                            {
                                label: "Installation Address",
                                key: "installation_address",
                                format: "address"
                            },
                            {
                                label: "Additional Notes",
                                key: "merchant.notes",
                            },
                        ]}
                    />

                    {data.history && data.history.length > 0 &&
                        <ServicesSingleHistory
                            key={hash}
                            data={data}
                            heading={"Installation History"}
                        />
                    }

                </Panel>

            </Col>

            {/* <Col
                col={{ xs: 24 }}
                align='end'
            >

                <InstallationStageActions
                    key={hash}
                    data={data}
                />

            </Col> */}

            <Col
                col={{ xs: 24 }}
            >

                <InstallationStages
                    key={hash}
                    data={data}
                />

            </Col>

        </Row>

    ), [hash]);

}

export default InstallationsSingleView;
