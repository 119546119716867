import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import MigrationSingleViewGeneral from './MigrationSingleViewGeneral';
import MigrationDataSingleType from '../../MigrationData/Components/MigrationDataSingleType';
import MigrationsApi from '../MigrationsApi';
import { FieldNumber, FieldSwitch, FieldText, FieldTextArea, Form, useApp, useForm } from '@dex/bubl-dash';

const MigrationsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const fetchIds = MigrationsApi.update(data.id, "fetchIds");

    const fetchRecords = MigrationsApi.update(data.id, "fetchRecords");

    const clearRecords = MigrationsApi.update(data.id, "clearRecords");

    const handleFetchid = () => {

        fetchIds.run();

    };

    const handleFetchRecords = () => {

        fetchRecords.run();

    };

    const handleClearRecords = () => {

        clearRecords.run();

    }

    const [tab, setTab] = useState('fetchIds');

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Migration Details"}
                >

                    <MigrationSingleViewGeneral
                        data={data}
                    />

                    <Col>

                        <Seperator
                            heading={"Actions"}
                            top={"auto"}
                            bottom={"auto"}
                            activeTab={tab}
                            setTab={setTab}
                            tabs={[
                                {
                                    label: "Fetch Ids",
                                    name: "fetchIds",
                                },
                                {
                                    label: "Fetch Records",
                                    name: "fetchRecords",
                                },
                                {
                                    label: "Extract Columns",
                                    name: "extractColumns",
                                },
                                {
                                    label: "process Data",
                                    name: "processData",
                                },
                                {
                                    label: "Map Relations",
                                    name: "mapRelations",
                                },
                                {
                                    label: "Clear Data",
                                    name: "clearRecords",
                                }
                            ]}
                        />

                        {tab === "fetchIds" && (
                            <MigrationFetchIds
                                data={data}
                            />
                        )}

                        {tab === "fetchRecords" && (
                            <MigrationFetchRecords
                                data={data}
                            />
                        )}

                        {tab === "processData" && (
                            <MigrationProcessData
                                data={data}
                            />
                        )}

                        {tab === "extractColumns" && (
                            <MigrationExtractColumns
                                data={data}
                            />
                        )}

                        {tab === "mapRelations" && (
                            <MigrationMapRelations
                                data={data}
                            />
                        )}

                        {tab === "clearRecords" && (
                            <MigrationClearRecords
                                data={data}
                            />
                        )}

                        {/* <ButtonRow>
                            <Button
                                label={"Fetch Ids"}
                                block={true}
                                size="small"
                                onClick={handleFetchid}
                            />

                            <Space height={"small"} />

                            <Button
                                label={"Fetch Records"}
                                block={true}
                                size="small"
                                onClick={handleFetchRecords}
                            />

                            <Space height={"small"} />

                            <Button
                                label={"Map Relations"}
                                block={true}
                                size="small"
                            />

                            <Space height={"small"} />

                            <Button
                                label={"Clear Data"}
                                block={true}
                                type={"faded"}
                                size="small"
                                onClick={handleClearRecords}
                            />

                        </ButtonRow> */}

                    </Col>

                </Panel>

                <Space height={"small"} />

                <MigrationDataSingleType
                    data={data}
                />

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data, tab]);

}

const MigrationFetchIds: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ limit: 1000, offset: 0 });

    const fetchIds = MigrationsApi.update(data.id, "fetchIds");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        fetchIds.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='limit'
                        label='Limit'
                        required={true}
                        defaultValue={form.defaults.limit}
                        allowNegative
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='offset'
                        label='Offset'
                        required={false}
                        defaultValue={form.defaults.offset}
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldTextArea
                        form={form}
                        name='ids'
                        label="Specific WP ID's"
                        required={false}
                        defaultValue={form.defaults.ids}
                        rows={1}
                    />

                </Col>

            </Row>

            <ButtonRow>

                <Button
                    label={"Fetch Ids"}
                    loading={fetchIds.loading}
                />

            </ButtonRow>

        </Form>

    )

};

const MigrationFetchRecords: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ status: 'fetch-id', limit: 1000 });

    const process = MigrationsApi.update(data.id, "fetchRecords");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='limit'
                        label='Limit'
                        required={true}
                        defaultValue={form.defaults.limit}
                        allowNegative
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        name='status'
                        label='Current Status'
                        required={true}
                        defaultValue={form.defaults.status}
                    />

                </Col>

            </Row>

            <ButtonRow>

                <Button
                    label={"Fetch Records"}
                    loading={process.loading}
                />

            </ButtonRow>

        </Form>

    )

};

const MigrationExtractColumns: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ status: 'fetch-record', limit: 1000 });

    const process = MigrationsApi.update(data.id, "extractColumns");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='limit'
                        label='Limit'
                        required={true}
                        defaultValue={form.defaults.limit}
                        allowNegative
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        name='status'
                        label='Current Status'
                        required={true}
                        defaultValue={form.defaults.status}
                        allowNegative
                    />

                </Col>

            </Row>

            <ButtonRow>

                <Button
                    label={"Extract Columns"}
                    loading={process.loading}
                />

            </ButtonRow>

        </Form>

    )

};

const MigrationProcessData: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ status: 'fetch-record', limit: 1000 });

    const process = MigrationsApi.update(data.id, "processData");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='limit'
                        label='Limit'
                        required={true}
                        defaultValue={form.defaults.limit}
                        allowNegative
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        name='status'
                        label='Current Status'
                        required={true}
                        defaultValue={form.defaults.status}
                        allowNegative
                    />

                </Col>

            </Row>

            <ButtonRow>

                <Button
                    label={"Process Data"}
                    loading={process.loading}
                />

            </ButtonRow>

        </Form>

    )

};

const MigrationMapRelations: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ status: 'process-data', limit: 1000 });

    const process = MigrationsApi.update(data.id, "mapRelations");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldNumber
                        form={form}
                        name='limit'
                        label='Limit'
                        required={true}
                        defaultValue={form.defaults.limit}
                        allowNegative
                    />

                </Col>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldText
                        form={form}
                        name='status'
                        label='Current Status'
                        required={true}
                        defaultValue={form.defaults.status}
                    />

                </Col>

            </Row>

            <ButtonRow>

                <Button
                    label={"Map Relations"}
                    loading={process.loading}
                />

            </ButtonRow>

        </Form>

    )

};

const MigrationClearRecords: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({});

    const process = MigrationsApi.update(data.id, "clearRecords");

    const app = useApp();

    const [nav] = app.store("nav");

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values,
            timeout: 100000,
            onComplete: (response: any) => {
                app.navigate(nav, 'update', {});
            }
        });

    }, []);

    return (

        <Form
            form={form}
            autoDiscardChanges={true}
            onSubmit={handleSubmit}
        >

            <FieldSwitch
                form={form}
                name={"clearData"}
                label='Clear Migration Data'
            />

            <ButtonRow>

                <Button
                    label={"Clear Records"}
                    type='faded'
                    loading={process.loading}
                    confirm={{
                        title: "Are you sure?",
                        message: "This will delete all records associated with this migration.",
                    }}
                    onClick={handleSubmit.bind(null, form.values)}
                    htmlType={"button"}
                />

            </ButtonRow>

        </Form>

    )

};

export default MigrationsSingleView;
