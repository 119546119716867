import React, { useMemo } from 'react';
import InvoicesStagesVoidInvoice from './InvoicesStagesVoidInvoice';
import InvoicesStagesPayment from './InvoicesStagesPayment';
import InvoicesStagesCreditInvoice from './InvoicesStagesCreditInvoice';


const InvoicesStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <InvoicesStagesPayment
                data={data}
            />

            <InvoicesStagesVoidInvoice
                data={data}
            />

            {[data.status === "void" || data.status === "credit"] &&

                <InvoicesStagesCreditInvoice
                    data={data}
                />

            }

        </>

    ), [data]);

}

export default InvoicesStages;
