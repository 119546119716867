import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import InventoriesCreateEditForm from "./Components/InventoriesCreateEditForm";
import InventoriesApi from "./InventoriesApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: InventoriesCreateEditForm,
                getCreate: InventoriesApi.create,
                getClone: InventoriesApi.getOne,
                defaults: {
                    type: "desktop",
                    has_serial: "yes"
                },
                successCallback: (response, app, nav) => {
                    const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { batch: response.batch } };
                    app.navigate(nav, 'push', route);
                },
                idKey: settings.idKey,
            },
        },
    });

}