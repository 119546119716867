import React, { useMemo } from 'react';
import { Col, FieldCheckbox, FieldDate, FieldRadio, FieldRelationship, FieldSelect, FieldText, FieldTextArea, Row, Space, useApp, useForm } from '@dex/bubl-dash';
import { userHasCap } from '../../../functions/useUser';
import Stage from '../../../elements/Stage/Stage';
import InvoicesApi from '../../Invoices/InvoicesApi';


const InvoicesStagesCreditInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const process = InvoicesApi.update(data.id, "stage_credit");

    const form = useForm(data);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"credit_status"}
            heading={"Credit Invoice"}
            editCaps={["stage_creditInvoices"]}
            editable={false}
            showForm={data.status !== "credit"}
            showMeta={data.status === "credit"}
            showSave={form.changed}
            passed={data.status === "credit"}
            columnA={[
                {
                    key: "status",
                    label: "Status",
                    format: "titleCase"
                },
                {
                    key: "credit_status",
                    label: "Credit Status",
                    format: "titleCase"
                },
                {
                    key: "payment_date",
                    label: "Payment Date",
                    format: "date"
                }
            ].filter(Boolean)}
            columnB={[
                data.credit_status == "refund" ? {
                    key: "payment_account",
                    label: "Payment From",
                    format: "modelLink"
                } : null,
                data.credit_status == "refund" ? {
                    key: "payment_to",
                    label: "Payment To",
                    format: "nl2br"
                } : null,
                data.credit_status == "contra" ? {
                    key: "contra_invoice_no",
                    label: "Contra Invoice No"
                } : null,
                {
                    key: "credit_note",
                    label: "Notes",
                    format: "nl2br"
                }
            ].filter(Boolean)}
            fields={

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={"status"}
                                label={"Status"}
                                defaultValue={form.defaults.status}
                                options={[
                                    { label: "Void", value: "void" },
                                    { label: "Credit", value: "credit" },
                                ]}
                            />

                        </Col>

                        {form.values.status == "credit" &&

                            <>
                                <Col col={{ xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        name={"credit_status"}
                                        label={"Credit Status"}
                                        defaultValue={form.defaults.credit_status}
                                        options={[
                                            { label: "Refund", value: "refund" },
                                            { label: "Contra", value: "contra" },
                                        ]}
                                    />

                                </Col>

                                {form.values.credit_status == "refund" ?

                                    <>
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                required={true}
                                                name={"payment_date"}
                                                label={"Payment Date"}
                                                defaultValue={form.defaults.payment_date || ""}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Payment From"}
                                                name='payment_account'
                                                required={true}
                                                model={'BankAccounts'}
                                                preload={true}
                                                defaultValue={form.defaults.payment_account}
                                                order={["created.date DESC"]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                name={"payment_to"}
                                                label={"Payment To"}
                                                defaultValue={form.defaults.payment_to}
                                            />

                                        </Col>

                                    </>
                                    :
                                    <>
                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Contra Invoice No"}
                                                name='contra_invoice_no'
                                                required={true}
                                                defaultValue={form.defaults.contra_invoice_no}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                required={true}
                                                name={"payment_date"}
                                                label={"Payment Date"}
                                                defaultValue={form.defaults.payment_date || ""}
                                            />

                                        </Col>

                                    </>

                                }

                                <Col col={{ xs: 24 }}>

                                    <FieldTextArea
                                        form={form}
                                        name={"credit_note"}
                                        label={"Note"}
                                        defaultValue={form.defaults.credit_note}
                                    />

                                </Col>
                            </>
                        }
                    </Row>

                </Col>
            }
        />

    ), [data, form.hash]);

}

export default InvoicesStagesCreditInvoice;
