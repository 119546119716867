import React, { useMemo } from 'react';

import { Box } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldAutocomplete, FieldSelect, Form, Space, useForm, userHasCap } from '@dex/bubl-dash';
import SummaryStats from './SummaryStats/SummaryStats';

const Summary: React.FC<any> = (props: any) => {

    const { type } = props;

    const isStaff = userHasCap(['staff']);

    const form = useForm({ filter: '' });

    return useMemo(() => (

        <Box
            windowHeight={true}
            gutter={"big"}
        >

            <Form
                form={form}
            >


                {type === "terminals" &&
                    <FieldAutocomplete
                        form={form}
                        label={"Model Number"}
                        group={"model_number"}
                        wrapClass={"w-500"}
                        name='filter'
                        required={false}
                        preload
                    />
                }
                {type === "inventories" &&
                    <FieldSelect
                        form={form}
                        label={"Filter"}
                        name="filter"
                        required={false}
                        wrapClass={"w-500"}
                        options={[
                            { label: "Swivel", value: "swivel" },
                            { label: "Router", value: "router" },
                            { label: "Docking", value: "docking" },
                            { label: "Sim Card", value: "sim" },
                        ]}
                    />
                }

            </Form>

            <Space />

            {isStaff &&
                <SummaryStats
                    type={type}
                    filter={form.values.filter}
                />
            }

        </Box>

    ), [form.hash, type, isStaff])

}

export default Summary;