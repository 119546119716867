import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import UsersSettings from '@dex/bubl-dash/src/lib/views/Users/UsersSettings';
import React, { useMemo, useState } from 'react'

const AcquirersUsersSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = UsersSettings.indexFetchParams(routeName, params, users);

        filters.where.acquirer_id = data.id;
        filters.where.roles = { inq: ['acquirer'] };

        return filters;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Users"}
                hideTrashSwitch={true}
                getFetch={UsersSettings.indexFetch}
                getColumns={UsersSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={UsersSettings.indexQuickSearch}
                getSearchFields={UsersSettings.indexSearchFields}
            />

        </>

    ), [data, tableState]);
}

export default AcquirersUsersSingleView