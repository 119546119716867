import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ServiceStagesFaultCall from '../Stages/ServiceStagesFaultCall';
import ServiceStagesRetrieval from '../Stages/ServiceStagesRetrieval';
import ServiceStagesAmendment from '../Stages/ServiceStagesAmendment';
import ServiceStagesAnnualVisitation from '../Stages/ServiceStageAnnualVisitation';
import ServiceStagesPaperRoll from '../Stages/ServiceStagesPaperRoll';
import ServiceStagesProgramming from '../Stages/ServiceStagesProgramming';
import ServiceStagesTraining from '../Stages/ServiceStagesTraining';
import ServiceStagesRental from '../Stages/ServiceStagesRental';
import ServiceStagesChangePlan from '../Stages/ServiceStagesSwapPlan';
import { Seperator } from '@dex/bubl-dash';
import MidTidLink from '../../../elements/MidTidLink/MidTidLink';
import TerminalLink from '../../../elements/TerminalLink/TerminalLink';
import ServicesSingleHistory from './ServicesSingleHistory';
import InventoryLink from '../../../elements/InventoryLink/InventoryLink';

const ServicesSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Service Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Merchant ID",
                                key: "merchant",
                                format: "ModelLink"
                            },
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },
                            {
                                label: "Trading Name",
                                key: "trading_name",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                            {
                                label: "Merchant Location",
                                key: "merchant_location",
                                format: "titleCase"
                            },
                            {
                                label: "Service Notes",
                                key: "service_notes",
                                format: "nl2br"
                            },
                            {
                                label: "Service Document",
                                key: "service_document",
                                format: "file"
                            },

                        ]}
                        columnB={[
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "ModelLink"
                            },
                            {
                                label: "MID",
                                key: "mid",
                                render: (value) => <MidTidLink data={data} />
                            },
                            {
                                label: "TID",
                                key: "tid",
                            },
                            {
                                label: "Serial Number",
                                key: "faulty_terminal",
                                render: (value) => <TerminalLink data={value} />
                            },
                            {
                                label: "Power Adapter Type",
                                key: "faulty_adapter_type",
                                format: "intl",
                                prefix: "installation.adapter_type",
                                hideIfEmpty: true
                            },
                            {
                                label: "SIM Card No.",
                                key: "faulty_sim_card_serial",
                                hideIfEmpty: true,
                                render: (value, row) => <InventoryLink id={value} />
                            },
                            {
                                label: "Swivel Serial No.",
                                key: "faulty_swivel_serial",
                                hideIfEmpty: true,
                                render: (value, row) => <InventoryLink id={value} />
                            },

                            {
                                label: "Router Serial No.",
                                key: "faulty_router_serial",
                                hideIfEmpty: true,
                                render: (value, row) => <InventoryLink id={value} />
                            },
                            {
                                label: "Docking Serial No.",
                                key: "faulty_docking_serial",
                                hideIfEmpty: true,
                                render: (value, row) => <InventoryLink id={value} />
                            },
                            {
                                label: "Device Type",
                                key: "device_type",
                                format: "intl",
                                prefix: "terminals.type"
                            },
                            {
                                label: "Addons",
                                key: "merchant.selected_addons_label",
                            },
                            {
                                label: "Engagement Plan",
                                key: "engagement_plan",
                                format: "ModelLink",
                            },

                        ]}
                        columnC={[

                            {
                                label: "Standard Applications",
                                key: "standard_applications",
                            },
                            {
                                label: "Optional Applications",
                                key: "optional_applications",
                            },
                            {
                                label: "QR Applications",
                                key: "qr_applications",
                            },
                            {
                                label: "OS Version",
                                key: "os_version",
                            },
                            {
                                label: "Software Version",
                                key: "software_version",
                            },
                            {
                                label: "Installation Address",
                                key: "installation_address",
                                format: "address"
                            },
                            {
                                label: "Merchant Notes",
                                key: "merchant.notes",
                                format: "nl2br"
                            },
                        ]}
                    />

                    {data.ticket &&
                        <>

                            <Seperator
                                heading={"Helpdesk Ticket"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data.ticket}
                                columnA={[
                                    {
                                        label: "Ticket ID",
                                        key: "this",
                                        format: "modelLink",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Status",
                                        key: "status",
                                        format: "status",
                                        prefix: "helpdesk.status"
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Issue",
                                        key: "issue",
                                        format: "intl",
                                        prefix: "helpdesk.issue"
                                    },
                                ]}
                            />

                        </>
                    }

                    {data.history && data.history.length > 0 &&
                        <ServicesSingleHistory
                            key={hash}
                            data={data}
                            heading={"Service History"}
                        />
                    }

                </Panel>

            </Col>

            {/* <Col
                col={{ xs: 24 }}
                align='end'
            >

                <ServiceStageActions
                    key={hash}
                    data={data}
                />

            </Col> */}

            <Col
                col={{ xs: 24 }}
            >

                {data.type === "fault_call" &&
                    <ServiceStagesFaultCall
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "retrieval" &&
                    <ServiceStagesRetrieval
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "paper_roll" &&
                    <ServiceStagesPaperRoll
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "programming" &&
                    <ServiceStagesProgramming
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "training" &&
                    <ServiceStagesTraining
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "yearly_rental" &&
                    <ServiceStagesRental
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "amendment" &&
                    <ServiceStagesAmendment
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "annual_visitation" &&
                    <ServiceStagesAnnualVisitation
                        key={hash}
                        data={data}
                    />
                }

                {data.type === "swap_plan" && (
                    <ServiceStagesChangePlan
                        key={hash}
                        data={data}
                    />
                )}

            </Col>

        </Row>

    ), [hash]);

}

export default ServicesSingleView;
