import React, { useMemo } from 'react';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import MassRolloutsApi from '../MassRolloutsApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';

const MassRolloutStageDelivery: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = MassRolloutsApi.update(data.id, "stage_delivery_complete");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"delivery_complete"}
            heading={"Delivery"}
            editable={false}
            showForm={data.delivery_complete !== "yes"}
            showMeta={data.delivery_complete}
            showSave={form.changed}
            passed={data.delivery_complete === "yes"}
            columnA={[
                {
                    label: "Delivery Completed",
                    key: "delivery_complete",
                    format: "titleCase",
                },
            ]}
            columnB={[
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Completed Delivery"}
                                name={"delivery_complete"}
                                defaultValue={form.defaults.delivery_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />

                        </Col>

                        {form.values.delivery_complete === "yes" && (

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Are you sure? Edits are not allowed after this stage."}
                                    name={"are_you_sure"}
                                    defaultValue={form.defaults.are_you_sure}
                                    options={[
                                        { label: "Yes", value: "yes" },
                                        { label: "No", value: "no" }
                                    ]}
                                />

                            </Col>

                        )}

                    </Row>

                </>
            }

        />

    ), [props, form.hash, process.loading]);

}

export default MassRolloutStageDelivery;
