import React, { useMemo } from 'react';
import ServiceStageMileageClaim from './ServiceStageMileageClaim';
import ServiceStageAssignServiceProvider from './ServiceStageAssignServiceProvider';
import ServiceStageRequestAccepted from './ServiceStageRequestAccepted';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServiceStageIssueSpareTerminal from './ServiceStageIssueSpareTerminal';
import ServiceStageRecoverTerminal from './ServiceStageRecoverTerminal';
import ServiceStageDetailFaultCall from './ServiceStageDetailFaultCall';
import ServiceStageReviewFailed from './ServiceStageReviewFailed';
import ServiceStageCheckComplete from './ServiceStageCheckComplete';

const ServiceStagesFaultCall: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (
        <>
            {data.record_verified === "yes" && (

                <ServiceStageMileageClaim
                    data={data}
                />

            )}

            <ServiceStageAssignServiceProvider
                data={data}
            />

            {data.assign_service_provider === "yes" && (

                <ServiceStageRequestAccepted
                    data={data}
                />

            )}

            {data.request_accepted === "yes" && data.status !== "failed" && data.status !== "cancelled" && (

                <ServiceStageDetailFaultCall
                    data={data}
                />

            )}

            {(data.status === "failed") && (
                <ServiceStageReviewFailed
                    data={data}
                />
            )}

            {data.service_complete === "yes" && (
                <ServiceStageCheckComplete
                    data={data}
                />
            )}

            {data.service_complete === "yes" && (data.check_complete === "yes") && (

                <ServiceStageIssueSpareTerminal
                    data={data}
                />

            )}

            {(data.issue_spare === "yes" || data.issue_spare === "skip") && (

                <ServiceStageRecoverTerminal
                    data={data}
                />

            )}

            {(data.recover_assets === "yes") && (data.check_complete === "yes") && (

                <ServiceStageRecordVerified
                    data={data}
                />

            )}


        </>
    ),
        [data]
    );

}

export default ServiceStagesFaultCall;
