import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRelationship } from '@dex/bubl-dash';

const ServiceStageSelectEngagementPlan: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_select_engagement_plan");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"select_engagement_plan"}
            heading={"Select Engagement Plan"}
            editCaps={["stage_select_engagement_planServices"]}
            editable={data.select_engagement_plan && data.prepare_invoice !== "yes"}
            showForm={data.select_engagement_plan !== "yes"}
            showMeta={data.select_engagement_plan === "yes"}
            passed={data.select_engagement_plan === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Previous Engagement Plan",
                    key: "previous_engagement_plan",
                    format: "modelLink"
                },
                {
                    label: "Previous Device Type",
                    key: "previous_engagement_plan.device_type",
                    format: "titleCase"
                },
            ]}
            columnB={[
                {
                    label: "New Engagement Plan",
                    key: "engagement_plan",
                    format: "modelLink"
                },
                {
                    label: "New Device Type",
                    key: "engagement_plan.device_type",
                    format: "titleCase"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRelationship
                                form={form}
                                label={"Engagement Plan"}
                                name='engagement_plan_id'
                                required={true}
                                model={'EngagementPlans'}
                                preload={true}
                                where={{ acquirer_id: data.acquirer_id }}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageSelectEngagementPlan;
