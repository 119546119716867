import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldList, FieldRelationship, FieldRepeater, FieldSelect, useApp } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { loop, parseNum } from '@dex/bubl-helpers';

const ParamTemplatesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const [settings] = app.store("appSettings");

    const options = useMemo(() => {

        let options: any = [
            { value: "company_name", label: "Company Name" },
            { value: "trading_name", label: "Trading Name" },
            { value: "address.line1", label: "Address Line 1" },
            { value: "address.line2", label: "Address Line 2" },
            { value: "address.city", label: "Address City" },
            { value: "address.zipCode", label: "Address Zip Code" },
            { value: "address.state", label: "Address State" },
            { value: "address.country", label: "Address Country" },
            { value: "acqurier.company_name", label: "Acquirer Name" },
            { value: "custom", label: "Custom Value" },
        ];

        const addOption = (item, key) => {

            if (item.hasMidTid) {

                options.push({
                    value: `midtid.${key}.mid`,
                    label: item.label + " - MID",
                });

                options.push({
                    value: `midtid.${key}.tid`,
                    label: item.label + " - TID",
                });

            }
            if (item.hasLimits) {

                options.push({
                    value: `midtid.${key}.min`,
                    label: item.label + " - Min Amount",
                });

                options.push({
                    value: `midtid.${key}.max`,
                    label: item.label + " - Max Amount",
                });

            }

        }

        loop(settings.applications, (applications: string, type: string) => {

            loop(applications, (item: any) => {

                options.push({
                    value: `enabled_applications.${item.key}`,
                    label: item.label + " - Enabled",
                });

                if (item.idList && item.idList.length > 0) {

                    loop(item.idList, (key) => {

                        const subItem = structuredClone(item);

                        subItem.label = item.label + ` ${parseNum(key)}`;

                        addOption(subItem, subItem.key);

                    });

                } else {

                    addOption(item, item.key);

                }

            });

        });

        return options;

    }, []);

    return useMemo(() => (

        <Panel
            heading={"Param Template Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Template Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Acquirer"}
                            name='acquirer_id'
                            required={true}
                            model={'Acquirers'}
                            preload={true}
                            defaultValue={form.defaults.acquirer_id}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Device Type"}
                            name='device_type'
                            required={true}
                            defaultValue={form.defaults.device_type}
                            options={[
                                { value: "desktop", label: app.intl("terminals.type.desktop") },
                                { value: "wireless", label: app.intl("terminals.type.wireless") },
                                { value: "mpos", label: app.intl("terminals.type.mpos") },
                                { value: "android", label: app.intl("terminals.type.android") }
                            ]}
                        />

                    </Col>

                </Row>

                <Seperator
                    top={"auto"}
                    bottom={"small"}
                    heading={"Params"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 24 }}>

                        <FieldList
                            form={form}
                            name={'params'}
                            itemLabel='Param'
                            required={false}
                            addButtonLabel={"Add New Param"}
                        >

                            {form.values.params.map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldText
                                                form={form}
                                                placeholder={"Param Name"}
                                                name={`params[${n}].name`}
                                                defaultValue={row.name}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldSelect
                                                form={form}
                                                placeholder={"Param Value"}
                                                name={`params[${n}].value`}
                                                defaultValue={row.value}
                                                options={options}
                                            />

                                        </Col>

                                        {row.value === "custom" &&
                                            <Col col={{ xs: 24, md: 8 }}>

                                                <FieldText
                                                    form={form}
                                                    placeholder={"Custom Value"}
                                                    name={`params[${n}].custom`}
                                                    defaultValue={row.custom}
                                                    required
                                                />

                                            </Col>
                                        }

                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldList>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ParamTemplatesCreateEditForm;
