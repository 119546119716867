import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Stage from '../../../elements/Stage/Stage';
import { formatNum } from '@dex/bubl-helpers';
import { Alert, FieldCheckbox, ModelLink } from '@dex/bubl-dash';


const ServicesStageVerifyPayments: React.FC<any> = (props: any) => {

    const { data, apiService } = props;

    const form = useForm(data);

    const process = apiService.update(data.id, "stage_verify_payment");

    const isLinked = data.prepare_invoice === "linked";
    const isZero = !isLinked && data.proforma_invoice.total === 0;

    const invoice = isLinked ? data.invoice : data.proforma_invoice;

    const checklist = [
        { label: "Total RM " + formatNum(invoice.total), value: "total" },
        { label: "Refundable Deposit RM " + formatNum(invoice.deposit), value: "deposit" },
    ];

    if (data.prepare_invoice === "linked") {
        checklist.push({ label: "Linked To Invoice " + data.invoice_id, value: "linked" })
    }

    const validateValues = useCallback((values) => {

        if (!values.payment_verified_checklist || checklist.length !== values.payment_verified_checklist.length)
            throw new Error("Please complete all items in the checklist.");

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"payment_verified"}
            validate={validateValues}
            heading={"Verify Invoice & Payment"}
            editCaps={["stage_verify_paymentMerchants"]}
            editable={false}
            showForm={data.payment_verified !== "yes"}
            showMeta={data.payment_verified}
            showSave={form.changed}
            passed={data.payment_verified === "yes"}
            columnA={[
                {
                    key: "payment_verified",
                    label: "Payment Verified",
                    format: "titleCase"
                }
            ]}
            columnB={[
                {
                    key: "invoice_id",
                    label: "Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="Invoices" />
                    )
                }

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"payment_verified"}
                                label={"Payment Verified"}
                                defaultValue={form.defaults.payment_verified}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldCheckbox
                                list
                                form={form}
                                name={"payment_verified_checklist"}
                                label={"Payment Checklist"}
                                defaultValue={form.defaults.payment_verified_checklist}
                                options={checklist}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            {isLinked &&
                                <Alert
                                    type='info'
                                    message='Linked To Tax Invoice'
                                    description={"No tax invoice will be generated on save."}
                                />
                            }

                            {isZero &&
                                <Alert
                                    type='info'
                                    message='Zero Value Invoice'
                                    description={"No tax invoice will be generated on save."}
                                />
                            }

                            {(!isZero && !isLinked) &&
                                <Alert
                                    type='info'
                                    message='Generate Tax Invoice'
                                    description={"A tax invoice for RM " + formatNum(data.proforma_invoice.total) + " will be generated on save."}
                                />
                            }

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form, process.loading]);

}

export default ServicesStageVerifyPayments;
