import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm, Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import UsersApi from '@dex/bubl-dash/src/lib/views/Users/UsersApi';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { FieldAddress, FieldTextArea, Seperator, Space } from '@dex/bubl-dash';

const UsersInfoEditPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = UsersApi.update(data.id, "userInfo", {
        onComplete: (data) => {

            app.alert('User Info Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"User Info"}
        >

            <Form
                form={form}
                loading={process.loading}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Bank Name"}
                            name='bank_name'
                            required={false}
                            defaultValue={form.defaults.bank_name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Bank Account Name"}
                            name='account_name'
                            required={false}
                            defaultValue={form.defaults.account_name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Bank Account No."}
                            name='account_number'
                            required={false}
                            defaultValue={form.defaults.account_number}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"IC Number"}
                            name='ic_number'
                            required={false}
                            defaultValue={form.defaults.ic_number}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Address Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>


                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldAddress
                            form={form}
                            label={"Address"}
                            name='address'
                            defaultValue={form.defaults.address}
                            allowedCountries={["Malaysia"]}
                            transform={'uppercase'}
                            maxLength={{
                                line1: 120,
                                line2: 120,
                                city: 13,
                                zipcode: 5,
                            }}
                        />

                    </Col>



                </Row>

                <Line />

                <Col col={{ xs: 24 }}>

                    <FieldTextArea
                        form={form}
                        label={"Additional Notes"}
                        name='notes'
                        required={false}
                        defaultValue={form.defaults.notes}
                    />

                </Col>

                <Space /><Space />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props, form, process]);

}

export default UsersInfoEditPanel;
