import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import React, { useMemo, useState } from 'react'
import TerminalTrackingSettings from '../../TerminalsTracking/TerminalsTrackingSettings';

const TerminalStatusHistory: React.FC<any> = (props: any) => {

    const { data, filters } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filter = TerminalTrackingSettings.indexFetchParams(routeName, params, users);

        filter.where.terminal_id = data.id;

        if (filters) filter.where.status = filters;

        return filter;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Status History"}
                hideTrashSwitch={true}
                getFetch={TerminalTrackingSettings.indexFetch}
                getColumns={TerminalTrackingSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={TerminalTrackingSettings.indexQuickSearch}
                getSearchFields={TerminalTrackingSettings.indexSearchFields}
            />

        </>

    ), [data, tableState]);
}

export default TerminalStatusHistory