import LeasingIndex from './LeasingIndex';
import LeasingCreate from './LeasingCreate';
import LeasingSettings from './LeasingSettings';
import LeasingSingle from './LeasingSingle';

export default function (app) {

    const settings = LeasingSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Leasing",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    LeasingIndex(app, settings);

    LeasingCreate(app, settings);

    LeasingSingle(app, settings);

}