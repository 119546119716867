import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import React, { useMemo, useState } from 'react'
import ServiceSettings from '../../Services/ServicesSettings';
import { Status, useApp } from '@dex/bubl-dash';
import MerchantsApi from '../MerchantsApi';

const MerchantHistory: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const app = useApp();

    const fetch = MerchantsApi.getHistry

    const indexColumns = (routeName, params, user) => {

        const columns = [
            {
                label: "Request ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Service Type",
                key: "type",
                render: (value, row) => {
                    if (row.modelName === "Installations") return "Installation";
                    else return app.intl(`service.type.${value}`);
                }
            },
            {
                label: "Status",
                key: "status",
                render: (value, row) => {
                    if (row.modelName === "installation") return <Status status={row.status} prefix='installation.status' />;
                    else return <Status status={row.status} prefix='service.status' />;
                }
            },
            {
                label: "Completed Date",
                key: "record_verified_on.date",
                format: "dateDay",
                alignEnd: true
            },
            {
                label: "Service Provider",
                key: "provider",
                format: "ModelLink"
            },
            {
                label: "Technician",
                key: "technician",
                format: "ModelLink"
            },
            {
                label: "Serial Number",
                key: "serial",
                render: (value, row) => {
                    if (row.modelName === "Services") {
                        if (row.type === "retrieval") return row.faulty_serial;
                        if (row.new_serial) return row.new_serial;
                    }
                    return value;
                }
            },
            {
                label: "State",
                key: "state",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            }
        ];

        return columns;

    }

    const indexFetchParams = (routeName, params, users) => {

        let filters = ServiceSettings.indexFetchParams(routeName, params, users);

        filters.limit = 1000;

        // filters.where.merchant_id = data.id;
        filters.where.and = [
            {
                or: [
                    { merchant_id: data.id },
                    { floating_merchant_id: data.id },
                ]
            },
            { type: { neq: "annual_visitation" } },
        ];

        return filters;

    }

    const indexQuickSearch = (routeName, params, users) => {

        return ['id', 'status'];

    }
    const indexSearchFields = (routeName, params, users) => {

        return [
            {
                key: "id",
                label: "Request ID",
                type: "text",
            },
            {
                key: "status",
                label: "Status",
                type: "text",
            },
            {
                key: "state",
                label: "State",
                type: "text",
            }
        ];

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Service History"}
                hideTrashSwitch={true}
                getFetch={fetch}
                getColumns={indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={indexQuickSearch}
                getSearchFields={indexSearchFields}
                tableProps={{
                    count: null,
                }}
            />

        </>

    ), [data.id, tableState, fetch.loading]);
}

export default MerchantHistory