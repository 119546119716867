import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio } from '@dex/bubl-dash';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { isEmpty } from '@dex/bubl-helpers';

const ServiceStageIssueSpareTerminal: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_issue_spare_terminal");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "in_progress";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"issue_spare_terminal"}
            heading={"Issue Spare Terminal"}
            editable={false}
            showForm={isEmpty(data.issue_spare)}
            showMeta={data.issue_spare}
            editCaps={["stage_issue_spare_terminalServices"]}
            passed={data.issue_spare}
            showSave={form.changed}
            columnA={[
                {
                    label: "Issue Spare Terminal",
                    key: "issue_spare",
                    format: "titleCase"
                },
                {
                    label: "Spare Serial Number",
                    key: "spare_terminal.serial",
                },
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Issue Spare Terminal"}
                                name='issue_spare'
                                required={true}
                                defaultValue={form.defaults.issue_spare}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "Skip", value: "skip" },
                                ]}
                            />

                        </Col>

                        {form.values.issue_spare === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <MetaTable
                                        data={data}
                                        columnA={[
                                            {
                                                label: "Technician",
                                                key: "technician",
                                                format: "modelLink"
                                            },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRelationship
                                        form={form}
                                        name={'spare_terminal_id'}
                                        required={true}
                                        label={"Terminal Serial Number"}
                                        model={'Terminals'}
                                        preload={true}
                                        where={{ status: 'in_stock' }}
                                        defaultValue={form.defaults.spare_terminal_id}
                                    />

                                </Col>

                            </>

                        )}

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageIssueSpareTerminal;
