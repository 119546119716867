import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio, userHasCap } from '@dex/bubl-dash';

const ServiceStageDeliveryComplete: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_delivery_complete",);

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";
    const isStaff = userHasCap(["staff"]);


    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"delivery_complete"}
            heading={"Delivery Complete"}
            editCaps={["stage_delivery_completeServices"]}
            editable={false}
            showForm={data.paper_roll_complete !== "yes"}
            showMeta={data.paper_roll_complete === "yes"}
            passed={data.paper_roll_complete === "yes"}
            showSave={form.changed}
            reverse={data.paper_roll_complete === "yes" && isStaff ? null : reverse}
            last_stage={"outbound"}
            columnA={[
                {
                    label: "Delivery Complete",
                    key: "paper_roll_complete",
                    format: "titleCase"
                },

            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Delivery Complete"}
                                name='paper_roll_complete'
                                required={true}
                                defaultValue={form.defaults.paper_roll_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageDeliveryComplete;
