import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { Alert, FieldCheckbox, FieldHidden, FieldRadio, FieldTextArea } from '@dex/bubl-dash';
import { date, loop, titleCase } from '@dex/bubl-helpers';

const InstallationStageCheckComplete: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_check_complete");

    let checklist: any = [];
    if (data.type === "installation") {

        checklist = [
            {
                value: "Installation Date",
                label: "Installation Date - " + date(data.active_date),
                message: "Installation Date is incorrect, please update."
            },
            {
                value: "Terminal Photo Back",
                label: "Terminal Photo Back",
                message: "Terminal Photo Back is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Front",
                label: "Terminal Photo Front",
                message: "Terminal Photo Front is incorrect, please reupload."
            },
            {
                value: "Checklist Photo",
                label: "Checklist Photo",
                message: "Checklist Photo is incorrect, please reupload."
            },
            {
                value: "Outlet Photo",
                label: "Outlet Photo",
                message: "Outlet Photo is incorrect, please reupload."
            },
            {
                value: "Signboard Photo",
                label: "Signboard Photo",
                message: "Signboard Photo is incorrect, please reupload."
            },
            {
                value: "Transaction Photo",
                label: "Transaction Photo",
                message: "Transaction Photo is incorrect, please reupload."
            },
            {
                value: "POSM Photo",
                label: "POSM Photo",
                message: "POSM Photo is incorrect, please reupload."
            },
        ];

    } else {
        return
    }



    const [messages, setMessages] = useState<any>(data.check_messages);

    const onChange = useCallback((values) => {

        const messages = [];

        if (!values) values = [];

        loop(checklist, (item) => {

            if (values.includes(item.value)) return;

            // @ts-ignore
            if (item.message) messages.push(item.message);

        });

        setMessages(messages);


    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"check_complete"}
            heading={"Check Complete"}
            editable={false}
            editCaps={["stage_check_completeInstallations"]}
            showForm={data.check_complete !== "yes"}
            showMeta={data.check_complete}
            passed={data.check_complete === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Check Complete",
                    key: "check_checklist",
                    render: () => "Passed"
                },
            ].filter(Boolean)}
            columnB={[]}
            columnC={[]}
            fields={
                <>

                    <Row gutter={8} edge={true}>


                        {data.check_active !== "yes" &&
                            <>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        name={"check_active"}
                                        label={"Terminal Active"}
                                        required={true}
                                        defaultValue={form.defaults.check_active}
                                        options={[
                                            { value: "yes", label: "Yes" },
                                            { value: "no", label: "No" }
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    {form.values.check_active === "no" &&
                                        <Alert
                                            type='warning'
                                            message={"Terminal Inactive"}
                                            description={"Merchant " + data.merchant_id + " status will be reset to Installation."}
                                        />
                                    }

                                    {form.values.check_active === "yes" &&
                                        <Alert
                                            type='success'
                                            message={"Terminal Active"}
                                            description={"Agent commission will be released. This action cannot be reversed."}
                                        />
                                    }

                                </Col>

                            </>
                        }

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldCheckbox
                                form={form}
                                name={"check_checklist"}
                                label={"Checklist"}
                                required={true}
                                defaultValue={form.defaults.check_checklist}
                                options={checklist}
                                onChange={onChange}
                                list
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            {messages && messages.length > 0 &&
                                <>
                                    <Alert
                                        type='warning'
                                        message={"Incomplete Items"}
                                        description={
                                            <>
                                                {messages.map((message, index) => (
                                                    <p key={index}>- {message}</p>
                                                ))}
                                            </>
                                        }
                                    />

                                    <FieldHidden
                                        form={form}
                                        name={"check_messages"}
                                        value={messages}
                                    />

                                    <FieldTextArea
                                        form={form}
                                        label={"Remarks To Technician"}
                                        name='check_remarks'
                                        required={false}
                                        defaultValue={form.defaults.check_remarks}
                                    />
                                </>
                            }

                        </Col>

                    </Row>

                </>
            }
        />

    ), [data, form.hash, process.hash, messages]);
}

export default InstallationStageCheckComplete;
