import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServiceStageAssignServiceProvider from './ServiceStageAssignServiceProvider';
import ServiceStageCollectionDetails from './ServiceStageCollectionDetails';
import ServiceStageMileageClaim from './ServiceStageMileageClaim';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServiceStageRequestAccepted from './ServiceStageRequestAccepted';
import ServiceStageRetrievalDetails from './ServiceStageRetrievalDetails';
import ServiceStageReviewFailed from './ServiceStageReviewFailed';
import ServiceStageCheckComplete from './ServiceStageCheckComplete';

const ServiceStagesRetrieval: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            {data.record_verified && (

                <ServiceStageMileageClaim data={data} />

            )}

            <ServiceStageAssignServiceProvider
                data={data}
            />

            {data.assign_service_provider && (

                <ServiceStageRequestAccepted
                    data={data}
                />

            )}

            {data.request_accepted === "yes" && data.status !== "failed" && data.status !== "cancelled" && (

                <ServiceStageCollectionDetails
                    data={data}
                />

            )}

            {(data.status === "failed") && (
                <ServiceStageReviewFailed
                    data={data}
                />
            )}

            {data.collection_complete === "yes" && (
                <ServiceStageCheckComplete
                    data={data}
                />
            )}

            {data.collection_complete === "yes" && (data.check_complete === "yes") && (

                <ServiceStageRetrievalDetails
                    data={data}
                />

            )}

            {data.retrieval_complete === "yes" && (data.check_complete === "yes") && (

                <ServiceStageRecordVerified
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ServiceStagesRetrieval;
