import { ModelLink, WhiteSpace } from '@dex/bubl-dash';
import React from 'react';

const OriginalModelLink: React.FC<OriginalModelLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            <ModelLink data={data} />
            {data.original_id &&
                <>
                    <br />
                    <WhiteSpace />
                    - {data.original_id}
                </>
            }
        </>

    )

}

interface OriginalModelLinkProps {
    data: any,
    [key: string]: any,
}

export default OriginalModelLink;