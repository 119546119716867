import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MerchantsApi from '../MerchantsApi';
import MerchantsCreateEditForm from './MerchantsCreateEditForm';

const MerchantsSingleReuse: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm({
        acquirer_id: data.acquirer_id,
        process: data.process,
        merchant_location: data.merchant_location,
        engagement_plan_id: data.engagement_plan_id
    });

    const update = MerchantsApi.update(data.id, "reuse", {
        onComplete: (data) => {

            app.alert('Merchant Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <MerchantsCreateEditForm
                    mode={"reuse"}
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default MerchantsSingleReuse;
