import React, { useMemo } from 'react';
import ServiceStageSelectEngagementPlan from './ServiceStageSelectEngagementPlan';
import ServicesApi from '../ServicesApi';
import ServicesStageAddOns from './ServiceStageAddOns';
import ServicesStagePrepareInvoice from './ServiceStagePrepareInvoice';
import ServicesStageDepositPayment from './ServiceStageDepositPayment';
import ServicesStageVerifyPayments from './ServiceStageVerifyPayments';
import ServiceStageProgramming from './ServiceStageProgramming';
import ServiceStageUpdateDetails from './ServiceStageUpdateDetails';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServiceStageAssignServiceProvider from './ServiceStageAssignServiceProvider';
import ServiceStageTerminalSelected from './ServiceStageTerminalSelected';
import ServiceStageTerminalPersonalization from './ServiceStageTerminalPersonalization';
import ServiceStageQualityControl from './ServiceStageQualityControl';
import ServiceStageOutbound from './ServiceStageOutbound';
// import ServiceStageDetailSwapPlan from './ServiceStageDetailSwapPlan';
import ServiceStageRecoverTerminal from './ServiceStageRecoverTerminal';
import ServiceStageDetailProgrammingOnsiteSwap from './ServiceStageDetailProgrammingOnsiteSwap';
import ServiceStageReviewFailed from './ServiceStageReviewFailed';
import ServiceStageCheckComplete from './ServiceStageCheckComplete';

const ServiceStagesSwapPlan: React.FC<any> = (props: any) => {

    const { data } = props;

    const isLinked = data.prepare_invoice === "linked";
    const isZero = data.proforma_invoice?.total === 0;
    const invoice = isLinked ? data.invoice : data.proforma_invoice;

    let verifyPayments = false;

    if (data.prepare_invoice) {
        if (isZero) verifyPayments = true;
        else if (invoice && invoice.status === "paid") verifyPayments = true;
        else if (data.invoice && invoice && invoice.status === "partially_paid") verifyPayments = true;
    }


    return useMemo(() => (

        <>

            <ServiceStageSelectEngagementPlan
                data={data}
            />

            {data.select_engagement_plan === "yes" && (
                <ServicesStageAddOns
                    data={data}
                    apiService={ServicesApi}
                />
            )}

            {(data.select_addons === 'yes' || data.select_addons === 'skip') &&
                <ServicesStagePrepareInvoice
                    data={data}
                    apiService={ServicesApi}
                    swap={true}
                />
            }

            {data.prepare_invoice === "yes" && data.proforma_invoice && (
                <ServicesStageDepositPayment
                    data={data}
                    apiService={ServicesApi}
                />
            )}

            {verifyPayments && (
                <ServicesStageVerifyPayments
                    data={data}
                    apiService={ServicesApi}
                />
            )}

            {(data.payment_verified === "yes") && (
                <ServiceStageProgramming
                    data={data}
                />
            )}

            {data.programming === "yes" && (
                <ServiceStageUpdateDetails
                    data={data}
                    swap_plan={true}
                />
            )}

            {data.mode_of_update === "onsite_swap" && (

                <>

                    <ServiceStageTerminalSelected
                        data={data}
                    />

                    {data.terminal_selected === "yes" && (

                        <ServiceStageTerminalPersonalization
                            data={data}
                        />

                    )}

                    {data.terminal_personalization === "yes" && (

                        <ServiceStageQualityControl
                            data={data}
                        />

                    )}

                    {data.quality_control === "yes" && (

                        <ServiceStageAssignServiceProvider
                            data={data}
                        />

                    )}

                    {data.assign_service_provider === "yes" && (

                        <ServiceStageOutbound
                            data={data}
                        />

                    )}

                    {data.outbound === "yes" && data.status !== "failed" && data.status !== "cancelled" && (

                        <ServiceStageDetailProgrammingOnsiteSwap
                            data={data}
                        />

                    )}

                    {(data.status === "failed") && (
                        <ServiceStageReviewFailed
                            data={data}
                        />
                    )}

                    {data.service_complete === "yes" && (
                        <ServiceStageCheckComplete
                            data={data}
                        />
                    )}


                    {data.service_complete === "yes" && (data.check_complete === "yes") && (

                        <ServiceStageRecoverTerminal
                            data={data}
                        />

                    )}

                    {data.recover_assets === "yes" && (

                        <ServiceStageRecordVerified
                            data={data}
                        />

                    )}

                </>

            )}

        </>

    ), [data]);

}

export default ServiceStagesSwapPlan;
