import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import ClaimsApi from '../ClaimsApi';
import Stage from '../../../elements/Stage/Stage';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';

const ClaimStageMakePayment: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = ClaimsApi.update(data.id, "stage_payment");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"payment"}
            heading={"Make Payment"}
            editable={false}
            showForm={!data.payment}
            showMeta={data.payment}
            showSave={form.changed}
            passed={data.payment === "yes"}
            columnA={[
                {
                    key: "payment",
                    label: "Payment Complete",
                    format: "titleCase"
                },
                {
                    key: "payment_amount",
                    label: "Amount",
                    format: "number",
                    prefix: "RM "
                }
            ]}
            columnB={[
                {
                    label: "Reference Number",
                    key: "payment_reference_no",
                },
                {
                    key: "payment_mode",
                    label: "Payment Mode",
                    format: "intl",
                    prefix: "merchants.payment_mode"
                }
            ]}
            columnC={[
                {
                    key: "payment_date",
                    label: "Transaction Date",
                    format: "date",
                },

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"payment"}
                                label={"Payment Complete"}
                                defaultValue={form.defaults.payment}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.payment === "yes" && (

                            <>
                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldSelect
                                                form={form}
                                                name={"payment_mode"}
                                                label={"Mode Of Payment"}
                                                required={true}
                                                defaultValue={form.defaults.payment_mode}
                                                options={[
                                                    { label: app.intl("merchants.payment_mode.cheque"), value: "cheque" },
                                                    { label: app.intl("merchants.payment_mode.instant_transfer"), value: "instant_transfer" },
                                                    { label: app.intl("merchants.payment_mode.interbank_giro"), value: "interbank_giro" },
                                                ]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Bank Account"}
                                                name='payment_account'
                                                required={true}
                                                model={'BankAccounts'}
                                                preload={true}
                                                defaultValue={form.defaults.payment_account}
                                                order={["created.date DESC"]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                required={true}
                                                name={"payment_date"}
                                                label={"Transaction Date"}
                                                defaultValue={form.defaults.payment_date || ""}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldSelect
                                                form={form}
                                                name={"payment_amount"}
                                                label={"Amount Received"}
                                                required={true}
                                                defaultValue={form.defaults.payment_amount}
                                                options={[
                                                    { label: `Full Amount - RM${data.total}`, value: data.total },
                                                ]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                name={"payment_reference_no"}
                                                label={"Reference No."}
                                                required={true}
                                                defaultValue={form.defaults.payment_reference_no}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldTextArea
                                                form={form}
                                                name={"payment_notes"}
                                                label={"Notes"}
                                                required={false}
                                                defaultValue={form.defaults.payment_notes}
                                            />

                                        </Col>

                                    </Row>

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        name={"payment_photo"}
                                        label={"Verification Photo"}
                                        defaultValue={form.defaults.payment_photo}
                                        folder='finance-media'
                                        limit={3}
                                    />

                                </Col>

                            </>

                        )}


                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ClaimStageMakePayment;
