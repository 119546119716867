import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio, useApp } from '@dex/bubl-dash';

const ServiceStageRecoverTerminal: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = ServicesApi.update(data.id, "stage_recover_assets");

    const isOnsiteSwap = data.mode_of_update === "onsite_swap";
    const terminalSwapped = isOnsiteSwap ? data.swap_terminal === "yes" : data.terminal_swapped === "yes";
    const adapterSwapped = isOnsiteSwap ? data.swap_adapter === "yes" : data.adapter_swapped === "yes";
    const simCardSwapped = isOnsiteSwap ? data.swap_sim_card === "yes" : data.sim_card_swapped === "yes";
    const swivelSwapped = isOnsiteSwap ? data.swap_swivel === "yes" : data.swivel_swapped === "yes";
    const routerSwapped = isOnsiteSwap ? data.swap_router === "yes" : data.router_swapped === "yes";
    const dockingSwapped = isOnsiteSwap ? data.swap_docking === "yes" : data.docking_swapped === "yes";

    const isFloating = data.business_model === "rent-to-own";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"recover_assets"}
            heading={"Recover Assets"}
            editable={false}
            showForm={data.recover_assets !== "yes"}
            showMeta={data.recover_assets === "yes"}
            passed={data.recover_assets === "yes"}
            editCaps={["stage_recover_assetsServices"]}
            showSave={form.changed}
            columnA={[
                {
                    label: "Recover Assets",
                    key: "recover_assets",
                    format: "titleCase"
                },

            ]}
            columnB={[
                {
                    label: "Send Terminal To",
                    key: "send_terminal_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Sim Card To",
                    key: "send_sim_card_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Swivel To",
                    key: "send_swivel_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Router To",
                    key: "send_router_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Docking To",
                    key: "send_docking_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Assets Recovered"}
                                name='recover_assets'
                                required={true}
                                defaultValue={form.defaults.recover_assets}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.recover_assets === "yes" &&
                            <>
                                {terminalSwapped &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Terminal To - " + data.faulty_serial}
                                            name='send_terminal_to'
                                            required={true}
                                            defaultValue={form.defaults.send_terminal_to}
                                            list
                                            options={[
                                                isFloating ? { label: app.intl("terminals.in_stock.acquirer_floating_unit"), value: "acquirer_floating_unit" } : null,
                                                !isFloating ? { label: app.intl("terminals.in_stock.store_used"), value: "store_used" } : null,
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ].filter(Boolean)}
                                        />

                                    </Col>

                                }

                                {adapterSwapped &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Adapter To - " + app.intl(`installation.adapter_type.${data.faulty_adapter_type}`)}
                                            name='send_adapter_to'
                                            required={true}
                                            defaultValue={form.defaults.send_adapter_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ].filter(Boolean)}
                                        />

                                    </Col>
                                }

                                {simCardSwapped && data.faulty_sim_card_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Sim Card To - " + data.faulty_sim_card_serial}
                                            name='send_sim_card_to'
                                            required={true}
                                            defaultValue={form.defaults.send_sim_card_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }

                                {swivelSwapped && data.faulty_swivel_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Swivel To - " + data.faulty_swivel_serial}
                                            name='send_swivel_to'
                                            required={true}
                                            defaultValue={form.defaults.send_swivel_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }

                                {routerSwapped && data.faulty_router_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Router To - " + data.faulty_router_serial}
                                            name='send_router_to'
                                            required={true}
                                            defaultValue={form.defaults.send_router_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }
                                {dockingSwapped && data.faulty_docking_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Docking To - " + data.faulty_docking_serial}
                                            name='send_docking_to'
                                            required={true}
                                            defaultValue={form.defaults.send_docking_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }
                            </>
                        }

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageRecoverTerminal;
