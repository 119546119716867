import React, { useCallback, useMemo, useState, } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, DataTableCell, FieldHidden, FieldParseFile, InputTableRow, Loading, Status } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { loop, slug, titleCase } from '@dex/bubl-helpers';
import { DataTable } from '@dex/bubl-dash';
import { Space } from '@dex/bubl-dash';

const ServicesImportCheckForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, handleReset, type } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [loading, setLoading] = useState(false);

    const columns: any = [
        {
            label: "#",
            key: "index",
        },
        type === "annual_visitation" && {
            label: "Queue Next Visit",
            key: "que_next_visit",
        },
        type === "yearly_rental" && {
            label: "Queue Yearly Rental",
            key: "que_yearly_rental",
        },
        {
            label: "Merchant ID",
            key: "merchant_id",
        },
        {
            label: "Company Name",
            key: "company_name",
        },
        {
            label: "Trading Name",
            key: "trading_name",
        },
        {
            label: "Status",
            key: "status",
        },
        {
            label: "State",
            key: "state",
        },
    ].filter(Boolean);

    const parseData = useCallback((data, file, buffer) => {

        setLoading(true);

        setTimeout(() => {

            const rows: any = [];

            loop(data, (row, index) => {

                row['status'] = (row['status'] || "active").toLowerCase();

                row['que-next-visit'] = (row['que-next-visit'] || "").toLowerCase();
                row['que-yearly-rental'] = (row['que-yearly-rental'] || "").toLowerCase();

                rows.push({
                    index: index + 1,
                    que_next_visit: row['que-next-visit'],
                    que_yearly_rental: row['que-yearly-rental'],
                    merchant_id: row['merchant-id'],
                    company_name: row['company-name'],
                    trading_name: row['trading-name'],
                    status: row['status'],
                    state: row['state'],
                });

            });

            form.reset({ services: rows });
            form.setKey();
            setLoading(false);
        })

    }, []);

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >


            <FieldHidden
                form={form}
                name={'type'}
                value={type}
            />

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldParseFile
                        label={"Import File"}
                        required={true}
                        accept={".xlsx,.xls,.csv"}
                        onFileLoaded={parseData}
                        optsCSV={{
                            header: true,
                            dynamicTyping: false,
                            skipEmptyLines: true,
                            transformHeader: (text, index) => slug(text),
                        }}
                        optsXLS={{
                            toCSV: true,
                            singleSheet: true,
                        }}
                    />

                </Col>

            </Row>

            {loading &&
                <Loading />
            }

            {!loading && form.values.services && form.values.services.length > 0 && (

                <ButtonRow>

                    <Button
                        label={"Validate Service Requests"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={handleReset}
                    />

                </ButtonRow>

            )}

            {!loading && form.values.services && form.values.services.length > 0 &&

                <div style={{ overflow: "auto" }}>

                    <Space />

                    <FieldHidden
                        form={form}
                        value={form.values.services}
                        name={"services"}
                    />

                    <DataTable
                        data={null}
                        columns={columns}
                        className={"input-table"}
                        style={{ overflowY: "scroll" }}
                    >

                        {form.values.services.map((service, index) => (

                            <InputTableRow key={index}>

                                <DataTableCell style={{ minWidth: "30px" }}>
                                    {service.index}
                                </DataTableCell>

                                <DataTableCell style={{ minWidth: "130px" }}>
                                    {type === "annual_visitation" &&
                                        titleCase(service.que_next_visit)
                                    }
                                    {type === "yearly_rental" &&
                                        titleCase(service.que_yearly_rental)
                                    }
                                </DataTableCell>

                                <DataTableCell style={{ width: "90px" }}>
                                    {service.merchant_id}
                                </DataTableCell>

                                <DataTableCell style={{ maxWidth: "200px" }} className={"cell-elipsis"}>
                                    {service.company_name}
                                </DataTableCell>

                                <DataTableCell style={{ maxWidth: "200px" }} className={"cell-elipsis"}>
                                    {service.trading_name}
                                </DataTableCell>

                                <DataTableCell>
                                    <Status status={service.status} prefix='merchants.status' />
                                </DataTableCell>

                                <DataTableCell>
                                    {service.state}
                                </DataTableCell>

                            </InputTableRow>

                        ))}

                        <Space height={300} />

                    </DataTable>

                </div>

            }

        </Form>


    ), [form.hash, form.changed, process.loading, loading]);

}

export default ServicesImportCheckForm;
