import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import MigrationsApi from './MigrationsApi';
import { ModelLink } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'migrations',
    zone: 'utils',
    path: '/migrations',
    title: "Migrations",
    endpoint: MigrationsApi.endpoint,
    modelName: 'Migrations',
    idKey: 'id',
    primaryKey: 'title',
    indexCaps: ["viewMigrations"],
    viewCaps: ["viewMigrations"],
    createCaps: ["createMigrations"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: MigrationsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 100, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'type', 'newModelName', 'source'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Migration",
                key: "this",
                render: (value, row) => (
                    <ModelLink data={{ id: row.id, title: "View Migration" }} modelName="Migrations" />
                )
            },
            {
                label: "WP Type",
                key: "type",
            },
            {
                label: "New Model Name",
                key: "newModelName",
            },
            {
                label: "Source",
                key: "source",
                format: "status"
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: MigrationsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: MigrationsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data.type + " > " + data.newModelName;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};