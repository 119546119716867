import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import TerminalsSettings from '../../Terminals/TerminalsSettings';

const MassRolloutsTerminal: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        const terminalIds = data.terminals.map(terminal => terminal.id);

        let filters = TerminalsSettings.indexFetchParams(routeName, params, users);

        filters.where.id = { inq: terminalIds };

        return filters;

    };

    return useMemo(() => (

        <>

            <IndexTable
                title={"Terminal"}
                getFetch={TerminalsSettings.indexFetch}
                getColumns={TerminalsSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={TerminalsSettings.indexQuickSearch}
                getSearchFields={TerminalsSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data, tableState]);

}

export default MassRolloutsTerminal;
