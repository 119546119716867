import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import ServiceStageAttemptFailed from './ServiceStageAttemptFailed';
import { FieldDate, FieldRelationship } from '@dex/bubl-dash';
import { validateDatePassed } from '../../../functions/sharedValidation';
import { userHasCap } from '@dex/bubl-dash/src/lib/functions/useUser';
import ServiceStageIncompleteMessages from './ServiceStageIncompleteMessages';


const ServiceStageTrainingDetails: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_training_details");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasCap(["technician"]);
    const isManager = isTechnician && userHasCap(["companyManager"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"training_complete"}
            heading={"Training Details"}
            editCaps={["stage_training_detailsServices"]}
            editable={false}
            showForm={data.training_complete !== "yes"}
            showMeta={data.training_complete}
            passed={data.training_complete === "yes"}
            showSave={form.changed}
            reverse={data.training_complete === "yes" && isStaff ? null : reverse}
            last_stage={"accepted_service_request"}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Training Complete",
                    key: "training_complete",
                    format: "titleCase"
                },
                {
                    label: "Technician",
                    key: "technician",
                    format: "modelLink"
                },

            ]}
            columnB={[
                data.training_complete === "yes" && {
                    label: "Service Date",
                    key: "service_date",
                    format: "date"
                },
                data.training_complete === "yes" && {
                    label: "Service Report Photo",
                    key: "service_photo",
                    format: "file"
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                label={"Training Complete"}
                                name='training_complete'
                                required={true}
                                defaultValue={form.defaults.training_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" }
                                ]}
                            />

                        </Col>



                        {form.values.training_complete === "yes" && (
                            <>
                                <Col col={{ xs: 24, md: 8 }}>
                                </Col>

                                {!isTechnician &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            form={form}
                                            label="Technician"
                                            name="technician_id"
                                            required={true}
                                            model={"Users"}
                                            preload={true}
                                            where={{ roles: "technician", provider_id: data.provider_id }}
                                            defaultValue={form.defaults.technician_id}
                                        />

                                    </Col>
                                }

                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldDate
                                        form={form}
                                        name={"service_date"}
                                        required={true}
                                        label={"Service Date"}
                                        defaultValue={form.defaults.service_date || ""}
                                        disabledDate={isDateDisabled}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 10 }}>
                                    <FieldUpload
                                        form={form}
                                        label={"Service Report Photo"}
                                        name="service_photo"
                                        required={true}
                                        defaultValue={form.defaults.service_photo}
                                        folder={"uploads"}
                                        limit={2}
                                    />

                                </Col>
                            </>

                        )}

                    </Row>

                    {form.values.training_complete === "failed" &&
                        <>
                            <ServiceStageAttemptFailed
                                form={form}
                                data={data}
                            />
                        </>
                    }
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageTrainingDetails;
