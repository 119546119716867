import React, { useCallback, useMemo } from 'react';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import MassRolloutsApi from '../MassRolloutsApi';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';

const MassRolloutStageDeliveryNote: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const app = useApp();

    const process = MassRolloutsApi.update(data.id, "stage_delivery");

    const handlePrintDeliveryNote = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/massRollouts/${data.id}/delivery-note/${action}`);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"delivery"}
            heading={"Print Delivery Note"}
            editable={false}
            showForm={true}
            showMeta={false}
            passed={data.print_documents === "yes"}
            columnA={[

            ]}
            columnB={[
            ]}
            fields={
                <>

                    <ButtonRow>

                        <Button
                            label={"Print Delivery Note"}
                            type={data.print_documents !== "yes" ? "primary" : "faded"}
                            onClick={handlePrintDeliveryNote.bind(null, "print")}
                        />

                        {app.userCan(["super"]) && (
                            <Button
                                label={"Preview Delivery Note"}
                                type='faded'
                                onClick={handlePrintDeliveryNote.bind(null, "preview")}
                                size='regular'
                            />
                        )}

                    </ButtonRow>

                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default MassRolloutStageDeliveryNote;
