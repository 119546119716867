import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import HelpdeskTicketsApi from './HelpdeskTicketsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';


let settings;

export default settings = {
    key: 'helpdesk/tickets',
    zone: 'helpdesk',
    path: '/helpdesk/tickets',
    title: "Helpdesk Tickets",
    endpoint: HelpdeskTicketsApi.endpoint,
    modelName: 'HelpdeskTickets',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewHelpdeskTickets"],
    viewCaps: ["viewHelpdeskTickets"],
    createCaps: ["createHelpdeskTickets"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Open",
                path: settings.path + "/status/open"
            },
            {
                label: "Resolved",
                path: settings.path + "/status/resolved"
            },
            {
                label: "Unresolved",
                path: settings.path + "/status/unresolved"
            },
            {
                label: "Closed",
                path: settings.path + "/status/closed"
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: HelpdeskTicketsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'merchant',
                    preset: "link"
                }
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "helpdeskTickets", label: "HelpdeskTickets" }
        ];

        if (!user || !user.caps.includes("exportHelpdeskTickets")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user, app) => {

        const fields = ['id', 'original_id', 'topic', 'person', 'company_name', 'serial', 'tid'];

        return fields;

    },
    indexSearchFields: (routeName, params, user, app) => {

        const fields = [
            {
                label: "Ticket ID",
                key: "id",
                type: "text",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "new", label: "New" },
                    { value: "open", label: "Open" },
                    { value: "resolved", label: "Resolved" },
                    { value: "unresolved", label: "Unresolved" },
                    { value: "assign_technician", label: "Assign Technician" },
                    { value: "closed", label: "Closed" },
                ]
            },
            {
                label: "Priority",
                key: "priority",
                type: "choice",
                options: [
                    { value: "normal", label: "Normal" },
                    { value: "high", label: "High" },
                    { value: "urgent", label: "Urgent" },
                ]
            },
            {
                label: "Topic",
                key: "topic",
                type: "choice",
                options: [
                    { value: "service_request", label: app.intl("helpdesk.topic.service_request") },
                    { value: "paper_roll", label: app.intl("helpdesk.topic.paper_roll") },
                    { value: "training", label: app.intl("helpdesk.topic.training") },
                    { value: "general_inquiry", label: app.intl("helpdesk.topic.general_inquiry") },
                    { value: "installation", label: app.intl("helpdesk.topic.installation") },
                    { value: "sales", label: app.intl("helpdesk.topic.sales") },
                    { value: "software_update", label: app.intl("helpdesk.topic.software_update") },
                    { value: "finance", label: app.intl("helpdesk.topic.finance") },
                ]
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text",
            },
            {
                label: "Person",
                key: "person",
                type: "text",
            },
            {
                label: "TID",
                key: "tid",
                type: "text",
            },
            {
                label: "Serial",
                key: "serial",
                type: "text",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },
    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Ticket ID",
                key: "this",
                format: "ModelLink",
                render: (value, row) => <OriginalModelLink data={row} />
            },
            {
                label: "Topic",
                key: "topic",
                format: "intl",
                prefix: "helpdesk.topic"
            },
            {
                label: "Status",
                key: "status",
                format: "status",
            },
            {
                label: "Priority",
                key: "priority",
                format: "status",
            },
            // {
            //     label: "Person",
            //     key: "person",
            // },
            {
                label: "TID",
                key: "tid",
            },
            {
                label: "Serial",
                key: "serial",
            },
            {
                label: "Company Name",
                key: "company_name",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: HelpdeskTicketsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: HelpdeskTicketsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'service',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string | any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        if (data) {
            title = <>
                {title}
                <WhiteSpace /> <Status status={data?.status} prefix='helpdesk' />
                {data.priority && <><WhiteSpace /> <Status status={data?.priority} prefix='helpdesk' /></>}
            </>
        }

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
            {
                label: "Activity",
                path: path + "/activity"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },
};