import React, { useMemo } from 'react'
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const MigrationSingleViewGeneral: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <MetaTable
            data={data}
            columnA={[
                {
                    label: "WP Type",
                    key: "type",
                    format: "link",
                },

            ]}
            columnB={[
                {
                    label: "New Model Name",
                    key: "newModelName",
                },
            ]}
            columnC={[
                {
                    label: "Source",
                    key: "source",
                    format: "status"
                },
            ]}
        />


    ), [props]);
}

export default MigrationSingleViewGeneral