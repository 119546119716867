import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import InstallationsApi from './InstallationsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';
import { formatTime } from '../../functions/sharedMerchant';


let settings;

export default settings = {
    key: 'installations',
    zone: 'ops',
    path: '/installations',
    title: "Installations",
    endpoint: InstallationsApi.endpoint,
    modelName: 'Installations',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewInstallations"],
    viewCaps: ["viewInstallations"],
    createCaps: ["createInstallations"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "New",
                path: settings.path + "/status/new",
            },
            {
                label: "Testing",
                path: settings.path + "/status/testing",
            },
            {
                label: "Perso",
                path: settings.path + "/status/perso",
            },
            {
                label: "Quality Control",
                path: settings.path + "/status/quality_control",
            },
            {
                label: "Assign Technician",
                path: settings.path + "/status/assign_technician",
            },
            {
                label: "Outbound",
                path: settings.path + "/status/outbound",
            },
            {
                label: "Installation",
                path: settings.path + "/status/installation",
            },

            {
                label: "Complete",
                path: settings.path + "/status/complete",
            },
            {
                label: "Complete Checked",
                path: settings.path + "/status/complete_checked",
            },
            {
                label: "Incomplete",
                path: settings.path + "/status/incomplete",
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
            },
            {
                label: "Failed",
                path: settings.path + "/status/failed",
            },
            {
                label: "Verified",
                path: settings.path + "/status/verified",
            },
            // {
            //     label: "In Progress",
            //     path: settings.path + "/status/in_progress",
            // },
            // {
            //     label: "Pending",
            //     path: settings.path + "/status/pending",
            // },

        ]

    },
    indexButtons: (routeName, params, user) => {

        return [];

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: InstallationsApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'swivel',
                    preset: "link"
                },
                {
                    relation: 'router',
                    preset: "link"
                },
                {
                    relation: 'docking',
                    preset: "link"
                },
                {
                    relation: 'sim',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        let { status } = params || {};

        if (status) filters.where.status = { inq: [status] };

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "installations", label: "Installations" }
        ];

        if (!user || !user.caps.includes("exportInstallations")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'original_id', 'title'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Installation ID",
                key: "id",
                type: "text",
            },
            {
                label: "Merchant",
                key: "merchant_id",
                type: "relationship",
                model: "Merchants"
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text",
            },
            {
                label: "TID",
                key: "tid",
                type: "text",
            },
            {
                label: "Service Provider",
                key: "provider_id",
                type: "relationship",
                model: "Providers"
            },
            {
                label: "Technician",
                key: "technician_id",
                type: "relationship",
                model: "Users"
            },
            {
                label: "Serial Number",
                key: "serial_id",
                type: "relationship",
                model: "Terminals"
            },
            {
                label: "State",
                key: "state",
                type: "text",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },

        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Installation ID",
                key: "this",
                format: "ModelLink",
                render: (value, row) => <OriginalModelLink data={row} />
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "installation.status"
            },
            {
                label: "Merchant",
                key: "merchant",
                format: "ModelLink"
            },
            {
                label: "Company Name",
                key: "merchant.company_name",
            },
            {
                label: "TID",
                key: "merchant.tid",
            },
            {
                label: "Service Provider",
                key: "provider",
                format: "ModelLink"
            },
            {
                label: "Technician",
                key: "technician",
                format: "ModelLink"
            },
            {
                label: "Total Time",
                key: "total_time",
                render: formatTime
            },
            {
                label: "Stage Time",
                key: "stage_time",
                render: formatTime
            },
            {
                label: "Serial Number",
                key: "terminal.serial",
            },
            {
                label: "State",
                key: "state",
            },
            {
                label: "City",
                key: "installation_address.city",
                type: "text",
            },
            {
                label: "Contact Person",
                key: "person",
            },
            {
                label: "Phone",
                key: "phone",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: InstallationsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: InstallationsApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'merchant',
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'engagement_plan',
                    preset: "link"
                },
                {
                    relation: 'terminal',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
                {
                    relation: 'outbound_collector',
                    preset: "link"
                },
                {
                    relation: 'swivel',
                    preset: "link"
                },
                {
                    relation: 'router',
                    preset: "link"
                },
                {
                    relation: 'docking',
                    preset: "link"
                },
                {
                    relation: 'sim',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace />{data && <Status status={data?.status} prefix='installation.status' />}</>;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },

            {
                label: "Activity",
                path: path + "/activity"
            }
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },

};