import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import ServicesApi from '../ServicesApi';
import { Line, ButtonRow, Button, Panel, Alert, FieldTextArea, FieldUpload } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import ServicesSettings from '../ServicesSettings';

const ServicesSingleReopenForm: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data } = props;

    const form = useForm({ reopen_application_reason: data.reopen_application_reason });

    const canReopen = app.userCan(['reopenServices']);

    const update = ServicesApi.update(data.id, "reopen", {
        onComplete: (response) => {

            app.alert('Service Saved', 'success');

            //navigate to single view after saving and replace current route
            const route = { "type": "Navigation/NAVIGATE", routeName: ServicesSettings.key + "View", params: { id: data.id } };

            app.navigate(nav, 'repalce', route);

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Reopen Reopenled Service"}
        >

            {!canReopen &&
                <Alert
                    type={"warning"}
                    message={"Access Denied"}
                    description={"You do not have permission to reopen this service."}
                />
            }

            {canReopen &&
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={update.loading}
                >

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Reopen Service"}
                                name='reopen_service'
                                required={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.reopen_service === "yes" && (
                            <>
                                <Col col={{ xs: 24, md: 12 }}>
                                    <FieldTextArea
                                        form={form}
                                        label={"Reason For Reopening"}
                                        name='reopen_remarks'
                                        required={true}
                                        defaultValue={form.defaults.reopen_remarks}
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>
                                    <FieldUpload
                                        form={form}
                                        label={"Supporting Documents"}
                                        name="reopen_documents"
                                        defaultValue={form.defaults.reopen_documents}
                                        folder={"uploads"}
                                        limit={3}
                                    />
                                </Col>

                            </>
                        )}


                    </Row>

                    <Line />

                    {form.values.reopen_service === "yes" &&
                        <ButtonRow>
                            <Button
                                label={"Save"}
                                loading={update.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />
                        </ButtonRow>
                    }

                </Form>
            }

        </Panel>

    ), [data, form.hash, update]);

}

export default ServicesSingleReopenForm;
