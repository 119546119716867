import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const SwivelLink: React.FC<SwivelLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            {(data && data.id) ?
                <ModelLink data={data} primaryKey={"swivel_id"} />
                :
                <>-</>
            }
        </>

    )

}

interface SwivelLinkProps {
    data: any,
    [key: string]: any,
}

export default SwivelLink;