import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRelationship } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldServiceProviders from '../../../fields/FieldServiceProviders/FieldServiceProviders';

const ServiceStageAssignServiceProvider: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_assign_provider");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    let last_stage = "";
    if (data.mode_of_update === "onsite_swap") {
        last_stage = "quality_control"
    } else {
        last_stage = "update_details"
    };

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"assign_service_provider"}
            heading={"Assign Service Provider"}
            editCaps={["stage_assign_providerServices"]}
            viewCaps={["viewServices"]}
            editable={false}
            showForm={data.assign_service_provider !== "yes"}
            showMeta={data.assign_service_provider === "yes"}
            passed={data.assign_service_provider === "yes"}
            showSave={form.changed}
            reverse={(data.assign_service_provider === "yes" || (data.mode_of_update !== "onsite_swap" && data.mode_of_update !== "onsite_update")) ? null : reverse}

            // reverse={(data.assign_service_provider === "yes" || data.mode_of_update !== "onsite_swap") ? null : reverse}
            last_stage={last_stage}
            columnA={[
                {
                    label: "Service Provider",
                    key: "provider",
                    format: "ModelLink"
                },
                (data.type !== "fault_call" && data.type !== "annual_visitation") && {
                    label: "Collection Method",
                    key: "collection_method",
                    format: "intl",
                    prefix: "service.collection_method",
                },
            ].filter(Boolean)}
            columnB={[
                {
                    label: "Contact Person",
                    key: "provider.person",
                },
                {
                    label: "Phone",
                    key: "provider.phone",
                },
            ]}
            columnC={[
                {
                    label: "Address",
                    key: "provider.billing_address",
                    format: "address"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Service Provider"}
                                        name='provider_id'
                                        required={true}
                                        model={'Providers'}
                                        preload={true}
                                        defaultValue={form.defaults.provider_id}
                                    />

                                </Col>

                                {data.type !== "fault_call" && data.type !== "annual_visitation" &&
                                    <Col col={{ xs: 24, md: 16 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Collection Method"}
                                            name='collection_method'
                                            required={true}
                                            defaultValue={form.defaults.collection_method}
                                            options={[
                                                { label: "Self Collect", value: "self_collect" },
                                                { label: "Postage", value: "postage" },
                                            ]}
                                        />

                                    </Col>
                                }

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageAssignServiceProvider;
