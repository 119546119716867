import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import ClaimsApi from '../ClaimsApi';
import Stage from '../../../elements/Stage/Stage';

const ClaimStageReview: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ClaimsApi.update(data.id, "stage_review");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"review"}
            heading={"Review"}
            editable={false}
            showForm={data.review !== "yes"}
            showMeta={data.review === "yes"}
            showSave={form.changed}
            passed={data.review === "yes"}
            columnA={[
                {
                    label: "Review Status",
                    key: "review_status",
                    format: "intl",
                    prefix: "claim.status",
                    hideIfEmpty: true
                }
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"status"}
                                label={"Claim Status"}
                                defaultValue={form.defaults.status}
                                options={[
                                    { label: "Approved", value: "approved" },
                                    { label: "Denied", value: "denied" },
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ClaimStageReview;
