import React, { useMemo } from 'react';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldList, Space, useApp } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';

const SettingsDepartments: React.FC<any> = (props: any) => {

    const { form } = props;

    const app = useApp();

    const [settings] = app.store("appSettings", {});

    return useMemo(() => (

        <>

            <Space />

            <Seperator
                heading="All Departments"
                bottom={"small"}
                top={false}
            />

            <FieldList
                form={form}
                name="department"
                required={false}
                addButtonLabel={"Add Department"}
                itemLabel='Department'
            >

                {(form.values?.department || []).map((item, index) => (
                    <>
                        <FieldText
                            form={form}
                            label={!index ? `Department Name` : ``}
                            name={`department[${index}]`}
                            defaultValue={get(form.values, `department[${index}]`)}
                            required
                        />
                    </>
                ))}

            </FieldList>

        </>

    ), [form.hash]);

}

export default SettingsDepartments;
