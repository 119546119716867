import React, { useMemo } from 'react';
import ServiceStageAmendmentRequest from './ServiceStageAmendmentRequest';
import ServiceStageApproveAmendment from './ServiceStageApproveAmendment';

const ServiceStagesAmendment: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ServiceStageAmendmentRequest
                data={data}
            />

            {data.request_amendment === "yes" && data.cancel_service !== "yes" && (

                <ServiceStageApproveAmendment
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ServiceStagesAmendment;
