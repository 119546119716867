import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ClaimsEntryByTypeView from './ClaimsEntryByTypeView';
import ClaimStages from '../Stages/ClaimStages';
import { Button, ButtonRow, useApp } from '@dex/bubl-dash';
import { Parser } from 'json2csv';
import { titleCase } from '@dex/bubl-helpers';

const ClaimsSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const app = useApp();

    const handlePrint = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/claims/${data.id}/claim/${action}`);

    }, []);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
                key={hash}
            >

                <Panel
                    heading={"Claim Details"}
                    actions={() => (
                        <ButtonRow>
                            <Button
                                label={"Preview"}
                                type={"faded"}
                                size={'small'}
                                onClick={handlePrint.bind(null, "preview")}
                            />
                            <Button
                                label={"Print"}
                                type={"light"}
                                size={'small'}
                                onClick={handlePrint.bind(null, "print")}
                            />
                        </ButtonRow>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                            {
                                label: "Additional Notes",
                                key: "notes",
                                hideIfEmpty: true,
                                format: "nl2br"
                            },
                        ]}
                        columnB={[
                            data.provider_id && {
                                label: "Provider",
                                key: "provider",
                                format: "modelLink"
                            },
                            (data.agent_id) && {
                                label: "Advisor",
                                key: "agent",
                                format: "modelLink"
                            },
                            (data.agency_id) && {
                                label: "Agency",
                                key: "agency",
                                format: "modelLink"
                            }
                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "Total Amount",
                                key: "total",
                                format: 'number',
                                prefix: "RM ",
                            },
                        ]}
                    />

                </Panel>

                <Space />

                <ClaimsEntryByTypeView
                    data={data}
                />

                <Space />

                <ClaimStages
                    data={data}
                />

            </Col>

        </Row>

    ), [data]);

}

export default ClaimsSingleView;
