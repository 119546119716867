import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import React, { useMemo } from 'react';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';

const ServiceStageRecordVerified: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_record_verified");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    const canReverse = data.status === "verified";

    return useMemo(() => (
        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"record_verified"}
            heading={"Service Record Verified"}
            editable={false}
            showForm={data.record_verified !== "yes"}
            showMeta={data.record_verified === "yes"}
            passed={data.record_verified === "yes"}
            editCaps={["stage_record_verifiedServices"]}
            showSave={form.changed}
            columnA={[
                {
                    label: "Record Verified",
                    key: "record_verified",
                    format: "titleCase",
                },
            ]}
            columnB={[]}
            fields={
                <>
                    <Row gutter={8} edge={true}>
                        <Col col={{ xs: 24, md: 12 }}>
                            <FieldRadio
                                form={form}
                                label={"Record Verified"}
                                name="record_verified"
                                required={true}
                                defaultValue={form.defaults.record_verified}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            }
        />
    ), [props, form.hash, process.loading]);

}

export default ServiceStageRecordVerified;
