import React, { useEffect, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import ClaimsEntrySalesApi from '../../ClaimsEntrySales/ClaimsEntrySalesApi';
import ClaimsEntryServicesApi from '../../ClaimsEntryServices/ClaimsEntryServicesApi';
import { Col, FieldTextArea, InputTable, InputTableCell, InputTableRow, ModelLink, Status } from '@dex/bubl-dash';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import { DataTableCell } from '@dex/bubl-dash/src/lib/components/DataTable/DataTableRow';
import { date, formatNum, get, parseNum, titleCase } from '@dex/bubl-helpers';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const ClaimsEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const type = data.type;

    const entries = type === "sales" ? ClaimsEntrySalesApi.get() : ClaimsEntryServicesApi.get();

    const status = form.values.status;

    let columns: any = [];

    useEffect(() => {

        let include: any = [];

        if (type === "sales") include = [
            {
                relation: 'merchant',
                scope: {
                    include: ['invoice']
                }
            }
        ];

        entries.run({
            params: {
                where: { claim_id: data.id },
                include: include,
                limit: 1000,
                order: ["created.date DESC"]
            },
            onComplete: (response) => {

                const notes = {};
                const amounts = {};

                response.data.map((entry) => {

                    notes[entry.id] = entry.notes;
                    amounts[entry.id] = entry.amount;

                });

                form.handleChange({
                    name: "lines",
                    value: notes,
                    reset: true,
                    silent: true
                });

                form.handleChange({
                    name: "amounts",
                    value: amounts,
                    reset: true,
                    silent: true
                });

            }

        });

    }, []);

    if (type === "sales") {

        columns = [
            {
                label: "Notes",
            },
            {
                label: "Commission",
            },
            {
                label: "Merchant",
            },
            {
                label: "Location",
            },
            {
                label: "Engagement Plan",
            },
            {
                label: "Invoice",
            },
            {
                label: "Total",
            },
            {
                label: "Date Installed",
            },
        ];


    } else if (type === "service") {

        columns = [
            {
                label: "Notes",
            },
            {
                label: "Payout",
            },
            {
                label: "Merchant",
            },
            {
                label: "Technician",
            },
            {
                label: "Request ID",
            },
            {
                label: "Type",
            },
            {
                label: "Service Time",
            },
            {
                label: "Travel Distance",
            },
            {
                label: "Approved Travel Distance",
            },
            {
                label: "SLA",
            },
            {
                label: "Acquirer",
            },

        ];

    }

    const total = useMemo(() => {

        let total = 0;

        if (form.values.amounts) {

            Object.values(form.values.amounts).map((amount: any) => {

                total += parseNum(amount);

            });

        }

        return total;

    }, [form.values]);

    return useMemo(() => (

        <Panel
            heading={"Claim Details"}
        >

            {entries.data && entries.data.data && (
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <Row gutter={"auto"} edge>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldTextArea
                                form={form}
                                name={"notes"}
                                label={"Notes"}
                                defaultValue={data.notes}
                                rows={4}
                            />

                        </Col>

                    </Row>

                    <InputTable
                        columns={columns}
                    >

                        {entries.data.data.map((row: any, index: number) => (

                            <InputTableRow key={index}>

                                {status !== 'pending' ?
                                    <>
                                        <DataTableCell>
                                            {row.notes}
                                        </DataTableCell>

                                        <DataTableCell>
                                            RM {row.amount}
                                        </DataTableCell>

                                    </>
                                    :
                                    <>
                                        <InputTableCell style={{ width: 175, minWidth: 175, maxWidth: 175 }}>

                                            <FieldText
                                                form={form}
                                                name={`lines.${row.id}`}
                                                defaultValue={get(form.defaults, `lines.${row.id}`)}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 100, minWidth: 100, maxWidth: 100 }}>

                                            <FieldNumber
                                                form={form}
                                                name={`amounts.${row.id}`}
                                                defaultValue={get(form.defaults, `amounts.${row.id}`)}
                                                prefix={"RM "}
                                            />

                                        </InputTableCell>
                                    </>
                                }

                                <DataTableCell className='cell-elipsis' style={{ maxWidth: 150 }}>
                                    <ModelLink data={{ id: row.merchant_id }} modelName={"Merchants"} />
                                    <br /><small style={{ fontSize: '.88em' }}>{row.merchant_name}</small>
                                </DataTableCell>


                                {type === "sales" && (

                                    <>

                                        <DataTableCell>
                                            {titleCase(row.merchant_location)}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {row.engagement_plan_name}
                                        </DataTableCell>

                                        <DataTableCell>
                                            <ModelLink data={{ id: row.merchant.invoice_id }} modelName={"Invoices"} />
                                        </DataTableCell>

                                        <DataTableCell>
                                            {formatNum(row.merchant?.invoice?.total, 2, "RM ")}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {date(row.date_installed)}
                                        </DataTableCell>

                                    </>
                                )}

                                {type === "service" && (

                                    <>

                                        <DataTableCell>
                                            {row.technician_name}
                                        </DataTableCell>

                                        <DataTableCell>
                                            <ModelLink data={{ id: row.ops_id }} modelName={row.ops_type} />
                                        </DataTableCell>

                                        <DataTableCell>
                                            <Status status={row.type} prefix='service.type' />
                                        </DataTableCell>

                                        <DataTableCell>
                                            {formatNum(row.service_time, 0, '', ' hours')}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {formatNum(row.mileage, 0, '', ' KM')}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {titleCase(row.approved_travel_distance)}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {titleCase(row.sla)}
                                        </DataTableCell>

                                        <DataTableCell>
                                            {row.acquirer_name}
                                        </DataTableCell>

                                    </>

                                )}

                            </InputTableRow>

                        ))}

                    </InputTable>


                    <Space />

                    <MetaTable
                        data={{
                            total: total
                        }}
                        columnA={[
                            {
                                label: "Total",
                                key: "total",
                                format: "number",
                                prefix: "RM ",
                            },
                        ]}
                    />

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>
            )}

        </Panel>

    ), [form.hash, entries.loading, process.loading, total]);

}

export default ClaimsEditForm;
