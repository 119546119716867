import React, { useMemo } from 'react';
import UsersInfoEditPanel from './Components/UsersInfoEditPanel'
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const UsersSingleEditMain: React.FC<any> = (props: any) => {

    return useMemo(() => (

        <>

            <Space />

            <UsersInfoEditPanel {...props} />

        </>

    ), [props]);

}

export default UsersSingleEditMain;
