import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServiceStageDeliveryComplete from './ServiceStageDeliveryComplete';
import ServiceStageOutbound from './ServiceStageOutbound';
import ServiceStagePreparePackage from './ServiceStagePreparePackage';

const ServiceStagesPaperRoll: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ServiceStagePreparePackage
                data={data}
            />

            {data.prepare_package === "yes" && (

                <ServiceStageOutbound
                    data={data}
                />

            )}

            {data.outbound === "yes" && (

                <ServiceStageDeliveryComplete
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ServiceStagesPaperRoll;
