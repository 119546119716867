import React, { useMemo } from 'react';
import styles from "./ConversationPanel.module.scss";
import { Avatar, Col, Panel, Row, useForm } from '@dex/bubl-dash';
import { dateTime } from '@dex/bubl-helpers';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';

const ConversationPanel: React.FC<ConversationPanelProps> = (props) => {

    const { data } = props;

    return useMemo(() => (

        <>

            {data.threads.map((thread, index) => (

                <div key={index}>

                    <Seperator
                        heading={thread.name}
                        description={dateTime(thread.date)}
                        top="auto"
                        bottom="auto"
                        size="4"
                        className={styles.messageContainer}
                    />

                    <p>{thread.message}</p>

                    {thread.attachments && thread.attachments.length > 0 &&
                        <>
                            <Space height={"small"} />
                            <MetaTable
                                data={thread}
                                columnA={[
                                    {
                                        label: "Attachments",
                                        key: "attachments",
                                        format: "file",
                                    },
                                ]}
                            />

                        </>
                    }

                    <Space height={"small"} />

                </div>

            ))}

        </>

    ), []);

}

interface ConversationPanelProps {
    [key: string]: any,
}

export default ConversationPanel;