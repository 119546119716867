import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import MigrationsCreateEditForm from "./Components/MigrationsCreateEditForm";
import MigrationsApi from "./MigrationsApi";
import MigrationsAutoRunForm from './Components/MigrationsAutoRunForm';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: MigrationsCreateEditForm,
                getCreate: MigrationsApi.create,
                defaults: {},
                successMessage: "Migration",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });


    app.addRoute({
        name: settings.key + "AutoRun",
        zone: settings.zone,
        path: settings.key + "/auto-run",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Auto Run",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: MigrationsAutoRunForm,
                getCreate: MigrationsApi.create,
                handler: "autoRun",
                defaults: {},
                successMessage: "Auto Run Migration Qued",
            },
        },
    });

}