import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import { DataTable, FieldNumber, FieldRadio, FieldRange, FieldRelationship, FieldText, InputTableCell, InputTableRow, Line, MetaTable, ModelLink } from '@dex/bubl-dash';
import { formatNum, loop, parseNum } from '@dex/bubl-helpers';
import { InvoiceLineItemsBreakdown } from '../../Invoices/Components/InvoiceLineItems';

const ServicesStagePrepareInvoice: React.FC<any> = (props: any) => {

    const { data, apiService, swap } = props;

    const form = useForm(data);

    const process = apiService.update(data.id, "stage_prepare_invoice");

    const plan = data.engagement_plan;

    const columns: any = [
        {
            label: "Description",
            key: "description",
        },
        {
            label: "Amount",
            key: "amount",
            alignEnd: true,
        },
        swap ?
            null
            : {
                label: "Agent's Commission",
                key: "commission",
                alignEnd: true,
                className: "table-bg-gray table-bdr-left-gray"
            },
    ].filter(Boolean);


    const subtotal = useMemo(() => {

        let amount = parseNum(plan?.charge_merchant_subtotal || 0);

        loop(data.selected_addons, (addon) => {

            amount += parseNum(addon.selling_price);

        });

        if (form.values.adjustment_amount) {
            amount += parseNum(form.values.adjustment_amount);
        }

        return amount;

    }, [plan, data.selected_addons, form.values.adjustment_amount]);

    const commission = useMemo(() => {

        let amount = parseNum(plan?.charge_merchant_agent_commission || 0) + parseNum(form.values.commission_adjustment_amount || 0);

        loop(data.selected_addons, (addon) => {

            amount += parseNum(addon.agents_commission);

        });

        return amount;

    }, [plan, data.selected_addons, form.values.commission_adjustment_amount]);

    const deposit = useMemo(() => {

        let amount = parseNum(plan?.charge_merchant_deposit || 0);

        if (form.values.deposit_adjustment_amount) {
            amount += parseNum(form.values.deposit_adjustment_amount);
        }

        return amount;

    }, [plan, form.values.deposit_adjustment_amount]);

    const total = useMemo(() => {

        return subtotal + deposit;

    }, [plan, deposit, subtotal]);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"prepare_invoice"}
            heading={"Prepare Invoice"}
            editCaps={["stage_prepare_invoiceServices"]}
            editable={data.payment_verified !== "yes" && data.prepare_invoice && ["in_progress"].includes(data.status)} //todo: change to false after testing
            showForm={(!data.prepare_invoice || data.prepare_invoice === "no")}
            showMeta={data.prepare_invoice}
            showSave={form.changed}
            passed={data.prepare_invoice}
            columnA={[
                data.prepare_invoice === 'linked' && {
                    key: "invoice_id",
                    label: "Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="Invoices" />
                    )
                },
                (data.prepare_invoice === 'linked_proforma' || data.prepare_invoice === 'yes') && {
                    key: "proforma_invoice_id",
                    label: "Proforma Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="ProformaInvoices" />
                    )
                },
            ].filter(Boolean)}
            columnB={[
                data.prepare_invoice === 'linked' && {
                    label: "Status",
                    key: "invoice.status",
                    format: "status",
                },
                (data.prepare_invoice === 'linked_proforma' || data.prepare_invoice === 'yes') && {
                    label: "Status",
                    key: "proforma_invoice.status",
                    format: "status",
                }
            ].filter(Boolean)}
            columnC={[
                data.prepare_invoice === 'linked' && {
                    label: "Total",
                    key: "invoice.total",
                    format: "number",
                    prefix: "RM "
                },
                (data.prepare_invoice === 'linked_proforma' || data.prepare_invoice === 'yes') && {
                    label: "Total",
                    key: "proforma_invoice.total",
                    format: "number",
                    prefix: "RM "
                },

                data.prepare_invoice === 'linked' && {
                    label: "Refundable Deposit",
                    key: "invoice.deposit",
                    format: "number",
                    prefix: "RM "
                },
                (data.prepare_invoice === 'linked_proforma' || data.prepare_invoice === 'yes') && {
                    label: "Refundable Deposit",
                    key: "proforma_invoice.deposit",
                    format: "number",
                    prefix: "RM "
                }
            ].filter(Boolean)}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Prepare Proforma Invoice"}
                                name='prepare_invoice'
                                defaultValue={form.defaults.prepare_invoice}
                                required={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "Link To Proforma Invoice", value: "linked_proforma" },
                                    { label: "Link To Paid Invoice", value: "linked" },
                                ]}
                            />

                        </Col>

                        {form.values.prepare_invoice === "linked" &&
                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Link To Invoice No"}
                                    name={"linked_invoice_id"}
                                    defaultValue={form.defaults.linked_invoice_id}
                                    required
                                    model={"Invoices"}
                                    preload={true}
                                    where={{ status: { inq: ["paid", "partially_paid"] } }}
                                />

                            </Col>
                        }

                        {form.values.prepare_invoice === "linked_proforma" &&
                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Link To Proforma Invoice No"}
                                    name={"linked_proforma_id"}
                                    defaultValue={form.defaults.linked_proforma_id}
                                    required
                                    model={"ProformaInvoices"}
                                    preload={true}
                                    where={{ status: { inq: ["paid"] } }}
                                />

                            </Col>
                        }

                        {form.values.prepare_invoice === "yes" &&
                            <>
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Apply Adjustment"}
                                        name={"apply_adjustment"}
                                        defaultValue={form.defaults.apply_adjustment}
                                        options={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" }
                                        ]}
                                    />

                                    {form.values.apply_adjustment === "yes" &&
                                        <>

                                            <FieldText
                                                form={form}
                                                label={"Adjustment Label"}
                                                name={"adjustment_label"}
                                                defaultValue={form.defaults.adjustment_label}
                                                required
                                            />

                                            <FieldNumber
                                                form={form}
                                                label={"Adjustment Amount"}
                                                name={"adjustment_amount"}
                                                defaultValue={form.defaults.adjustment_amount || 0}
                                                prefix={"RM "}
                                                required
                                                allowNegative
                                            />

                                        </>
                                    }

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Apply Deposit Adjustment"}
                                        name={"apply_deposit_adjustment"}
                                        defaultValue={form.defaults.apply_deposit_adjustment}
                                        options={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" }
                                        ]}
                                    />

                                    {form.values.apply_deposit_adjustment === "yes" &&
                                        <>

                                            <FieldText
                                                form={form}
                                                label={"Deposit Adjustment Label"}
                                                name={"deposit_adjustment_label"}
                                                defaultValue={form.defaults.deposit_adjustment_label}
                                                required
                                            />

                                            <FieldNumber
                                                form={form}
                                                label={"Deposit Adjustment"}
                                                name={"deposit_adjustment_amount"}
                                                defaultValue={form.defaults.deposit_adjustment_amount || 0}
                                                prefix={"RM "}
                                                required
                                                allowNegative
                                            />

                                        </>
                                    }

                                </Col>

                                {!swap &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Apply Commission Adjustment"}
                                            name={"apply_commission_adjustment"}
                                            defaultValue={form.defaults.apply_commission_adjustment}
                                            options={[
                                                { label: "Yes", value: "yes" },
                                                { label: "No", value: "no" }
                                            ]}
                                        />

                                        {form.values.apply_commission_adjustment === "yes" &&
                                            <>

                                                <FieldText
                                                    form={form}
                                                    label={"Commission Adjustment Label"}
                                                    name={"commission_adjustment_label"}
                                                    defaultValue={form.defaults.adjustment_label}
                                                    required
                                                />


                                                <FieldNumber
                                                    form={form}
                                                    label={"Commission Adjustment Amount"}
                                                    name={"commission_adjustment_amount"}
                                                    defaultValue={form.defaults.commission_adjustment_amount || 0}
                                                    prefix={"RM "}
                                                    required
                                                    allowNegative
                                                />

                                            </>
                                        }

                                    </Col>
                                }

                                <Col col={{ xs: 24 }}>

                                    <DataTable
                                        data={null}
                                        columns={columns}
                                        className={"mini-table invoice-table"}
                                    >

                                        <InputTableRow>

                                            <InputTableCell style={{ width: "55%" }}>

                                                {plan?.billing_name || plan?.plan_name}

                                                <InvoiceLineItemsBreakdown
                                                    items={[
                                                        plan?.charge_merchant_processing_fee ? {
                                                            description: "Processing Fee",
                                                            amount: plan.charge_merchant_processing_fee
                                                        } : null,
                                                        plan?.charge_merchant_selling_price ? {
                                                            description: "Selling Price",
                                                            amount: plan.charge_merchant_selling_price
                                                        } : null,
                                                        plan?.charge_merchant_maintenance_fee ? {
                                                            description: "Maintenance Fee (1 Year)",
                                                            amount: plan.charge_merchant_maintenance_fee
                                                        } : null
                                                    ].filter(Boolean)}
                                                />

                                            </InputTableCell>

                                            <InputTableCell style={{ width: "20%" }} alignEnd>

                                                {formatNum(plan?.charge_merchant_subtotal, 2, "RM ")}

                                            </InputTableCell>

                                            {!swap &&
                                                <InputTableCell style={{ width: "20%" }} alignEnd className="table-bg-gray table-bdr-left-gray">

                                                    {formatNum(plan?.charge_merchant_agent_commission, 2, "RM ")}

                                                </InputTableCell>
                                            }

                                        </InputTableRow>

                                        {data.selected_addons?.length > 0 && data.selected_addons.map((addon, index) => (
                                            <InputTableRow key={index}>

                                                <InputTableCell>

                                                    Addon: {addon.name}
                                                    {/* {addon.sku && <span> ({addon.sku})</span>} */}

                                                </InputTableCell>

                                                <InputTableCell alignEnd>

                                                    {formatNum(addon.selling_price, 2, "RM ")}

                                                </InputTableCell>

                                                {!swap &&

                                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">

                                                        {formatNum(addon.agents_commission, 2, "RM ")}

                                                    </InputTableCell>
                                                }

                                            </InputTableRow>
                                        ))}

                                        {form.values.apply_adjustment === "yes" &&
                                            <InputTableRow>

                                                <InputTableCell>
                                                    Adjustment: {form.values.adjustment_label}
                                                </InputTableCell>

                                                <InputTableCell alignEnd>
                                                    {formatNum(form.values.adjustment_amount, 2, "RM ")}
                                                </InputTableCell>

                                                {!swap &&

                                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                                    </InputTableCell>

                                                }

                                            </InputTableRow>
                                        }

                                        {form.values.apply_commission_adjustment === "yes" &&
                                            <InputTableRow>

                                                <InputTableCell>
                                                    Commission Adjustment: {form.values.commission_adjustment_label}
                                                </InputTableCell>

                                                <InputTableCell alignEnd>
                                                </InputTableCell>

                                                {!swap &&

                                                    <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                                        {formatNum(form.values.commission_adjustment_amount, 2, "RM ")}
                                                    </InputTableCell>

                                                }

                                            </InputTableRow>
                                        }

                                        <InputTableRow>

                                            <InputTableCell alignEnd className="invoice-line-label">
                                                Subtotal (Excluding Tax)
                                            </InputTableCell>

                                            <InputTableCell alignEnd>
                                                {formatNum(subtotal, 2, "RM ")}
                                            </InputTableCell>

                                            {!swap &&

                                                <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                                </InputTableCell>

                                            }

                                        </InputTableRow>

                                        <InputTableRow>

                                            <InputTableCell alignEnd className="invoice-line-label">
                                                Refundable Deposit
                                            </InputTableCell>

                                            <InputTableCell alignEnd>
                                                {formatNum(deposit, 2, "RM ")}
                                            </InputTableCell>

                                            {!swap &&

                                                <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                                </InputTableCell>

                                            }

                                        </InputTableRow>

                                        <InputTableRow>

                                            <InputTableCell alignEnd className="invoice-line-label">
                                                <strong>Total</strong>
                                            </InputTableCell>

                                            <InputTableCell alignEnd>
                                                <strong>{formatNum(total, 2, "RM ")}</strong>
                                            </InputTableCell>

                                            {!swap &&

                                                <InputTableCell alignEnd className="table-bg-gray table-bdr-left-gray">
                                                    <strong>{formatNum(commission, 2, "RM ")}</strong>
                                                </InputTableCell>

                                            }

                                        </InputTableRow>

                                    </DataTable>

                                </Col>
                            </>
                        }

                    </Row>

                </>
            }
        />

    ), [props, form, process.loading]);

}

export default ServicesStagePrepareInvoice;
