import React, { useMemo, useState } from 'react';
import { Panel, Form, Row, Col, Line, ButtonRow, Button, FieldDate, FieldRelationship, FieldTextArea, FieldList, FieldText, FieldHidden, WhiteSpace, Modal } from '@dex/bubl-dash';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import MassRolloutsImportForm from './MassRolloutsImportForm';


const MassRolloutsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [showImport, setShowImport] = useState("");

    return useMemo(() => (


        <Panel
            heading={"Mass Rollout Details"}
        >

            {showImport &&
                <Modal
                    show={true}
                    onClose={setShowImport.bind(null, "")}
                    width={620}
                >

                    <MassRolloutsImportForm
                        form={form}
                        type={showImport}
                        handleClose={setShowImport.bind(null, "")}
                    />

                </Modal>
            }

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            name={"delivery_date"}
                            required={true}
                            label={"Delivery Date"}
                            defaultValue={form.defaults.delivery_date || ""}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            name={"engagement_plan_id"}
                            required={true}
                            label={"Engagement Plan"}
                            model={'EngagementPlans'}
                            preload={true}
                            where={{ status: "active" }}
                            defaultValue={form.defaults.engagement_plan_id}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='additional_note'
                            required={false}
                            defaultValue={form.defaults.additional_note}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Seperator
                            heading={<>
                                Terminals
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "terminals")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'terminal_ids'}
                            required={false}
                            addButtonLabel={"Add Terminal"}
                        >

                            {(form.values.terminal_ids || []).map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`terminal_ids[${n}]`}
                                        required={true}
                                        model={'Terminals'}
                                        preload={true}
                                        where={{ status: "in_stock" }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Seperator
                            heading={<>
                                Sim Cards
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "sim")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'sim'}
                            addButtonLabel={"Add Sim Card"}
                        >

                            {Array.isArray(form.values.sim) && form.values.sim.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldRelationship
                                        form={form}
                                        name={`sim[${n}]`}
                                        required={true}
                                        model={'Inventories'}
                                        preload={true}
                                        where={{ status: "in_stock", type: "sim" }}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <Seperator
                            heading={<>
                                Batteries
                                <WhiteSpace />
                                <Button
                                    type={"light"}
                                    label={"Import"}
                                    size={"small"}
                                    onClick={setShowImport.bind(null, "batteries")}
                                />
                            </>}
                            bottom={"small"}
                        />

                        <FieldList
                            form={form}
                            name={'batteries'}
                            addButtonLabel={"Add Batteries SN"}
                        >

                            {Array.isArray(form.values.batteries) && form.values.batteries.map((row, n) => (

                                <div key={form.key + "-" + n}>

                                    <FieldText
                                        form={form}
                                        name={`batteries[${n}]`}
                                        required={true}
                                        defaultValue={row}
                                    />

                                </div>

                            ))}

                        </FieldList>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>

    ), [showImport, props]);

}

export default MassRolloutsCreateEditForm;
