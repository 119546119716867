import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import UsersSettings from '@dex/bubl-dash/src/lib/views/Users/UsersSettings';
import React, { useMemo, useState } from 'react'

const ProviderTechniciansSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = UsersSettings.indexFetchParams(routeName, params, users);

        filters.where.providers_ids = { inq: [data.id] };
        filters.where.roles = { inq: ['technician'] };

        return filters;

    }

    const indexColumns = (routeName, params, users) => {

        params.role = "technician";

        let columns = UsersSettings.indexColumns(routeName, params, users);

        return columns;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Technicians"}
                hideTrashSwitch={true}
                getFetch={UsersSettings.indexFetch}
                getColumns={indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                getQuickSearch={UsersSettings.indexQuickSearch}
                getSearchFields={UsersSettings.indexSearchFields}
            />

        </>

    ), [data, tableState]);
}

export default ProviderTechniciansSingleView