import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MassRolloutStages from '../Stages/MassRolloutStages';
import { PanelTabs, Space } from '@dex/bubl-dash';
import MassRolloutsTerminal from './MassRolloutsTerminal';
import MassRolloutsBatteries from './MassRolloutsBatteries';
import MassRolloutsSimcards from './MassRolloutsSimcards';

const MassRolloutsSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const [tab, setTab] = useState("rollout");

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Mass Rollout Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Engagement Plan",
                                key: "engagement_plan",
                                format: "modelLink",
                            },
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "modelLink",
                            },

                        ]}
                        columnB={[
                            {
                                label: "No. Of Terminals",
                                key: "serials.length",
                            },
                            {
                                label: "No. Of Battery Pack",
                                key: "batteries.length",
                            },
                            {
                                label: "No. Of Sim Cards",
                                key: "sim.length",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Delivery Date",
                                key: "delivery_date",
                                format: "dateDay"
                            },
                            {
                                label: "Additional Notes",
                                key: "additional_note",
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col col={{ xs: 24 }}>

                <PanelTabs
                    activeTab={tab}
                    setTab={setTab}
                    tabs={[
                        { name: "rollout", label: "Rollout Process" },
                        { name: "terminal", label: "Terminals" },
                        { name: "battery", label: "Battery Packs" },
                        { name: "simcard", label: "Sim Cards" },
                    ]}
                />

                <Space />

                <div
                    key={hash}
                >

                    {tab === "rollout" &&

                        <MassRolloutStages
                            data={data}
                        />
                    }

                    {tab === "terminal" &&

                        <MassRolloutsTerminal
                            data={data}
                        />
                    }

                    {tab === "battery" &&

                        <MassRolloutsBatteries
                            data={data}
                        />

                    }

                    {tab === "simcard" &&

                        <MassRolloutsSimcards
                            data={data}
                        />

                    }

                </div>

            </Col>

        </Row>

    ), [data, tab]);

}

export default MassRolloutsSingleView;
