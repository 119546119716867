import React, { useCallback, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { ButtonGroup, Col, FieldRadio, FieldRelationship, Line, Modal, Row, useForm } from '@dex/bubl-dash';
import MerchantsApi from '../../Merchants/MerchantsApi';
import AcquirersApi from '../../Acquirers/AcquirerApi';
import LeasingApi from '../../Leasing/LeasingApi';
import ProvidersApi from '../../Providers/ProvidersApi';
import AgenciesApi from '../../Agencies/AgenciesApi';

const ProformaInvoiceAutofill: React.FC<any> = (props: any) => {

    const { form, onClose } = props;

    const filters = useForm({});

    const [loading, setLoading] = useState(false);

    const merchant = MerchantsApi.getOne(":id");
    const acquirer = AcquirersApi.getOne(":id");
    const leasing = LeasingApi.getOne(":id");
    const agency = AgenciesApi.getOne(":id");
    const provider = ProvidersApi.getOne(":id");

    const handleFill = useCallback((values) => {

        let request: any = null;
        let id: any = null;

        if (values.from === "acqurier") { request = acquirer; id = values.acquirer_id; }
        if (values.from === "merchant") { request = merchant; id = values.merchant_id; }
        if (values.from === "leasing") { request = leasing; id = values.leasing_id; }
        if (values.from === "provider") { request = provider; id = values.provider_id; }
        if (values.from === "agency") { request = agency; id = values.agency_id; }

        if (!request) return;
        if (!id) return;

        setLoading(true);

        request.run({
            url: request.options.url.replace(":id", id),
            onEnd: () => {
                setLoading(false)
            },
            onComplete: (response) => {

                console.log(response);

                form.handleChange({ name: "company_name", value: response.billing_name || response.company_name, reset: true, silent: true });
                form.handleChange({ name: "billing_address", value: response.billing_address, reset: true, silent: true });
                form.handleChange({ name: "email", value: response.email, reset: true, silent: true });
                form.handleChange({ name: "phone", value: response.phone, reset: true, silent: true });
                form.handleChange({ name: "person", value: response.person, reset: true, silent: true });

                if (response.modelName === "Agencies") {
                    form.handleChange({ name: "email", value: response.person.email, reset: true, silent: true });
                    form.handleChange({ name: "phone", value: response.person.phone, reset: true, silent: true });
                    form.handleChange({ name: "person", value: response.person.full_name, reset: true, silent: true });
                }

                form.handleChange({ name: values.from + "_id", value: response.id, reset: true, silent: true });

                onClose();

            }
        });

    }, [form.hash]);

    return (

        <Modal
            show={true}
            onClose={onClose}
            width={620}
        >

            <Panel
                heading={"Autofill Company Details"}
                actions={(
                    <ButtonGroup>
                        <Button
                            label={"Cancel"}
                            onClick={onClose}
                            type={'faded'}
                            size={'small'}
                        />
                    </ButtonGroup>
                )}
            >

                <Form
                    form={filters}
                    onSubmit={handleFill}
                    loading={loading}
                >

                    <Row gutter={8} edge>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                button
                                form={filters}
                                name={"from"}
                                required
                                defaultValue={filters.values.from}
                                label={"Autofill From"}
                                options={[
                                    { label: "Acqurier", value: "acqurier" },
                                    { label: "Leasing", value: "leasing" },
                                    { label: "Merchant", value: "merchant" },
                                    { label: "Service Provider", value: "provider" },
                                    { label: "Agency", value: "agency" },
                                ]}
                            />

                        </Col>

                        {filters.values.from === "acqurier" &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={filters}
                                    label={"Acquirer"}
                                    name='acquirer_id'
                                    required
                                    preload
                                    model={'Acquirers'}
                                    defaultValue={form.defaults.acquirer_id}
                                />


                            </Col>
                        }

                        {filters.values.from === "merchant" &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={filters}
                                    label={"Merchant"}
                                    name={"merchant_id"}
                                    required
                                    preload
                                    defaultValue={filters.values.merchant_id}
                                    model={'Merchants'}
                                />

                            </Col>
                        }

                        {filters.values.from === "leasing" &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={filters}
                                    label={"Leasing"}
                                    name={"leasing_id"}
                                    required
                                    preload
                                    defaultValue={filters.values.leasing_id}
                                    model={'Leasing'}
                                />

                            </Col>
                        }

                        {filters.values.from === "provider" &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={filters}
                                    label={"Provider"}
                                    name={"provider_id"}
                                    required
                                    preload
                                    defaultValue={filters.values.provider_id}
                                    model={'Providers'}
                                />

                            </Col>
                        }

                        {filters.values.from === "agency" &&
                            <Col col={{ xs: 24 }}>

                                <FieldRelationship
                                    form={filters}
                                    label={"Agency"}
                                    name={"agency_id"}
                                    required
                                    preload
                                    defaultValue={filters.values.agency_id}
                                    model={'Agencies'}
                                />

                            </Col>
                        }


                    </Row>

                    {(filters.values.acquirer_id || filters.values.merchant_id || filters.values.leasing_id || filters.values.provider_id || filters.values.agency_id) &&
                        <>
                            <Line />

                            <Button
                                label={"Autofill"}
                                type={"primary"}
                                block
                                onClick={filters.triggerSubmit}
                                loading={loading}
                            />
                        </>
                    }

                </Form>

            </Panel>

        </Modal >

    );

}

export default ProformaInvoiceAutofill;
