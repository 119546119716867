import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { ModelLink, Space } from '@dex/bubl-dash';
import DepositEntriesStages from '../Stages/DepositEntriesStages';

const DepositEntriesSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Deposit Entry Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Merchant ID",
                                key: "merchant_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Merchants" />
                                )
                            },

                            {
                                label: "Company Name",
                                key: "company_name",
                            }

                        ]}
                        columnB={[
                            {
                                label: "Invoice ID",
                                key: "invoice_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="Invoices" />
                                )
                            },
                            {
                                label: "Bank Name",
                                key: "bank_account_id",
                                render: (value, row) => (
                                    <ModelLink data={{ id: value }} modelName="BankAccounts" />
                                )
                            },
                            {
                                label: "Deposit",
                                key: "deposit",
                                format: "number",
                                prefix: "RM "
                            }
                        ]}
                        columnC={[
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            }
                        ]}
                    />

                </Panel>

                <Space />

                <Col
                    col={{ xs: 24 }}
                >

                    <DepositEntriesStages
                        key={hash}
                        data={data}
                    />

                </Col>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data, hash]);

}

export default DepositEntriesSingleView;
