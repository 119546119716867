import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { FieldRadio, FieldUpload, Line, MetaTable, Space, useApp } from '@dex/bubl-dash';
import MerchantsMidTidForm from '../../Merchants/Components/MerchantsMidTidForm';

const InstallationStageTerminalTesting: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_terminal_testing");


    const checklist = [
        { value: "terminal", label: "Terminal" },
        { value: "software", label: "Download Software" },
        { value: "pma_sticker", label: "PMA Sticker" },
        { value: "paper_roll", label: "Paper Roll" },
        { value: "power_cord", label: "Power Cord & Adapter" },
        { value: "phone_line_spliter", label: "Phone Line & Spliter" },
        { value: "user_guide", label: "User Guide" },
        { value: "verify_transaction_slip", label: "Verify Transaction Slip" },
        { value: "mid", label: `Check MID : ${data.merchant.mid}` },
        { value: "tid", label: `Check TID : ${data.merchant.tid}` },
        // { value: "sim_card_no", label: `SIM Card No : ${data.sim_card_no}` },
    ];

    if (data.serial) checklist.push({ value: "serial", label: `Serial No. : ${data.serial}` });
    if (data.sim_card_serial) checklist.push({ value: "sim_card_no", label: `SIM Card No. : ${data.sim_card_serial}` });
    if (data.swivel_serial) checklist.push({ value: "swivel_serial", label: `SWIVEL Serial No. : ${data.swivel_serial}` });
    if (data.router_serial) checklist.push({ value: "router_serial", label: `Router Serial No. : ${data.router_serial}` });
    if (data.docking_serial) checklist.push({ value: "docking_serial", label: `Docking Serial No. : ${data.docking_serial}` });

    const validateValues = useCallback((values) => {

        if (!values.quality_control_checklist || checklist.length !== values.quality_control_checklist.length)
            throw new Error("Please complete all items in the checklist.");

    }, []);

    const handleGenerateParamsFile = useCallback((template_id) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/installations/${data.id}/params/${template_id}`);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            vadlidate={validateValues}
            name={"terminal_testing"}
            heading={"Terminal Testing"}
            editCaps={["stage_terminal_testingInstallations"]}
            editable={false}
            showForm={data.terminal_testing !== "yes"}
            showMeta={data.terminal_testing === "yes"}
            passed={data.terminal_testing === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    key: "terminal_testing",
                    label: "Test Transaction Passed?",
                    format: "titleCase"
                },

            ]}
            columnB={[{
                label: "Params File",
                key: "params_file",
                format: "file",
            }]
            }
            fields={
                <>
                    <Row gutter={"auto"} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldRadio
                                        form={form}
                                        name={"terminal_testing"}
                                        label={"Test Transaction Passed?"}
                                        defaultValue={form.defaults.terminal_testing}
                                        required
                                        options={[
                                            { label: "Yes", value: "yes" },
                                            { label: "No", value: "no" }
                                        ]}
                                    />

                                </Col>

                                {form.values.terminal_testing === "yes" && (

                                    <>

                                        <Col col={{ xs: 24 }}>

                                            <FieldUpload
                                                form={form}
                                                name={"params_file"}
                                                required={true}
                                                label={"Params File"}
                                                defaultValue={form.defaults.params_file}
                                                folder={'params-uploads'}
                                            />

                                        </Col>

                                    </>

                                )}

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={false} />

                            <MetaTable
                                data={data}
                                columnSize={{ xs: 8 }}
                                columnA={[
                                    {
                                        label: "Standard Applications",
                                        key: "merchant.standard_applications",
                                    }
                                ]}
                                columnB={[
                                    {
                                        label: "Optional Applications",
                                        key: "merchant.optional_applications",
                                    }
                                ]}
                                columnC={[
                                    {
                                        label: "QR Applications",
                                        key: "merchant.qr_applications",
                                    }
                                ]}
                            />

                            <Space height={"small"} />

                            <MerchantsMidTidForm
                                data={data}
                                form={form}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default InstallationStageTerminalTesting;
