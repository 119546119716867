import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const TerminalLink: React.FC<TerminalLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            {(data && data.id) ?
                <ModelLink data={data} primaryKey={"serial"} />
                :
                <>-</>
            }
        </>

    )

}

interface TerminalLinkProps {
    data: any,
    [key: string]: any,
}

export default TerminalLink;