import InvoicesCreate from "./InvoicesCreate";
import InvoicesIndex from "./InvoicesIndex";
import InvoicesSettings from "./InvoicesSettings";
import InvoicesSingle from "./InvoicesSingle";


export default function (app) {

    const settings = InvoicesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 10,
        items: [
            {
                label: "All Invoices",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    InvoicesIndex(app, settings);

    InvoicesCreate(app, settings);

    InvoicesSingle(app, settings);

}