import React, { useMemo } from 'react';
import ReplacementStageAssignReplaceTerminal from './ReplacementStageAssignReplaceTerminal';
import ReplacementStagesDelivery from './ReplacementStageDelivery';
import ReplacementStageReviewReplacementRequest from './ReplacementStageReviewReplacementRequest';

const ReplacementStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ReplacementStageReviewReplacementRequest
                data={data}
            />

            {(data.review_replacement_request === "yes" && data.request_status === "approved") && (

                <ReplacementStageAssignReplaceTerminal
                    data={data}
                />

            )}

            {data.assign_terminal === "yes" && (

                <ReplacementStagesDelivery
                    data={data}
                />

            )}


        </>

    ), [props]);

}

export default ReplacementStages;
