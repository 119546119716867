import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { FieldNumber, FieldRelationship, FieldSelect, FieldTextArea, Heading, Seperator, Space, useApp } from '@dex/bubl-dash';


const EngagementPlansCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    const [tab, setTab] = useState('merchant');

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    return useMemo(() => (

        <Panel
            heading={"Engagement Plan Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Business Model"}
                                    name='business_model'
                                    required={true}
                                    defaultValue={form.defaults.business_model}
                                    options={[
                                        { label: app.intl("engagement_plans.business_model.leasing"), value: "leasing" },
                                        { label: app.intl("engagement_plans.business_model.esa"), value: "esa" },
                                        { label: app.intl("engagement_plans.business_model.rent-to-own"), value: "rent-to-own" },
                                        { label: app.intl("engagement_plans.business_model.rental"), value: "rental" },
                                        { label: app.intl("engagement_plans.business_model.esa-rent-to-own"), value: "esa-rent-to-own" },
                                        { label: app.intl("engagement_plans.business_model.esa-rental"), value: "esa-rental" },
                                        { label: app.intl("engagement_plans.business_model.outright-purchase"), value: "outright-purchase" },
                                        { label: app.intl("engagement_plans.business_model.maintenance"), value: "maintenance" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldText
                                    form={form}
                                    label={"Plan Name"}
                                    name='plan_name'
                                    required={true}
                                    defaultValue={form.defaults.plan_name}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 24 }}>

                                <FieldText
                                    form={form}
                                    label={"Plan Name on Invoice"}
                                    name='billing_name'
                                    required={false}
                                    defaultValue={form.defaults.billing_name}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Acquirer"}
                                    name='acquirer_id'
                                    required={false}
                                    model={'Acquirers'}
                                    preload={true}
                                    defaultValue={form.defaults.acquirer_id}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Device Type"}
                                    name='device_type'
                                    required={false}
                                    defaultValue={form.defaults.device_type}
                                    options={[
                                        { value: "desktop", label: app.intl("terminals.type.desktop") },
                                        { value: "wireless", label: app.intl("terminals.type.wireless") },
                                        { value: "mpos", label: app.intl("terminals.type.mpos") },
                                        { value: "android", label: app.intl("terminals.type.android") }
                                    ]}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={false}
                            defaultValue={form.defaults.status}
                            options={[
                                { label: "Active", value: "active" },
                                { label: "Disabled", value: "disabled" },
                            ]}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Invoice To"}
                    top={"auto"}
                    bottom={"auto"}
                    tabs={[
                        { label: "Merchant", name: "merchant" },
                        { label: "Leasing / Rental", name: "leasing" },
                        { label: "Acquirer", name: "acquirer" },
                    ]}
                    activeTab={tab}
                    setTab={setTab}
                />

                <div className={tab === "merchant" ? "" : "hidden"}>

                    <Heading space={false}>Initial Rollout</Heading>
                    <Space height={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Processing Fee"}
                                        name='charge_merchant_processing_fee'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_processing_fee}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Refundable Deposit"}
                                        name='charge_merchant_deposit'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_deposit}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Selling Price"}
                                        name='charge_merchant_selling_price'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_selling_price}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Maintenance Fee"}
                                        name='charge_merchant_maintenance_fee'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_maintenance_fee}
                                        prefix={"RM "}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Agent's Commission - Headquarters"}
                                        name='charge_merchant_agent_commission_headquarters'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_agent_commission_headquarters}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Agent's Commission - Branch"}
                                        name='charge_merchant_agent_commission_branch'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_agent_commission_branch}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Agent's Commission - Additional"}
                                        name='charge_merchant_agent_commission_additional'
                                        required={false}
                                        defaultValue={form.defaults.charge_merchant_agent_commission_additional}
                                        prefix={"RM "}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    <Line />
                    <Heading space={false}>Annual Maintenance</Heading>
                    <Space height={"small"} />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Maintenance Fee"}
                                        name='charge_annual_maintenance_fee'
                                        required={false}
                                        defaultValue={form.defaults.charge_annual_maintenance_fee}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Refundable Deposit"}
                                        name='charge_annual_deposit'
                                        required={false}
                                        defaultValue={form.defaults.charge_annual_deposit}
                                        prefix={"RM "}
                                        allowNegative={true}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Agent's Commission"}
                                name='charge_annual_agent_commission'
                                required={false}
                                defaultValue={form.defaults.charge_annual_agent_commission}
                                prefix={"RM "}
                            />

                        </Col>

                    </Row>

                </div>

                <div className={tab === "leasing" ? "" : "hidden"}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Selling Price"}
                                        name='charge_leasing_selling_price'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_selling_price}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Rental Duration (Months)"}
                                        name='charge_leasing_rental_duration'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_rental_duration}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Rental (Monthly)"}
                                        name='charge_leasing_rental'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_rental}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Service Fee (Monthly)"}
                                        name='charge_leasing_service'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_service}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"SAAS Fee (Monthly)"}
                                        name='charge_leasing_saas'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_saas}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"5MB SIM Fee (Monthly)"}
                                        name='charge_leasing_sim'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_sim}
                                        prefix={"RM "}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"MYEVO SIM Fee (Monthly)"}
                                        name='charge_leasing_myevo_sim'
                                        required={false}
                                        defaultValue={form.defaults.charge_leasing_myevo_sim}
                                        prefix={"RM "}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </div>

                <div className={tab === "acquirer" ? "w-760" : "hidden"}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Terminal Installed At Headquarters"}
                                name='charge_acquirer_headquarters'
                                required={false}
                                defaultValue={form.defaults.charge_acquirer_headquarters}
                                prefix={"RM "}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Terminal Installed At Branch Office"}
                                name='charge_acquirer_branch'
                                required={false}
                                defaultValue={form.defaults.charge_acquirer_branch}
                                prefix={"RM "}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Terminal Installed As Additional Unit"}
                                name='charge_acquirer_additional'
                                required={false}
                                defaultValue={form.defaults.charge_acquirer_additional}
                                prefix={"RM "}
                            />

                        </Col>

                    </Row>

                </div>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form >

        </Panel >

    ), [props, tab]);

}

export default EngagementPlansCreateEditForm;
