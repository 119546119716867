import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const RouterLink: React.FC<RouterLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            {(data && data.id) ?
                <ModelLink data={data} primaryKey={"router_id"} />
                :
                <>-</>
            }
        </>

    )

}

interface RouterLinkProps {
    data: any,
    [key: string]: any,
}

export default RouterLink;