import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const SimCardLink: React.FC<SimCardLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            {(data && data.id) ?
                <ModelLink data={data} primaryKey={"sim_card_id"} />
                :
                <>-</>
            }
        </>

    )

}

interface SimCardLinkProps {
    data: any,
    [key: string]: any,
}

export default SimCardLink;