import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { DataTableCell, FieldDate, FieldHidden, FieldNumber, FieldRadio, FieldRelationship, FieldSelect, FieldTable, FieldTextArea, FieldUpload, InputTable, InputTableCell, InputTableHead, InputTableHeadCell, InputTableHeadRow, InputTableRow, useApp, useMountEffect } from '@dex/bubl-dash';
import { dateDay, formatNum, get, loop } from '@dex/bubl-helpers';
import moment from 'moment';
import { validateDatePassed } from '../../../functions/sharedValidation';
import StockApi from '../StockApi';

const StockTransferForm: React.FC<any> = (props: any) => {

    const app = useApp();

    const { form, process, handleSubmit } = props;

    const transfer = StockApi.prepareTransfer();

    useEffect(() => {

        transfer.run({
            onComplete: (data) => {

                let products: any = [];

                loop(data.products, (product) => {

                    products.push({
                        stock_id: product.id,
                        ...product,
                        quantity: undefined,
                    });

                });

                form.handleChange({ reset: true, silent: true, name: "products", value: products });

            },
        });

        return () => {
            transfer.cancel();
        };

    }, []);

    const departments = get(transfer, "data.departments", []);

    // eslint-disable-next-line
    let data = props.data || {};

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, false);

    }, []);

    useMountEffect(() => {

        if (form.values.date) return;

        form.handleChange({ name: "date", value: dateDay(moment()), reset: true, silent: true });

    });

    return useMemo(() => (

        <Panel
            heading={"Transfer General Stock"}
        >

            {transfer.data && transfer.data.departments &&
                <Form
                    form={form}
                    onSubmit={handleSubmit}
                    loading={process.loading}
                >

                    <Row gutter={8} edge={true}>
                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                button
                                form={form}
                                label={"Action"}
                                name='action'
                                required={true}
                                defaultValue={form.defaults.action}
                                options={[
                                    { label: "Add", value: "add" },
                                    { label: "Use", value: "use" },
                                    { label: "Transfer", value: "transfer" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldDate
                                key={form.defaults.date}
                                form={form}
                                label={"Date"}
                                name='date'
                                required={true}
                                defaultValue={form.defaults.date}
                                disabledDate={isDateDisabled}
                            />

                        </Col>

                        {form.values.action === "transfer" &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldSelect
                                    form={form}
                                    required={true}
                                    name={"from_department"}
                                    label={"From Department"}
                                    defaultValue={form.defaults.from_department}
                                    options={departments}
                                />

                            </Col>
                        }

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                required={true}
                                name={"department"}
                                label={form.values.action === "transfer" ? "To Department" : "Department"}
                                defaultValue={form.defaults.department}
                                options={departments}
                            />

                        </Col>

                        {form.values.action === "add" &&
                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldUpload
                                    form={form}
                                    label={"Attachments"}
                                    name='attachments'
                                    defaultValue={form.defaults.attachments}
                                    folder='admin-media'
                                />

                            </Col>
                        }

                        {(form.values.action === "add" || form.values.action === "use") &&

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Notes"}
                                    name='notes'
                                    defaultValue={form.defaults.notes}
                                />

                            </Col>
                        }

                        {form.values.department && form.values.action &&
                            <Col col={{ xs: 24, md: 20 }}>

                                <InputTable
                                    head={
                                        <>
                                            <InputTableHeadRow>

                                                <InputTableHeadCell
                                                    label={"#"}
                                                />

                                                <InputTableHeadCell
                                                    label={"Stock Item"}
                                                />

                                                {form.values.from_department && form.values.action === "transfer" &&
                                                    <InputTableHeadCell
                                                        label={"In Stock"}
                                                        desc={form.values.from_department}
                                                    />
                                                }

                                                {form.values.department &&
                                                    <InputTableHeadCell
                                                        label={"In Stock"}
                                                        desc={form.values.department}
                                                    />
                                                }

                                                <InputTableHeadCell
                                                    label={"Quantity"}
                                                    desc={form.values.action === "add" ? "To Add" : (form.values.action === "use" ? "To Use" : "To Transfer")}
                                                />

                                            </InputTableHeadRow>
                                        </>
                                    }
                                >



                                    {(form.values.products || []).map((row, n) => (

                                        <InputTableRow key={form.key + "-" + n}>

                                            <DataTableCell style={{ width: "10px" }}>
                                                {n + 1}
                                            </DataTableCell>

                                            <DataTableCell style={{ width: "50%" }}>

                                                {row.name}

                                                <FieldHidden
                                                    form={form}
                                                    name={`products[${n}]stock_id`}
                                                    defaultValue={row.stock_id}
                                                />

                                            </DataTableCell>

                                            {form.values.from_department && form.values.action === "transfer" &&
                                                <DataTableCell style={{ width: "20%" }}>
                                                    {formatNum(row.departments[form.values.from_department] || 0)}
                                                </DataTableCell>
                                            }

                                            {form.values.department &&
                                                <DataTableCell style={{ width: "20%" }}>
                                                    {formatNum(row.departments[form.values.department] || 0)}
                                                </DataTableCell>
                                            }

                                            <InputTableCell style={{ width: "25%" }}>

                                                <FieldNumber
                                                    form={form}
                                                    name={`products[${n}]quantity`}
                                                    defaultValue={row.quantity}
                                                />

                                            </InputTableCell>

                                        </InputTableRow>

                                    ))}

                                </InputTable>


                            </Col>
                        }

                    </Row>

                    <Line />

                    <ButtonRow>
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                        <Button
                            label={"Reset"}
                            type={"faded"}
                            onClick={form.reset}
                        />
                    </ButtonRow>

                </Form>
            }

        </Panel>

    ), [props, transfer.hash]);

}

export default StockTransferForm;
