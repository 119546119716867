import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Stock';

const StockApi: baseApiReturn = baseApi(endpoint);

StockApi.prepareTransfer = (options?: options): response => {

    return apiGet({
        url: `${endpoint}/prepareTransfer`,
        ...options
    })

}

StockApi.getHistory = (id, department, options?: options): response => {

    return apiGet({
        url: `${endpoint}/${id}/getHistory/${department}`,
        ...options
    })

}

export default StockApi;