import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import AddonsSingleView from "./Components/AddonsSingleView";
import AddonsSingleEdit from "./Components/AddonsSingleEdit";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: AddonsSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: AddonsSingleEdit,
        },
    });

}