import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import MassRolloutsCreateEditForm from "./Components/MassRolloutsCreateEditForm";
import MassRolloutsApi from "./MassRolloutsApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: MassRolloutsCreateEditForm,
                getCreate: MassRolloutsApi.create,
                getClone: MassRolloutsApi.getOne,
                defaults: { terminal_ids: [] },
                successMessage: "Mass Rollout Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

}