import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import { FieldAutocomplete, FieldNumber, FieldRadio, useApp } from '@dex/bubl-dash';

const InventoriesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    return useMemo(() => (

        <Panel
            heading={"Inventory Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    {mode === "edit" &&

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldText
                                form={form}
                                label={"Serial Number"}
                                name='serial'
                                disabled
                                required={true}
                                placeholder={"Serial Number"}
                                defaultValue={form.defaults.serial}
                            />

                        </Col>

                    }

                    {mode === "create" &&

                        <>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Has Serial Number?"}
                                    name='has_serial'
                                    required
                                    defaultValue={form.defaults.has_serial}
                                    options={[
                                        { value: "yes", label: "Yes" },
                                        { value: "no", label: "No" },
                                    ]}
                                />

                            </Col>

                            {form.values.has_serial == "yes" ?
                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldTextArea
                                        form={form}
                                        label={"Serial Numbers"}
                                        name='serials'
                                        required={true}
                                        placeholder={"Serial Number (one per line)"}
                                        defaultValue={form.defaults.serials}
                                    />

                                </Col>
                                :
                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Quantity"}
                                        name='quantity'
                                        required
                                        defaultValue={form.defaults.quantity}
                                    />

                                </Col>
                            }



                        </>

                    }

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Type"}
                            name='type'
                            required
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "swivel", label: app.intl("inventories.type.swivel") },
                                { value: "router", label: app.intl("inventories.type.router") },
                                { value: "sim", label: app.intl("inventories.type.sim") },
                                { value: "docking", label: app.intl("inventories.type.docking") },
                            ]}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Delivery Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldDate
                            form={form}
                            label={"Delivery Date"}
                            name='delivery_date'
                            required={false}
                            defaultValue={form.defaults.delivery_date || ""}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Delivery Number"}
                            name='delivery_number'
                            required={false}
                            defaultValue={form.defaults.delivery_number}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default InventoriesCreateEditForm;
