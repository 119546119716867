import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Button, DataTable, DataTableCell, InputTableRow, WhiteSpace } from '@dex/bubl-dash';
import { formatNum } from '@dex/bubl-helpers';
import StockSingleDepartmentHistory from './StockSingleDepartmentHistory';

const StockSingleView: React.FC<any> = (props: any) => {

    const { data, hash } = props;

    const [history, setHistory] = useState("");

    const columns = [
        {
            label: 'Department',
            key: 'department',
        },
        {
            label: <WhiteSpace />,
            key: 'action',
        },
        {
            label: 'Quantity In Stock',
            key: 'quantity',
            alignEnd: true
        },
    ];

    const departments = Object.entries(data?.departments ?? {}).map(([departmentName, quantity]) => ({
        name: departmentName,
        quantity: quantity === 0 ? '0' : quantity,
    }));

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                {history &&
                    <StockSingleDepartmentHistory
                        data={data}
                        department={history}
                        onClose={setHistory.bind(null, "")}
                    />
                }

                <Panel
                    heading={"General Stock Details"}
                >


                    <DataTable
                        columns={columns}
                    >

                        <InputTableRow className={data.quantity < data.low_stock_limit ? 'table-bg-red' : 'table-bg-green'}>

                            <DataTableCell width={"50%"}>
                                <strong>Total</strong>
                            </DataTableCell>

                            <DataTableCell>
                                <Button
                                    label={"View History"}
                                    size='small'
                                    type='faded'
                                    onClick={setHistory.bind(null, "Total")}
                                />
                            </DataTableCell>


                            <DataTableCell alignEnd>
                                <strong>
                                    {/* @ts-ignore */}
                                    {formatNum(data.quantity)}
                                </strong>
                            </DataTableCell>

                        </InputTableRow>

                        {departments && departments.length > 0 && departments.map((department, index) => (
                            <InputTableRow key={index}>

                                <DataTableCell width={"50%"}>
                                    {department.name}
                                </DataTableCell>

                                <DataTableCell>
                                    <Button
                                        label={"View History"}
                                        size='small'
                                        type='faded'
                                        onClick={setHistory.bind(null, department.name)}
                                    />
                                </DataTableCell>

                                <DataTableCell alignEnd>
                                    {/* @ts-ignore */}
                                    {formatNum(department.quantity)}
                                </DataTableCell>

                            </InputTableRow>
                        ))}

                    </DataTable>

                </Panel>

            </Col>


            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data, hash, history]);

}

export default StockSingleView;
