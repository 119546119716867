import React, { useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import PanelTabs from '@dex/bubl-dash/src/lib/components/PanelTabs/PanelTabs';
import AgencyAdvisorsSingleView from './AgencyAdvisorsSingleView';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { userHasCap, userHasRole } from '@dex/bubl-dash';



const AgentSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const pathDefaults = encodeURIComponent(JSON.stringify({ roles: ["advisor"], "agency_id": [data.id] }));
    const isStaff = userHasCap(['staff']);
    const isCompanyManager = userHasCap(['companyManager']);

    const [tab, setTab] = useState("service");

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Agency Details"}
                    actions={(
                        <>
                            {isStaff &&
                                <Button
                                    label="Add Advisor"
                                    size={"small"}
                                    path={"/users/create?defaults=" + pathDefaults}
                                />
                            }
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },

                            {
                                label: "Contact Person",
                                key: "person.full_name",
                            },
                            {
                                label: "Email",
                                key: "person.email",
                            },
                            {
                                label: "Phone",
                                key: "person.phone",
                            },
                            {
                                label: "IC Number",
                                key: "person.ic_no",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Agency Level",
                                key: "agency_level",
                            },
                            {
                                label: "Bank Name",
                                key: "bank.bank_name",
                            },
                            {
                                label: "Account Name",
                                key: "bank.account_name",
                            },
                            {
                                label: "Account Number",
                                key: "bank.account_number",
                            },

                        ]}
                        columnC={[
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            },
                            {
                                label: "Additional Notes",
                                key: "notes",
                                format: "nl2br"
                            },

                        ]}
                    />

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                </Panel>

                {(isCompanyManager || isStaff) &&
                    <>
                        <Space />

                        <AgencyAdvisorsSingleView
                            data={data}
                        />
                    </>
                }


            </Col>


        </Row>

    ), [data]);

}

export default AgentSingleView;
