import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MerchantsApi from '../MerchantsApi';
import Stage from '../../../elements/Stage/Stage';
import MerchantsMidTidForm from '../Components/MerchantsMidTidForm';

const MerchantsStageAcquirersApproval: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = MerchantsApi.update(data.id, "stage_merchant_approved");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"merchant_approved"}
            heading={"Acquirers Approval"}
            editCaps={["stage_merchant_approvedMerchants"]}

            editable={data.merchant_approved === "yes" && data.status === "new_merchant"}
            showForm={data.merchant_approved !== "yes" && data.status === "new_merchant"}
            showMeta={data.merchant_approved}
            showSave={form.changed}
            passed={data.merchant_approved === "yes"}
            columnA={[
                {
                    key: "merchant_approved",
                    label: "Merchant Approved",
                    format: "titleCase"
                }
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"merchant_approved"}
                                label={"Merchant Account Approved"}
                                defaultValue={form.defaults.merchant_approved}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.merchant_approved === "yes" && (

                            <Col col={{ xs: 24 }}>

                                <MerchantsMidTidForm
                                    form={form}
                                    data={data}
                                />

                            </Col>

                        )}

                    </Row>

                </>

            }
        />

    ), [props, form, process.loading]);

}

export default MerchantsStageAcquirersApproval;
