import React, { useMemo } from 'react';
import { useMountEffect } from '@dex/bubl-dash';
import ProformaInvoicesStagesPayment from '../../ProformaInvoices/Stages/ProformaInvoicesStagesPayment';
import ProformaInvoicesApi from '../../ProformaInvoices/ProformaInvoicesApi';

const MerchantsStageDepositPayment: React.FC<any> = (props: any) => {

    const { data } = props;

    const invoice = ProformaInvoicesApi.getOne(data.proforma_invoice_id);

    useMountEffect(() => {

        invoice.run();

    });

    return useMemo(() => (

        <>

            {invoice.data &&
                <ProformaInvoicesStagesPayment
                    data={invoice.data}
                />
            }

        </>

    ), [invoice.loading]);

}

export default MerchantsStageDepositPayment;
