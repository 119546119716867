import ReplacementsIndex from './ReplacementsIndex';
import ReplacementsCreate from './ReplacementsCreate';
import ReplacementsSettings from './ReplacementsSettings';
import ReplacementsSingle from './ReplacementsSingle';

export default function (app) {

    const settings = ReplacementsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     addPath: settings.path + "/create",
    //     addCaps: settings.createCaps,
    //     priority: 14,
    //     items: [
    //         {
    //             label: "All",
    //             path: settings.path,
    //             caps: settings.indexCaps
    //         },
    //     ],
    // });

    ReplacementsIndex(app, settings);

    ReplacementsCreate(app, settings);

    ReplacementsSingle(app, settings);

}