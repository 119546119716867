import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

import ServicesSingleCancelForm from './ServicesSingleCancelForm';
import ServicesSingleReopenForm from './ServicesSingleReopenForm';

const ServicesSingleCancel: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                {data.status === "cancelled" &&
                    <ServicesSingleReopenForm
                        data={data}
                    />
                }

                {(data.status !== "cancelled" && data.status !== "complete" && data.status !== "verified") &&
                    <ServicesSingleCancelForm
                        data={data}
                    />
                }

            </Col>

        </Row>

    ), [data]);

}

export default ServicesSingleCancel;
