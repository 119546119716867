import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { Alert, FieldRadio, FieldRelationship, FieldSelect, FieldText, FieldTextArea, Space } from '@dex/bubl-dash';
import { titleCase } from '@dex/bubl-helpers';

const InstallationStageTerminalSelected: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_terminal_selected");

    let in_stock = ['store', 'store_used', 'floating_unit', 'pma_floating_unit'];
    let priority = "New Stock / Used Stock / PMA Floating Stock";

    const where: any = {
        type: data.device_type,
        status: 'in_stock',
        in_stock: { inq: in_stock }
    };

    if (data.merchant?.process === 'bir') {
        in_stock = ['store', 'store_used', 'acquirer_floating_unit'];
        where.in_stock = { inq: in_stock };
        priority = "Acquirer Floating Stock / New Stock / Used Stock";
    } else if (data.merchant?.process === 'mtp') {
        priority = "Merchant Transfered Stock";
    } else if (data.merchant?.business_model === 'maintenance') {
        delete (where.in_stock);
        where.status = { inq: ["deployed", "delivered"] };
        where.acquirer_id = data.merchant?.acquirer_id;
        priority = "Mass Rollout Acquirer Stock";
    }

    const editable = data.terminal_selected === "yes" && data.status === "testing";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"terminal_selected"}
            heading={"Assign Asset"}
            editCaps={["stage_terminal_selectedInstallations"]}
            editable={editable}
            showForm={!data.terminal_selected || data.terminal_selected === "no"}
            showMeta={data.terminal_selected === "yes"}
            passed={data.terminal_selected === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    key: "serial",
                    label: "Serial No.",
                },
                {
                    key: "adapter_type",
                    label: "Power Adapter Type",
                    format: "intl",
                    prefix: "installation.adapter_type",
                    hideIfEmpty: true
                },
                {
                    key: "terminal_selected_notes",
                    label: "Additional Notes",
                },
            ]}
            columnB={[
                {
                    key: "sim_card_serial",
                    label: "SIM Card No.",
                    hideIfEmpty: true
                },
                {
                    key: "sim_data_plan",
                    label: "SIM Data Plan",
                    hideIfEmpty: true
                },
                {
                    key: "telco_network",
                    label: "TELCO Network",
                    format: "intl",
                    prefix: "service.telco_network",
                    hideIfEmpty: true
                },
            ]}
            columnC={[
                {
                    key: "swivel_serial",
                    label: "SWIVEL Serial No.",
                    hideIfEmpty: true
                },
                {
                    key: "router_serial",
                    label: "Router Serial No.",
                    hideIfEmpty: true
                },
                {
                    key: "docking_serial",
                    label: "Docking Serial No.",
                    hideIfEmpty: true
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Serial Number (" + titleCase(data.device_type) + ") | (" + priority + ")"}
                                        name='terminal_id'
                                        defaultValue={form.defaults.terminal_id}
                                        endpoint={'Terminals'}
                                        required={true}
                                        preload={true}
                                        where={where}
                                        format={["serial", "status", "in_stock"]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        button
                                        form={form}
                                        label={"Power Adapter Type"}
                                        name='adapter_type'
                                        defaultValue={form.defaults.adapter_type}
                                        required
                                        options={[
                                            { label: "Original", value: "original" },
                                            { label: "Third Party", value: "third_party" },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"SIM Card No."}
                                        name='sim_card_id'
                                        // required={true}
                                        defaultValue={form.defaults.sim_card_id}
                                        endpoint='Inventories'
                                        where={{ type: "sim", status: 'in_stock' }}
                                        preload={true}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"SIM Data Plan"}
                                        name='sim_data_plan'
                                        defaultValue={form.defaults.sim_data_plan}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldSelect
                                        form={form}
                                        name={"telco_network"}
                                        label={"TELCO Network"}
                                        required={form.values.sim_card_id ? true : false}
                                        defaultValue={form.defaults.telco_network}
                                        options={[
                                            { value: "maxis", label: "Maxis" },
                                            { value: "digi", label: "Digi" },
                                            { value: "digi-ultra", label: "Digi-Ultra" },
                                            { value: "celcom", label: "Celcom" },
                                            { value: "myevo", label: "MyEvo" },
                                            { value: "myevo-multisim", label: "MyEvo - Multi Sim" },
                                            { value: "tns", label: "TNS" },
                                            { value: "umobile", label: "Umobile" },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"SWIVEL Serial No."}
                                        name='swivel_id'
                                        required={false}
                                        defaultValue={form.defaults.swivel_id}
                                        endpoint={'Inventories'}
                                        where={{ type: "swivel", status: 'in_stock' }}
                                        preload={true}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Router Serial No."}
                                        name='router_id'
                                        required={false}
                                        defaultValue={form.defaults.router_id}
                                        endpoint={'Inventories'}
                                        where={{ type: "router", status: 'in_stock' }}
                                        preload={true}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Docking Serial No."}
                                        name='docking_id'
                                        required={false}
                                        defaultValue={form.defaults.docking_id}
                                        endpoint={'Inventories'}
                                        where={{ type: "docking", status: 'in_stock' }}
                                        preload={true}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldTextArea
                                form={form}
                                label={"Additional Notes"}
                                name='terminal_selected_notes'
                                required={false}
                                defaultValue={form.defaults.terminal_selected_notes}
                            />

                        </Col>

                    </Row>

                    {data.terminal_selected === "yes" &&
                        <>

                            {data.terminal_id && data.terminal_id != form.values.terminal_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Terminal Changed"}
                                        description={data.serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.sim_card_id && data.sim_card_id != form.values.sim_card_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"SIM Card Changed"}
                                        description={data.sim_card_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.swivel_id && data.swivel_id != form.values.swivel_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"SWIVEL Changed"}
                                        description={data.swivel_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }

                            {data.router_id && data.router_id != form.values.router_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Router Changed"}
                                        description={data.router_serial + " will be moved to bench repair"}
                                    />
                                </>
                            }


                            {data.docking_id && data.docking_id != form.values.docking_id &&
                                <>
                                    <Space height={8} />
                                    <Alert
                                        type={"warning"}
                                        message={"Docking Changed"}
                                        description={data.docking_serial + " will be moved to bench repair."}
                                    />
                                </>
                            }

                        </>
                    }
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default InstallationStageTerminalSelected;
