import MassRolloutsCreate from "./MassRolloutsCreate";
import MassRolloutsIndex from "./MassRolloutsIndex";
import MassRolloutsSettings from "./MassRolloutsSettings";
import MassRolloutsSingle from "./MassRolloutsSingle";


export default function (app) {

    const settings = MassRolloutsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 20,
        items: [
            {
                label: "All Mass Rollouts",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Replacements",
                path: "/replacements",
                caps: settings.indexCaps,
            },
        ],
    });

    MassRolloutsIndex(app, settings);

    MassRolloutsCreate(app, settings);

    MassRolloutsSingle(app, settings);

}