import moment from "moment";

const today = moment().endOf("day");
const twoMonths = moment().endOf("day").subtract(2, "months");

export const validateDatePassed = (date, isTechnician) => {

    if (today.isBefore(date)) return true;

    if (isTechnician && twoMonths.isAfter(date)) return true;

    return false;

};