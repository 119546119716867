import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { FieldAutocomplete, FieldCheckbox, FieldText, MetaTable, Space } from '@dex/bubl-dash';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';
import userReverseStage from '../../../functions/userReverseStage';

const InstallationStageTerminalPersonalization: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_terminal_personalization");

    const checklist = [
        { value: "terminal", label: "Terminal" },
        { value: "software", label: "Download Software" },
        { value: "pma_sticker", label: "PMA Sticker" },
        { value: "paper_roll", label: "Paper Roll" },
        { value: "power_cord", label: "Power Cord & Adapter" },
        { value: "phone_line_spliter", label: "Phone Line & Spliter" },
        { value: "user_guide", label: "User Guide" },
        { value: "verify_transaction_slip", label: "Verify Transaction Slip" },
        { value: "mid", label: `Check MID : ${data.merchant.mid}` },
        { value: "tid", label: `Check TID : ${data.merchant.tid}` },
    ];

    // { value: "sim_card_no", label: `SIM Card No : ${data.sim_card_no}` },
    if (data.serial) checklist.push({ value: "serial", label: `Serial No. : ${data.serial}` });
    if (data.sim_card_serial) checklist.push({ value: "sim_card_no", label: `SIM Card No. : ${data.sim_card_serial}` });
    if (data.swivel_serial) checklist.push({ value: "swivel_serial", label: `SWIVEL Serial No. : ${data.swivel_serial}` });
    if (data.router_serial) checklist.push({ value: "router_serial", label: `Router Serial No. : ${data.router_serial}` });
    if (data.docking_serial) checklist.push({ value: "docking_serial", label: `Docking Serial No. : ${data.docking_serial}` });

    const validateValues = useCallback((values) => {

        if (!values.terminal_personalization_checklist || checklist.length !== values.terminal_personalization_checklist.length)
            throw new Error("Please complete all items in the checklist.");

    }, [checklist]);

    const reverse = InstallationsApi.update(data.id, "stage_reverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            validate={validateValues}
            name={"terminal_personalization"}
            heading={"Terminal Personalization"}
            editCaps={["stage_terminal_personalizationInstallations"]}
            editable={false}
            showForm={data.terminal_personalization !== "yes"}
            showMeta={data.terminal_personalization === "yes"}
            passed={data.terminal_personalization === "yes"}
            showSave={form.changed}
            reverse={data.terminal_personalization === "yes" ? null : reverse}
            last_stage={"terminal_testing"}
            columnA={
                [
                    {
                        label: "Software Version",
                        key: "software_version",
                    },
                ]}
            columnB={
                [
                    {
                        label: "OS Version",
                        key: "os_version",
                    },
                ]}
            columnC={
                [
                    {
                        label: "Checklist",
                        key: "terminal_personalization_checklist",
                        render: () => "Passed"
                    },
                ]}
            fields={
                <>
                    <Row gutter={"auto"} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        name={"software_version"}
                                        required={false}
                                        label={"Software Version"}
                                        defaultValue={form.defaults.software_version}
                                        group='software_version'
                                        preload
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        name={"os_version"}
                                        required={false}
                                        label={"OS Version"}
                                        defaultValue={form.defaults.os_version}
                                        group='os_version'
                                        preload
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 24 }}>

                                    <FieldCheckbox
                                        form={form}
                                        name={"terminal_personalization_checklist"}
                                        label={"Checklist"}
                                        required={true}
                                        defaultValue={form.defaults.terminal_personalization_checklist}
                                        list
                                        options={checklist}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <MetaTable
                                data={data}
                                columnSize={{ xs: 8 }}
                                columnA={[

                                    {
                                        label: "Standard Applications",
                                        key: "merchant.standard_applications",
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Optional Applications",
                                        key: "merchant.optional_applications",
                                    },
                                ]}
                                columnC={[
                                ]}
                            />

                            <Space height={"small"} />

                            <MerchantsMidTidView
                                data={data.merchant}
                                form={form}
                                passed={data.tests}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default InstallationStageTerminalPersonalization;
