import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const AddonsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Addons Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Addon Name",
                                key: "addon_name",
                            },
                            {
                                label: "SKU",
                                key: "sku",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Selling Price",
                                key: "selling_price",
                                format: "number",
                                prefix: "RM "
                            },
                            {
                                label: "Agent's Commission",
                                key: "agents_commission",
                                format: "number",
                                prefix: "RM "
                            },

                        ]}
                        columnC={[
                            {
                                label: "Device Type",
                                key: "device_types",
                                format: "titleCase",
                            },
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default AddonsSingleView;
