import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ProviderTechniciansSingleView from './ProviderTechniciansSingleView';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { userHasCap, userHasRole } from '@dex/bubl-dash';



const ProvidersSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const pathDefaults = encodeURIComponent(JSON.stringify({ roles: ["technician"], "providers_ids": [data.id] }));

    const isStaff = userHasCap(['staff']);
    const isCompanyManager = userHasCap(['companyManager']);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Provider Details"}
                    actions={(
                        <>
                            {isStaff &&
                                <Button
                                    label="Add Technician"
                                    size={"small"}
                                    path={"/users/create?defaults=" + pathDefaults}
                                />
                            }
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },
                            {
                                label: "IC Number",
                                key: "ic_no",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Bank Name",
                                key: "bank.bank_name",
                            },
                            {
                                label: "Account Name",
                                key: "bank.account_name",
                            },
                            {
                                label: "Account Number",
                                key: "bank.account_number",
                            },

                        ]}
                        columnC={[
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            },
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },

                        ]}
                    />

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                </Panel>

                {(isCompanyManager || isStaff) &&
                    <>
                        <Space />

                        <ProviderTechniciansSingleView
                            data={data}
                        />
                    </>
                }

            </Col>

        </Row >

    ), [data]);

}

export default ProvidersSingleView;
