import { clone, loop, slug } from "@dex/bubl-helpers";

export const merchantApplications = (settings) => {

    const applications = { standard: [], optional: [], qr: [] };
    const defaults = { standard: [], optional: [], qr: [] };

    if (!settings || !settings.applications) return { applications, defaults };

    loop(settings.applications, (list, type) => {

        loop(list, (app, key) => {

            applications[type]?.push({ label: app.key, value: app.key });

            if (app.default) defaults[type].push(app.key);

        });

    });

    return { applications, defaults };

}

export const merchantMidTidList = (settings, merchant, format = "raw") => {

    const idList: any = [];

    const addTo = (item, to) => {

        item.label = item.key;
        item.key = slug(item.key);

        if (item.idList && item.idList.length > 0) {

            loop(item.idList, (key) => {

                if (key === "base") {

                    to.push(item);

                } else {

                    const subItem = { ...item };

                    subItem.label = subItem.label + " " + key;
                    subItem.key += key;

                    to.push(subItem);

                }

            });

        } else {

            to.push(item);

        }

    };

    const applications = clone(settings.applications || {});

    if (merchant === null) {

        merchant = {
            standard_applications: [],
            optional_applications: [],
            qr_applications: [],
        }

        loop(applications.standard, (app, key) => {
            if (app.hasMidTid) merchant.standard_applications.push(key);
        });

        loop(applications.optional, (app, key) => {
            if (app.hasMidTid) merchant.optional_applications.push(key);
        });

        loop(applications.qr, (app, key) => {
            merchant.qr_applications.push(key);
        });

    }

    loop(merchant.standard_applications || [], (key) => {

        const item = applications.standard[key] || {};

        if (item.hasMidTid) addTo(item, idList);

    });

    loop(merchant.optional_applications || [], (key) => {

        const item = applications.optional[key] || {};

        if (item.hasMidTid) addTo(item, idList);

    });

    if (merchant.qr_applications && merchant.qr_applications.length > 0) {

        addTo({ key: 'QR', label: 'QR', hasRemarks: true, hasMidTid: true }, idList);

    }

    return idList;

}

export const merchantMidTidValues = (idList, merchant) => {

    const values: any = {};

    values.mid = merchantMidPrefix(merchant.mid, merchant.mid_prefix, 15);
    values.tid = merchant.tid;
    values.mid_prefix = merchant.mid_prefix;
    values.amex_prefix = merchant.amex_prefix;
    values.wifi_password = merchant.wifi_password;
    values.qr_master_key = merchant.qr_master_key;
    values.config_notes = merchant.config_notes;

    values.list = [];

    loop(idList, (item) => {

        const label = item.label;
        const key = slug(item.key + "");

        let midtid: any = { mid: '', tid: '', min: '', max: '', remarks: '' };
        if (merchant.midtid && merchant.midtid[key]) midtid = clone(merchant.midtid[key])

        if (item.prefix === "primary") {

            midtid.mid = merchantMidPrefix(midtid.mid, merchant.mid_prefix, 15);

        } else if (item.prefix === "amex" || item.prefix === "ezpay") { //amex ezpay

            if (midtid.mid && midtid.mid.startsWith('920')) midtid.mid = midtid.mid.substring(3);

            midtid.mid = merchantMidPrefix(midtid.mid, merchant.amex_prefix, null);

        }

        if (item.usePrimary) {
            midtid.mid = values.mid;
            midtid.tid = values.tid;
        }

        values.list.push({
            key: key,
            label: label,
            mid: midtid.mid,
            tid: midtid.tid,
            min: midtid.min,
            max: midtid.max,
            remarks: midtid.remarks,
            test: item.testTransaction
        });

    });

    return values;

};

export const merchantMidPrefix = (mid: number | string, prefix: number | string, length: number | null = null) => {

    if (!mid) return;

    if (typeof mid === "number") mid = mid.toString();
    if (typeof prefix === "number") prefix = prefix.toString();

    if (!prefix) prefix = "";

    if (length) {

        length = length - mid.length;
        prefix = prefix.substr(0, length);

    }

    if (!prefix) return mid;

    if (mid.includes(prefix)) return mid;

    return prefix + mid;

}

export const formatTime = (time) => {
    if (!time && time !== 0) {
        return "";
    }

    if (time < 60) {
        return `${time} minute${time !== 1 ? 's' : ''}`;
    } else if (time < 1440) {
        const hours = Math.floor(time / 60);
        return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else if (time < 43200) { // 43200 time = 30 days
        const days = Math.floor(time / 1440);
        return `${days} day${days !== 1 ? 's' : ''}`;
    } else {
        const months = Math.floor(time / 43200);
        const remainingMinutes = time % 43200;
        const days = Math.floor(remainingMinutes / 1440);
        return `${months} month${months !== 1 ? 's' : ''}${days > 0 ? `, ${days} day${days !== 1 ? 's' : ''}` : ''}`;
    }
};