import ClaimsIndex from './ClaimsIndex';
import ClaimsCreate from './ClaimsCreate';
import ClaimsSettings from './ClaimsSettings';
import ClaimsSingle from './ClaimsSingle';

export default function (app) {

    const settings = ClaimsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 10,
        items: [
            {
                label: "All Claims",
                path: settings.path,
                caps: settings.indexCaps
            },
        ],
    });

    ClaimsIndex(app, settings);

    ClaimsCreate(app, settings);

    ClaimsSingle(app, settings);

}