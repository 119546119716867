import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MerchantsApi from '../MerchantsApi';
import Stage from '../../../elements/Stage/Stage';
import { FieldText } from '@dex/bubl-dash';

const MerchantsStageSubmitDocuments: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = MerchantsApi.update(data.id, "stage_documents_submitted");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"documents_submitted"}
            heading={"Submit Documents"}
            editCaps={["stage_documents_submittedMerchants"]}
            editable={data.documents_submitted === "yes" && data.status === "new_merchant"}
            showForm={data.documents_submitted !== "yes" && data.status === "new_merchant"}
            showMeta={data.documents_submitted}
            showSave={form.changed}
            passed={data.documents_submitted === "yes"}
            columnA={[
                {
                    key: "documents_submitted",
                    label: "Documents Submitted",
                    format: "titleCase"
                }
            ]}
            columnB={[
                {
                    key: "reference_id",
                    label: "Reference ID",
                }
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={"documents_submitted"}
                                label={"Documents Submitted"}
                                defaultValue={form.defaults.documents_submitted}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.documents_submitted === "yes" && (

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    name={"reference_id"}
                                    label={"Reference ID"}
                                    defaultValue={form.defaults.reference_id}
                                />

                            </Col>

                        )}

                    </Row>
                </>
            }
        />

    ), [props, form, process.loading]);

}

export default MerchantsStageSubmitDocuments;
