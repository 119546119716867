import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { FieldRadio, FieldRelationship } from '@dex/bubl-dash';

const InstallationStageAssignTechnician: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_assign_technician");

    const reverse = InstallationsApi.update(data.id, "stage_reverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"assign_technician"}
            heading={"Assign Service Provider"}
            editCaps={["stage_assign_technicianInstallations"]}
            editable={false}
            showForm={data.assign_technician !== "yes"}
            showMeta={data.assign_technician === "yes"}
            passed={data.assign_technician === "yes"}
            showSave={form.changed}
            reverse={data.assign_technician === "yes" ? null : reverse}
            last_stage={"quality_control"}
            columnA={[
                {
                    label: "Service Provider",
                    key: "provider",
                    format: "ModelLink"
                },
                {
                    label: "Collection Method",
                    key: "collection_method",
                    format: "intl",
                    prefix: "installation.collection_method",
                },
                {
                    label: "Contact Person",
                    key: "provider.person",
                },
            ]}
            columnB={[
                {
                    label: "Phone",
                    key: "provider.phone",
                },
                {
                    label: "Address",
                    key: "provider.billing_address",
                    format: "address"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Service Provider"}
                                        name='provider_id'
                                        required={true}
                                        model={'Providers'}
                                        preload={true}
                                        defaultValue={form.defaults.provider_id}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Collection Method"}
                                        name='collection_method'
                                        required={true}
                                        defaultValue={form.defaults.collection_method}
                                        options={[
                                            { label: "Self Collect", value: "self_collect" },
                                            { label: "Postage", value: "postage" },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default InstallationStageAssignTechnician;
