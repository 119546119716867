import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Heading, Line, PanelTabs, Space } from '@dex/bubl-dash';

const EngagementPlansSingleView: React.FC<any> = (props: any) => {
    const [tab, setTab] = useState("merchant");

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col col={{ xs: 24 }} >

                <Panel
                    heading={"Engagement Plan Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Model",
                                key: "business_model",
                                format: "intl",
                                prefix: "engagement_plans.business_model"
                            },
                            {
                                label: "Plan Name",
                                key: "plan_name",
                            },
                            {
                                label: "Plan Name on Invoice",
                                key: "billing_name",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "modelLink",
                            },
                            {
                                label: "Device Type",
                                key: "device_type",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col col={{ xs: 24 }}>

                <PanelTabs
                    activeTab={tab}
                    setTab={setTab}
                    tabs={[
                        { name: "merchant", label: "Merchant" },
                        { name: "leasing", label: "Leading / Rental" },
                        { name: "acquirer", label: "Acquirer" },
                    ]}
                />

                <Space />

                <Panel
                    heading="Invoice To"
                >
                    <Line />


                    {tab === "merchant" && (
                        <>
                            <Heading space={false}>Initial Rollout</Heading>

                            <Space height={"small"} />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Processing Fee",
                                        key: "charge_merchant_processing_fee",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Selling Price",
                                        key: "charge_merchant_selling_price",
                                        format: "number",
                                        prefix: "RM "
                                    }
                                ]}
                                columnB={[
                                    {
                                        label: "Refundable Deposit",
                                        key: "charge_merchant_deposit",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Maintenance Fee",
                                        key: "charge_merchant_maintenance_fee",
                                        format: "number",
                                        prefix: "RM "
                                    }
                                ]}
                                columnC={[
                                    {
                                        label: "Agent's Commission - Headquarters",
                                        key: "charge_merchant_agent_commission_headquarters",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Agent's Commission - Branch",
                                        key: "charge_merchant_agent_commission_branch",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Agent's Commission - Additional",
                                        key: "charge_merchant_agent_commission_additional",
                                        format: "number",
                                        prefix: "RM "
                                    }
                                ]}
                            />

                            <Line />

                            <Heading space={false}>Annual Maintenance</Heading>

                            <Space height={"small"} />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Maintenance Fee",
                                        key: "charge_annual_maintenance_fee",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Refundable Deposit",
                                        key: "charge_annual_deposit",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                ]}
                                columnC={[
                                    {
                                        label: "Agent's Commission",
                                        key: "charge_annual_agent_commission",
                                        format: "number",
                                        prefix: "RM "
                                    }
                                ]}
                            />

                        </>
                    )}

                    {tab === "leasing" && (

                        <>

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Selling Price",
                                        key: "charge_leasing_selling_price",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Rental (Monthly)",
                                        key: "charge_leasing_rental",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "SAAS Fee (Monthly)",
                                        key: "charge_leasing_saas",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "MYEVO SIM Fee (Monthly)",
                                        key: "charge_leasing_myevo_sim",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Rental Duration (Months)",
                                        key: "charge_leasing_rental_duration",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "Service Fee (Monthly)",
                                        key: "charge_leasing_service",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                    {
                                        label: "5MB SIM Fee (Monthly)",
                                        key: "charge_leasing_sim",
                                        format: "number",
                                        prefix: "RM "
                                    },
                                ]}
                                columnC={[

                                ]}
                            />

                        </>

                    )}

                    {tab === "acquirer" && (

                        <MetaTable
                            data={data}
                            columnA={[
                                {
                                    label: "Terminal Installed At Headquarters",
                                    key: "charge_acquirer_headquarters",
                                    format: "number",
                                    prefix: "RM "
                                },

                            ]}
                            columnB={[
                                {
                                    label: "Terminal Installed At Branch Office",
                                    key: "charge_acquirer_branch",
                                    format: "number",
                                    prefix: "RM "
                                },

                            ]}
                            columnC={[
                                {
                                    label: "Terminal Installed As Additional Unit",
                                    key: "charge_acquirer_additional",
                                    format: "number",
                                    prefix: "RM "
                                },
                            ]}
                        />
                    )}

                </Panel>

            </Col>

        </Row>

    ), [data, tab]);

}

export default EngagementPlansSingleView;
