import React, { useCallback, useEffect } from 'react';
import { Button, FieldTextArea, Form, Line, Panel, useForm } from '@dex/bubl-dash';
import MassRolloutsApi from '../MassRolloutsApi';
import { clone } from '@dex/bubl-helpers';

const MassRolloutsImportForm: React.FC<any> = (props: any) => {

    const { type, form, handleClose } = props;

    const importForm = useForm({});

    const check = MassRolloutsApi.create({}, "check_serials");

    const handleImport = useCallback((values) => {

        const serials = values.serials = values.serials.split("\n").map((serial: string) => { return serial.trim() }).filter(Boolean);

        let name = type;

        if (type === "terminals") name = "terminal_ids";

        if (type === "batteries") {
            setValues("batteries", serials);
            return;
        }

        check.run({
            data: { type: type, serials: serials },
            onComplete: (response) => {

                setValues(name, response);

            },
        })

    }, []);

    useEffect(() => {

        return () => {
            check.cancel();
        }

    }, [check.hash]);

    const setValues = (name, values) => {

        let value = clone(form.values[name] || []);

        value = [...value, ...values];

        form.handleChange({ name: name, value: value, reset: true });

        handleClose();

    }

    return (

        <Panel
            heading={"Import"}
        >

            <Form
                form={importForm}
                onSubmit={handleImport}
                loading={check.loading}
            >

                <FieldTextArea
                    form={importForm}
                    name={"serials"}
                    defaultValue={""}
                    rows={10}
                    placeholder={"Enter serials here, one per line"}
                />

                <Line />

                <Button
                    label={"Import"}
                    loading={check.loading}
                />

            </Form>

        </Panel>

    );

}

export default MassRolloutsImportForm;
