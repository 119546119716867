import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import InventoriesTrackingApi from './InventoriesTrackingApi';
import Status from '@dex/bubl-dash/src/lib/components/Status/Status';

let settings;

export default settings = {
    key: 'inventoriestracking',
    zone: '',
    path: '/inventoriestracking',
    title: "InventoriesTracking",
    endpoint: InventoriesTrackingApi.endpoint,
    modelName: 'InventoriesTracking',
    idKey: 'id',
    primaryKey: 'company_name',
    indexCaps: ["viewInventoriesTracking"],
    viewCaps: ["viewInventoriesTracking"],
    createCaps: ["createInventoriesTracking"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: InventoriesTrackingApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {}, include: [
                {
                    relation: 'inventory',
                    preset: "link"
                },
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
            ], order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'date', 'in_stock', 'in_process', 'status'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Date",
                key: "date",
                type: "date",
            },
            {
                label: "Stock Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "in_stock", label: "In Stock" },
                    { value: "in_process", label: "Processing" },
                    { value: "deployed", label: "Deployed" },
                    { value: "delivered", label: "Delivered" },
                    { value: "write_off", label: "Write-Off" }
                ]
            },
            {
                label: "In Stock Location",
                key: "in_stock",
                type: "choice",
                options: [
                    { value: "store", label: "In Stock" },
                    { value: "store_used", label: "Processing" },
                    { value: "spare_stock", label: "Spare Stock" },
                    { value: "technician", label: "Technician" },
                    { value: "bench_repair", label: "Bench Repair" },
                    { value: "acquirer_floating_unit", label: "Acquirer Floating Unit" },
                    { value: "warehouse_entry", label: "Warehouse Entry" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Date",
                key: "date",
                format: "dateTime",
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "terminals.status"

            },
            {
                label: "Location",
                key: "in_stock",
                render: (value, row) => {

                    if (row.status === "in_stock") return <Status status={row.in_stock} prefix='terminals.in_stock' />
                    if (row.status === "in_process") return <Status status={row.in_process} prefix='terminals.in_process' />
                    return <></>

                }
            },
            {
                label: "Merchant",
                key: "merchant",
                format: "modelLink",

            },
            {
                label: "Technician",
                key: "technician",
                format: "modelLink",

            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: InventoriesTrackingApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: InventoriesTrackingApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'inventory',
                    preset: "link"
                },
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'acquirer',
                    preset: "link"
                },
                {
                    relation: 'provider',
                    preset: "link"
                },
                {
                    relation: 'technician',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};