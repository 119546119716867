import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldAddress, FieldAutocomplete, FieldRadio, FieldSwitch, ModelLink, Seperator } from '@dex/bubl-dash';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import { render } from 'react-dom';

const ServiceStageAmendmentRequest: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ ...data, amend: data.merchant });

    const process = ServicesApi.update(data.id, "stage_request_amendment");

    const app = useApp();

    return (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"request_amendment"}
            heading={"Amendment Request"}
            editCaps={["stage_request_amendmentServices"]}
            editable={false}
            showForm={data.request_amendment !== "yes"}
            showMeta={data.request_amendment === "yes"}
            passed={data.request_amendment === "yes"}
            showSave={form.changed}
            renderMetaAfter={() => (
                <>
                    {data.amendment_type === "change_merchant_details" &&
                        <>

                            <Seperator
                                heading={"Merchant Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />


                            <MetaTable
                                data={data.amend}
                                columnA={[
                                    {
                                        label: "Company Name",
                                        key: "company_name",
                                    },
                                    {
                                        label: "Trading Name",
                                        key: "trading_name",
                                    },
                                    {
                                        label: "Merchant Location",
                                        key: "merchant_location",
                                        format: "titleCase"
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Contact Person",
                                        key: "person",
                                    },
                                    {
                                        label: "Phone",
                                        key: "phone",
                                    },
                                    {
                                        label: "E-Mail",
                                        key: "email",
                                    },

                                ]}
                                columnC={[
                                    {
                                        label: "Acquirer - Person In Charge",
                                        key: "acquirer_person",
                                    },
                                    {
                                        label: "Notification Email",
                                        key: "notification_email",
                                    },

                                    {
                                        label: "Documents",
                                        key: "documents",
                                        format: "file"
                                    },
                                ]}
                            />

                            <Seperator
                                heading={"Additional Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />


                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Advisor",
                                        key: "agent",
                                        format: "ModelLink"
                                    },

                                ]}
                                columnB={[
                                    {
                                        label: "Agency",
                                        key: "agency",
                                        format: "ModelLink"
                                    },
                                ]}
                                columnC={[


                                ]}
                            />

                            <Seperator
                                heading={"Billing Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <MetaTable
                                data={data.amend}
                                columnA={[
                                    {
                                        label: "Billing Address",
                                        key: "billing_address",
                                        format: "address"
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Installation Address",
                                        key: "installation_address",
                                        format: "address"
                                    },
                                ]}
                            />

                        </>
                    }
                </>
            )}
            columnA={[
                {
                    label: "Amendment Type",
                    key: "amendment_type",
                    format: "intl",
                    prefix: "service.amendment"
                },
                data.amendment_type === "change_active_date" && {
                    label: "New Active Date",
                    key: "amend.active_date",
                    format: "date",
                },
                data.amendment_type === "change_retrieval_date" && {
                    label: "New Retrieve Date",
                    key: "amend.retrieved_date",
                    format: "date",
                },
                data.amendment_type === "reuse_floating_merchant" && {
                    label: "Floating Merchant ID",
                    key: "amend.floating_merchant_id",
                    format: "modelLink",
                    render: (value) => (<ModelLink data={{ id: value }} modelName="Merchants" />),
                },
            ].filter(Boolean)}
            columnB={[
                {
                    label: "Change Reason",
                    key: "change_reason",
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Amendment Type"}
                                name='amendment_type'
                                required={true}
                                defaultValue={form.defaults.amendment_type}
                                options={[
                                    { label: app.intl("service.amendment.change_merchant_details"), value: "change_merchant_details" },
                                    data.merchant.status === "active" && { label: app.intl("service.amendment.change_active_date"), value: "change_active_date" },
                                    { label: app.intl("service.amendment.change_retrieval_date"), value: "change_retrieval_date" },
                                    data.merchant.status === "active" && { label: app.intl("service.amendment.reuse_floating_merchant"), value: "reuse_floating_merchant" },
                                ].filter(Boolean)}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldTextArea
                                form={form}
                                label={"Reason For Ammendment"}
                                name='change_reason'
                                required={true}
                                defaultValue={form.defaults.change_reason}
                            />

                        </Col>

                    </Row>

                    {form.values.amendment_type === "change_merchant_details" && (
                        <>

                            <Seperator
                                heading={"Merchant Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Company Name"}
                                                name='amend.company_name'
                                                required={true}
                                                defaultValue={form.defaults.amend?.company_name}
                                                transform={'uppercase'}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Trading Name"}
                                                name='amend.trading_name'
                                                required={false}
                                                defaultValue={form.defaults.amend?.trading_name}
                                                transform={'uppercase'}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Contact Person"}
                                                name='amend.person'
                                                required={true}
                                                defaultValue={form.defaults.amend?.person}
                                                transform={'uppercase'}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"E-Mail"}
                                                name='amend.email'
                                                required={true}
                                                defaultValue={form.defaults.amend?.email}
                                                transform={'uppercase'}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Phone"}
                                                name='amend.phone'
                                                required={true}
                                                defaultValue={form.defaults.amend?.phone}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Notification Email"}
                                                name='amend.notification_email'
                                                required={false}
                                                defaultValue={form.defaults.amend?.notification_email}
                                                transform={'uppercase'}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24 }}>

                                            <FieldAutocomplete
                                                form={form}
                                                label={"Acquirer - Person In Charge"}
                                                name='amend.acquirer_person'
                                                required={false}
                                                defaultValue={form.defaults.amend?.acquirer_person}
                                                transform={'uppercase'}
                                                group='acquirer_person'
                                                preload={true}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Business Registration Number (BRN)"}
                                                name='amend.brn'
                                                required={false}
                                                defaultValue={form.defaults.amend?.brn}
                                                maxLength={12}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldText
                                                form={form}
                                                label={"Tax Identification Number (TIN)"}
                                                name='amend.tin'
                                                required={false}
                                                defaultValue={form.defaults.amend?.tin}
                                                maxLength={12}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 24 }}>

                                            <FieldText
                                                form={form}
                                                label={"SST No."}
                                                name='amend.sst'
                                                required={false}
                                                defaultValue={form.defaults.amend?.sst}
                                                maxLength={15}
                                            />

                                        </Col>

                                        {form.values.merchant.process === "mrp" && (

                                            <>
                                                <Col col={{ xs: 24 }}>

                                                    <FieldRelationship
                                                        form={form}
                                                        label={"Advisor"}
                                                        name='amend.agent_id'
                                                        required={false}
                                                        endpoint={'Users'}
                                                        where={{ roles: 'advisor' }}
                                                        preload={true}
                                                        defaultValue={form.defaults.amend?.agent_id}
                                                    />

                                                </Col>

                                                <Col col={{ xs: 24 }}>

                                                    <FieldRadio
                                                        form={form}
                                                        label={"Merchant Location"}
                                                        name='amend.merchant_location'
                                                        required={false}
                                                        defaultValue={form.defaults.amend?.merchant_location}
                                                        options={[
                                                            { value: 'headquarters', label: 'Headquarters' },
                                                            { value: 'branch', label: 'Branch' },
                                                            { value: 'additional', label: 'Additional' },
                                                        ]}
                                                    />

                                                </Col>

                                            </>

                                        )}


                                    </Row>

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'admin-media'}
                                        label={"Documents"}
                                        name='amend.documents'
                                        required={false}
                                        defaultValue={form.defaults.amend?.documents}
                                        limit={20}
                                    />

                                </Col>

                            </Row>

                            <Seperator
                                heading={"Billing Details"}
                                top={"auto"}
                                bottom={"auto"}
                            />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldAddress
                                        form={form}
                                        label={"Billing Address"}
                                        name='amend.billing_address'
                                        required={true}
                                        defaultValue={form.defaults.amend?.billing_address}
                                        allowedCountries={["Malaysia"]}
                                        transform={'uppercase'}
                                        maxLength={{
                                            line1: 120,
                                            line2: 120,
                                            city: 13,
                                            zipcode: 5,
                                        }}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Installation Address same as Billing Address?"}
                                        name='amend.installation_same'
                                        required={false}
                                        defaultValue={form.defaults.amend?.installation_same}
                                    />

                                </Col>

                                {!form.values.amend?.installation_same &&

                                    <Col col={{ xs: 24, md: 16 }}>

                                        <FieldAddress
                                            form={form}
                                            label={"Installation Address"}
                                            name='amend.installation_address'
                                            required={false}
                                            defaultValue={form.defaults.amend?.installation_address}
                                            allowedCountries={["Malaysia"]}
                                            transform={'uppercase'}
                                            maxLength={{
                                                line1: 120,
                                                line2: 120,
                                                city: 13,
                                                zipcode: 5,
                                            }}
                                        />

                                    </Col>

                                }

                            </Row>

                        </>
                    )}

                    {form.values.amendment_type === "change_active_date" && (

                        <Row gutter={"auto"} edge>

                            <Col col={{ xs: 24 }}>

                                <MetaTable
                                    data={data}
                                    columnA={[
                                        {
                                            label: "Current Active Date",
                                            key: "merchant_active_date",
                                            format: "date"
                                        },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldDate
                                    form={form}
                                    name={"amend.active_date"}
                                    required={true}
                                    label={"Change Active Date To"}
                                    defaultValue={form.defaults.amend?.active_date || ""}
                                />

                            </Col>

                        </Row>

                    )}

                    {form.values.amendment_type === "change_retrieval_date" && (

                        <Row gutter={"auto"} edge>

                            <Col col={{ xs: 24 }}>

                                <MetaTable
                                    data={data}
                                    columnA={[
                                        {
                                            label: "Current Retrieval Date",
                                            key: "merchant_retrieved_date",
                                            format: "date"
                                        },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 8 }}>

                                <FieldDate
                                    form={form}
                                    name={"amend.retrieved_date"}
                                    required={true}
                                    label={"Change Retrived Date To"}
                                    defaultValue={form.defaults.amend?.retrieved_date || ""}
                                />

                            </Col>

                        </Row>

                    )}

                    {form.values.amendment_type === "reuse_floating_merchant" && (

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldRelationship
                                    form={form}
                                    label={"Floating Merchant ID"}
                                    name='amend.floating_merchant_id'
                                    required={false}
                                    endpoint={'Merchants'}
                                    where={{ status: 'floating' }}
                                    preload={true}
                                    defaultValue={form.defaults.amend?.floating_merchant_id}
                                />

                            </Col>

                        </Row>
                    )}

                </>

            }
        />

    );

}

export default ServiceStageAmendmentRequest;
