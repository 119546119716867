import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { Button, ButtonRow, useApp } from '@dex/bubl-dash';

const InstallationStagePrintDocuments: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_print_documents");

    const handlePrintDeliveryNote = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/installations/${data.id}/delivery-note/${action}`);

    }, []);

    const handlePrintInvoice = useCallback((action) => {

        const baseUrl = app.options.endpoint.slice(0, -4);

        window.open(`${baseUrl}/invoices/${data.merchant.invoice_id}/invoice/${action}`);

    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"print_documents"}
            heading={"Print Documents"}
            // editCaps={["stage_print_documentsInstallations"]}
            showForm={true}
            showMeta={false}
            passed={data.print_documents === "yes"}
            showSave={form.changed}
            columnA={[

            ]}
            columnB={[

            ]}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <ButtonRow>

                                <Button
                                    label={"Print Delivery Note"}
                                    type={data.print_documents !== "yes" ? "primary" : "faded"}
                                    onClick={handlePrintDeliveryNote.bind(null, "print")}
                                    size='regular'
                                />

                                <Button
                                    label={"Preview"}
                                    type='ghost'
                                    onClick={handlePrintDeliveryNote.bind(null, "preview")}
                                    size='regular'
                                />

                            </ButtonRow>

                        </Col>

                        {data.merchant.invoice_id &&
                            <Col col={{ xs: 24, md: 12 }}>

                                <ButtonRow>

                                    <Button
                                        label={"Print Invoice"}
                                        type={data.print_invoice !== "yes" ? "faded" : "faded"}
                                        onClick={handlePrintInvoice.bind(null, "print")}
                                        size='regular'
                                    />

                                    <Button
                                        label={"Preview"}
                                        type='ghost'
                                        onClick={handlePrintInvoice.bind(null, "preview")}
                                        size='regular'
                                    />

                                </ButtonRow>

                            </Col>
                        }

                    </Row>

                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default InstallationStagePrintDocuments;
