import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';

const MassRolloutsBatteries: React.FC<any> = (props: any) => {

    const { data } = props;

    const columns = [
        {
            label: "Serial Number",
            key: 'sn'
        }
    ];

    return useMemo(() => (

        <>

            <Panel
                heading={"Battery Packs"}
            >

                <DataTable
                    data={data.batteries}
                    columns={columns}
                />

            </Panel>

        </>

    ), [data]);

}

export default MassRolloutsBatteries;
