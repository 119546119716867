import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import StockCreateEditForm from "./Components/StockCreateEditForm";
import StockApi from "./StockApi";
import StockTransferForm from './Components/StockTransferForm';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StockCreateEditForm,
                getCreate: StockApi.create,
                getClone: StockApi.getOne,
                defaults: {},
                successMessage: "Stock Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

    app.addRoute({
        name: settings.key + "Transfer",
        zone: settings.zone,
        path: settings.key + "/transfer",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Transfer Stock",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StockTransferForm,
                getCreate: StockApi.create,
                handler: "transfer",
                defaults: {},
                successMessage: "Stock Transferred",
                successCallback: (response, app, nav) => {
                    const route = { "type": "Navigation/NAVIGATE", routeName: settings.key, params: { batch: response.batch } };
                    app.navigate(nav, 'push', route);
                },
            },
        },
    });

}