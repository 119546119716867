import MerchantsIndex from './MerchantsIndex';
import MerchantsCreate from './MerchantsCreate';
import MerchantsSettings from './MerchantsSettings';
import MerchantsSingle from './MerchantsSingle';

export default function (app) {

    const settings = MerchantsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Merchants",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "ESA",
                path: settings.path + "/model/esa",
                caps: settings.indexCaps
            },
            {
                label: "Rent To Own",
                path: settings.path + "/model/rent",
                caps: settings.createCaps
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
                caps: settings.indexCaps
            },
            {
                label: "New",
                path: settings.path + "/status/new_merchant",
                caps: settings.indexCaps
            },
            {
                label: "Installation",
                path: settings.path + "/status/installation",
                caps: settings.indexCaps
            },
            {
                label: "Floating",
                path: settings.path + "/status/floating",
                caps: settings.createCaps
            },
            {
                label: "Inactive",
                path: settings.path + "/status/inactive",
                caps: settings.indexCaps
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
                caps: settings.indexCaps
            },
        ],
    });

    MerchantsIndex(app, settings);

    MerchantsCreate(app, settings);

    MerchantsSingle(app, settings);

}