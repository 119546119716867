import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import InventoriesApi from './InventoriesApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'inventory',
    zone: 'stock',
    path: '/inventory',
    title: "Fixed Inventory",
    endpoint: InventoriesApi.endpoint,
    modelName: 'Inventories',
    idKey: 'id',
    primaryKey: 'serial',
    indexCaps: ["viewInventories"],
    viewCaps: ["viewInventories"],
    createCaps: ["createInventories"],
    actions: ['trash'],

    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "In Stock",
                path: settings.path + "/status/in_stock",
            },
            {
                label: "Processing",
                path: settings.path + "/status/in_process",
            },
            {
                label: "Deployed",
                path: settings.path + "/status/deployed",
            },
            {
                label: "Delivered",
                path: settings.path + "/status/delivered",
            },
            {
                label: "Write-Off",
                path: settings.path + "/status/write_off",
            },
            {
                label: "Summary",
                path: settings.path + "/summary",
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: InventoriesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        let { status } = params || {};

        if (status === 'write_off') filters.where.in_stock = status;
        else if (status) filters.where.status = status;

        if (params && params.batch) filters.where.batch = params.batch;

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "inventories", label: "Inventories" }
        ];

        if (!user || !user.caps.includes("exportInventories")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['serial', 'model', 'type'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Serial Number",
                key: "serial",
                type: "text",
            },
            {
                label: "Model Number",
                key: "model",
                type: "text",
            },
            {
                label: "Brand",
                key: "brand",
                type: "choice",
                options: [
                    { value: "color2com_newpos", label: "Color2Com New Pos" },
                    { value: "color2com_newland", label: "Color2Com Newland" },
                    { value: "bbpos", label: "BBPOS" }
                ]
            },
            {
                label: "Type",
                key: "type",
                type: "choice",
                options: [
                    { value: "swivel", label: "Swivel" },
                    { value: "router", label: "Router" },
                    { value: "docking", label: "Docking" },
                    { value: "sim", label: "Sim Card" },
                ]
            },
            {
                label: "Stock Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "in_stock", label: "In Stock" },
                    { value: "in_process", label: "Processing" },
                    { value: "deployed", label: "Deployed" },
                    { value: "delivered", label: "Delivered" },
                    { value: "write_off", label: "Write-Off" }
                ]
            },
            {
                label: "In Stock Location",
                key: "in_stock",
                type: "choice",
                conditions: (form) => {
                    return form.values.status === "in_stock";
                },
                options: [
                    { value: "store", label: "Store - New" },
                    { value: "store_used", label: "Store - Used" },
                    { value: "technician", label: "Technician" },
                    { value: "technician_faulty", label: "Technician Faulty" },
                    { value: "bench_repair", label: "Bench Repair" },
                    { value: "acquirer_floating_unit", label: "Acquirer Floating Unit" },
                    { value: "warehouse_entry", label: "Warehouse Entry" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Serial Number",
                key: "this",
                format: "ModelLink",
                sort: 'id'
            },
            {
                label: "Description",
                key: "description",
            },
            {
                label: "Type",
                key: "type",
                format: "intl",
                prefix: "inventories.type"
            },
            {
                label: "Asset Owner",
                key: "owner",
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "inventories.status"
            },
            {
                label: "Location",
                key: "in_stock",
                render: (value, row) => {

                    if (row.status === "in_stock") return <Status status={row.in_stock} prefix='inventories.in_stock' />
                    if (row.status === "in_process") return <Status status={row.in_process} prefix='inventories.in_process' />
                    return <></>

                }
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: InventoriesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: InventoriesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>
            {title}

            <WhiteSpace />

            {data && (
                <>
                    <Status status={data.status} prefix="inventories.status" />
                    <WhiteSpace />
                    {data.in_stock && (
                        <Status status={data.in_stock} prefix="inventories.in_stock" />
                    )}
                    {data.in_process && (
                        <>
                            <WhiteSpace />
                            <Status status={data.in_process} prefix="inventories.in_process" />
                        </>
                    )}
                </>
            )}

        </>

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
            {
                label: "Transfer",
                path: path + "/transfer"
            },
            {
                label: "Activity",
                path: path + "/activity"
            }
        ]

    },
};