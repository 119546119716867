import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAddress, FieldNumber, FieldRelationship, Seperator } from '@dex/bubl-dash';

const DepositEntriesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Deposit Entry Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Merchant"}
                            name={"merchant_id"}
                            defaultValue={form.defaults.merchant_id}
                            required
                            model={"Merchants"}
                            preload={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Invoice ID"}
                            name={"invoice_id"}
                            defaultValue={form.defaults.invoice_id}
                            required
                            model={"Invoices"}
                            preload={true}
                            where={{ status: { inq: ["paid"] }, deposit: { gt: 0 } }}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Contact Person"}
                            name='person'
                            required={false}
                            defaultValue={form.defaults.person}
                            transform={'uppercase'}
                            maxLength={30}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"E-Mail"}
                            name='email'
                            required={false}
                            defaultValue={form.defaults.email}
                            transform={'uppercase'}
                            maxLength={50}
                        />

                    </Col>

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Phone"}
                            name='phone'
                            required={false}
                            defaultValue={form.defaults.phone}
                            maxLength={30}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label={"Bank Account"}
                            name='bank_account_id'
                            required={true}
                            model={'BankAccounts'}
                            preload={true}
                            defaultValue={form.defaults.bank_account_id}
                            order={["created.date DESC"]}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Billing Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>


                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldAddress
                            form={form}
                            label={"Billing Address"}
                            name='billing_address'
                            required={true}
                            defaultValue={form.defaults.billing_address}
                            allowedCountries={["Malaysia"]}
                            transform={'uppercase'}
                            maxLength={{
                                line1: 120,
                                line2: 120,
                                city: 13,
                                zipcode: 5,
                            }}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default DepositEntriesCreateEditForm;
