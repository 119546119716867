import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldDate, FieldText, FieldTextArea } from '@dex/bubl-dash';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';


const ReplacementsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Replacements Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            label={"Faulty Serial Number"}
                            form={form}
                            name={`faulty_terminal_id`}
                            required={false}
                            model={'Terminals'}
                            preload={true}
                            where={{ status: { inq: ['deployed', 'delivered'] } }}
                            defaultValue={form.defaults.faulty_terminal_id}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldDate
                            form={form}
                            name={"return_date"}
                            required={true}
                            label={"Return Date"}
                            defaultValue={form.defaults.return_date || ""}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='additional_note'
                            required={false}
                            defaultValue={form.defaults.additional_note}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ReplacementsCreateEditForm;
