import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const LeasingCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Leasing Company Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldText
                            form={form}
                            label={"Company Name"}
                            name='company_name'
                            required={true}
                            defaultValue={form.defaults.company_name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldText
                            form={form}
                            label={"Contact Person"}
                            name='person'
                            required={false}
                            defaultValue={form.defaults.person}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"E-Mail"}
                            name='email'
                            required={false}
                            defaultValue={form.defaults.email}
                        />

                    </Col>


                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Phone"}
                            name='phone'
                            required={false}
                            defaultValue={form.defaults.phone}
                        />

                    </Col>



                </Row>
                <Row gutter={8} edge={true}>
                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Business Registration Number (BRN)"}
                            name='brn'
                            required={false}
                            defaultValue={form.defaults.brn}
                            maxLength={12}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Tax Identification Number (TIN)"}
                            name='tin'
                            required={false}
                            defaultValue={form.defaults.tin}
                            maxLength={12}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldText
                            form={form}
                            label={"SST No."}
                            name='sst'
                            required={false}
                            defaultValue={form.defaults.sst}
                            maxLength={15}
                        />

                    </Col>
                </Row>


                <Seperator
                    heading={"Billing Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldAddress
                            form={form}
                            label={"Billing Address"}
                            name='billing_address'
                            required={false}
                            defaultValue={form.defaults.billing_address}
                            allowedCountries={["Malaysia"]}
                            transform={"uppercase"}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Additional Details"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 16 }}>

                        <FieldTextArea
                            form={form}
                            label={"Additional Notes"}
                            name='notes'
                            required={false}
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default LeasingCreateEditForm;
