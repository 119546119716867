import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldHidden, FieldList, FieldRepeater, FieldSelect, FieldSwitch, Space, useApp } from '@dex/bubl-dash';
import { get, slug } from '@dex/bubl-helpers';

const SettingsChecklists: React.FC<any> = (props: any) => {

    const { form } = props;

    const app = useApp();

    const [settings] = app.store("appSettings", {});

    return useMemo(() => (

        <>

            <Space />

            <Seperator
                heading="Merchant Documents Checklist"
                bottom={"small"}
                top={false}
            />

            <FieldList
                form={form}
                name="document"
                required={false}
                addButtonLabel={"Add Checklist"}
                itemLabel='Document'
            >

                {(form.values?.document || []).map((item, index) => (
                    <>
                        <FieldText
                            form={form}
                            label={!index ? `Document Name` : ``}
                            name={`document[${index}]`}
                            defaultValue={get(form.values, `document[${index}]`)}
                            required
                        />
                    </>
                ))}

            </FieldList>

        </>

    ), [form.hash]);

}

export default SettingsChecklists;
