import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { useApp } from '@dex/bubl-dash';

const ServiceStageRetrievalDetails: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_retrieval_details");

    const checklist = [
        { label: "Terminal - " + data.faulty_serial, value: "Terminal - " + data.faulty_serial },
    ];

    if (data.faulty_sim_card_id) checklist.push({ label: "SIM Card - " + data.faulty_sim_card_serial, value: "SIM Card - " + data.faulty_sim_card_serial });
    if (data.faulty_swivel_id) checklist.push({ label: "SIM Card - " + data.faulty_swivel_serial, value: "SIM Card - " + data.faulty_swivel_serial });
    if (data.faulty_router_id) checklist.push({ label: "SIM Card - " + data.faulty_router_serial, value: "SIM Card - " + data.faulty_router_serial });
    if (data.faulty_docking_id) checklist.push({ label: "SIM Card - " + data.faulty_docking_serial, value: "SIM Card - " + data.faulty_docking_serial });

    const isFloating = data.business_model === "rent-to-own";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"retrieval_details"}
            heading={"Retrieval Details"}
            editCaps={["stage_retrieval_detailsServices"]}
            editable={false}
            showForm={data.retrieval_complete !== "yes"}
            showMeta={data.retrieval_complete === "yes"}
            passed={data.retrieval_complete === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Retrieval Complete",
                    key: "retrieval_complete",
                    format: "titleCase"
                },

            ]}
            columnB={[
                {
                    label: "Send Terminal To",
                    key: "send_terminal_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Sim Card To",
                    key: "send_sim_card_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Swivel To",
                    key: "send_swivel_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Router To",
                    key: "send_router_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
                {
                    label: "Send Docking To",
                    key: "send_docking_to",
                    format: "intl",
                    prefix: "terminals.in_stock",
                    hideIfEmpty: true
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Retrieval Complete"}
                                name='retrieval_complete'
                                required={true}
                                defaultValue={form.defaults.retrieval_complete}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.retrieval_complete === "yes" && (
                            <>


                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Send Terminal To - " + data.faulty_serial}
                                        name='send_terminal_to'
                                        required={true}
                                        defaultValue={form.defaults.send_terminal_to}
                                        list
                                        options={[
                                            isFloating ? { label: app.intl("terminals.in_stock.acquirer_floating_unit"), value: "acquirer_floating_unit" } : null,
                                            !isFloating ? { label: app.intl("terminals.in_stock.store_used"), value: "store_used" } : null,
                                            { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                            { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                        ].filter(Boolean)}
                                    />

                                </Col>

                                {data.faulty_adapter_type &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Adapter To - " + app.intl(`installation.adapter_type.${data.faulty_adapter_type}`)}
                                            name='send_adapter_to'
                                            required={true}
                                            defaultValue={form.defaults.send_adapter_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ].filter(Boolean)}
                                        />

                                    </Col>
                                }

                                {data.faulty_sim_card_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Sim Card To - " + data.faulty_sim_card_serial}
                                            name='send_sim_card_to'
                                            required={true}
                                            defaultValue={form.defaults.send_sim_card_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }

                                {data.faulty_swivel_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Swivel To - " + data.faulty_swivel_serial}
                                            name='send_swivel_to'
                                            required={true}
                                            defaultValue={form.defaults.send_swivel_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }

                                {data.faulty_router_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Router To - " + data.faulty_router_serial}
                                            name='send_router_to'
                                            required={true}
                                            defaultValue={form.defaults.send_router_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }
                                {data.faulty_docking_id &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"Send Docking To - " + data.faulty_docking_serial}
                                            name='send_docking_to'
                                            required={true}
                                            defaultValue={form.defaults.send_docking_to}
                                            list
                                            options={[
                                                { label: app.intl("terminals.in_stock.store_used"), value: "store_used" },
                                                { label: app.intl("terminals.in_stock.bench_repair"), value: "bench_repair" },
                                                { label: app.intl("terminals.in_stock.lost/write_off"), value: "lost/write_off" },
                                            ]}
                                        />

                                    </Col>
                                }

                            </>
                        )}

                    </Row>

                </>

            }

        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageRetrievalDetails;
