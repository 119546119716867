import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { Button, FieldDate, FieldHidden, FieldRadio, FieldRelationship, FieldSelect, FieldTextArea, FieldUpload, useApp, useUser, userHasCap, userHasRole } from '@dex/bubl-dash';
import ServiceStageAttemptFailed from '../../Services/Stages/ServiceStageAttemptFailed';
import { validateDatePassed } from '../../../functions/sharedValidation';
import ServiceStageIncompleteMessages from '../../Services/Stages/ServiceStageIncompleteMessages';

const InstallationStageInstallationAndTraining: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const user = useUser();

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_installation_training");

    const handleInstructionsToTechnician = () => { }

    const canEdit = data.status === "complete" && app.userCan(["updateCompletedInstallation"]);

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasRole("technician");
    const isManager = isTechnician && userHasCap(["companyManager"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    const reverse = InstallationsApi.update(data.id, "stage_reverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"installation_training"}
            heading={"Installation & Training"}
            editable={canEdit}
            editCaps={["stage_installation_trainingInstallations"]}
            showForm={data.installation_training !== "yes"}
            showMeta={data.installation_training}
            passed={data.installation_training === "yes"}
            showSave={form.changed}
            reverse={data.installation_training === "yes" && isStaff ? null : reverse}
            last_stage={"outbound"}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Installation And Installation Complete",
                    key: "installation_training",
                    format: "titleCase"
                },
                data.installation_training === "yes" && {
                    label: "Technician",
                    key: "technician",
                    format: "ModelLink"
                },
                data.installation_training === "yes" && {
                    label: "Date Installed On",
                    key: "active_date",
                    format: "date"
                },
                data.installation_training === "no" && {
                    label: data.installation_training === "no" ? "Reason Installation Failed" : "Reason Installation Delayed",
                    key: data.installation_training === "no" ? "installation_failed_reason" : "installation_delayed_reason",
                    format: data.installation_training === "no" ? undefined : "titleCase"
                },
                {
                    label: "Remarks",
                    key: "remarks"
                }
            ].filter(Boolean)}
            columnB={[
                data.installation_training === "yes" && {
                    key: "terminal_photo_back",
                    label: "Terminal Photo Back",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "terminal_photo_front",
                    label: "Terminal Photo Front",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "checklist_photo",
                    label: "Checklist Photo",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "outlet_photo",
                    label: "Outlet Photo",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "signboard_photo",
                    label: "Signboard Photo",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "transaction_photo",
                    label: "Transaction Photo",
                    format: "file",
                },
                data.installation_training === "yes" && {
                    key: "posm_photo",
                    label: "POSM Photo",
                    format: "file",
                },
            ].filter(Boolean)}
            columnC={[
                data.installation_training === "yes" && {
                    key: "completed_hours",
                    label: "Installation Time",
                    render: (value, row) => {
                        return <>{value} hours</>
                    }
                },
                data.installation_training === "yes" && {
                    key: "completed_sla",
                    label: "Within SLA Period",
                    format: "titleCase"
                },
            ].filter(Boolean)}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        {/* <Col col={{ xs: 24 }}>

                            <Button
                                label={"Instructions To Technician"}
                                loading={process.loading}
                                onClick={handleInstructionsToTechnician}
                            />

                        </Col> */}

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                name={"installation_training"}
                                required={true}
                                label={"Installation And Training Complete"}
                                defaultValue={form.defaults.installation_training}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" },
                                ]}
                            />

                        </Col>

                        {form.values.installation_training === "yes" && (
                            <>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge={true}>

                                        {(isStaff) &&
                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldRelationship
                                                    form={form}
                                                    name={"technician_id"}
                                                    required={true}
                                                    label={"Technician"}
                                                    model={'Users'}
                                                    preload={true}
                                                    where={{ roles: "technician", provider_id: data.provider_id }}
                                                    defaultValue={form.defaults.technician_id}
                                                />

                                            </Col>
                                        }

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldDate
                                                form={form}
                                                name={"active_date"}
                                                required={true}
                                                label={"Installation Date"}
                                                defaultValue={form.defaults.active_date || ""}
                                                disabledDate={isDateDisabled}
                                            />

                                        </Col>

                                    </Row>

                                    <Row gutter={8} edge>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Terminal Photo Back"}
                                                name={'terminal_photo_back'}
                                                required
                                                defaultValue={form.defaults.terminal_photo_back}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Terminal Photo Front"}
                                                name={'terminal_photo_front'}
                                                required
                                                defaultValue={form.defaults.terminal_photo_front}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Checklist Photo"}
                                                name={'checklist_photo'}
                                                required
                                                defaultValue={form.defaults.checklist_photo}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Outlet Photo"}
                                                name={'outlet_photo'}
                                                required
                                                defaultValue={form.defaults.outlet_photo}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Signboard Photo"}
                                                name={'signboard_photo'}
                                                required
                                                defaultValue={form.defaults.signboard_photo}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"Transaction Photo"}
                                                name={'transaction_photo'}
                                                required
                                                defaultValue={form.defaults.transaction_photo}
                                                limit={2}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 12 }}>

                                            <FieldUpload
                                                form={form}
                                                folder={'ops-media'}
                                                label={"POSM Photo"}
                                                name={'posm_photo'}
                                                required
                                                defaultValue={form.defaults.posm_photo}
                                                limit={2}
                                            />

                                        </Col>

                                    </Row>

                                </Col>

                            </>
                        )}

                        {(form.values.installation_training === "yes" || form.values.installation_training === "no") && (
                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldTextArea
                                    form={form}
                                    name={"remarks"}
                                    required={false}
                                    label={"Remarks"}
                                    defaultValue={form.defaults.remarks}
                                />

                            </Col>
                        )}




                    </Row>

                    {(form.values.installation_training === "failed") && (
                        <>
                            <ServiceStageAttemptFailed
                                data={data}
                                form={form}
                            />
                        </>
                    )}

                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default InstallationStageInstallationAndTraining;
