import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';

const BankAccountsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Bank Account Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Bank Name"}
                            name='bank_name'
                            required={true}
                            defaultValue={form.defaults.bank_name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldText
                            form={form}
                            label={"Account Name"}
                            name='account_name'
                            required={true}
                            defaultValue={form.defaults.account_name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 16 }}>

                        <FieldText
                            form={form}
                            label={"Account No"}
                            name='account_no'
                            required={true}
                            defaultValue={form.defaults.account_no}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default BankAccountsCreateEditForm;
