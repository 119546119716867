import { Alert, Col, Line, MetaTable, Row } from '@dex/bubl-dash';
import React from 'react';

const ServiceStageIncompleteMessages: React.FC<any> = (props: any) => {

    const { data } = props;

    const messages = data.check_messages || [];

    if (data.status !== "incomplete") return <></>;

    return (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 12 }}>

                    <Alert
                        type='warning'
                        message={"Incomplete Items"}
                        description={
                            <>
                                {messages.map((message, index) => (
                                    <p key={index}>- {message}</p>
                                ))}
                            </>
                        }
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: "check_remarks",
                                label: "Incomplete Remarks From PMA",
                                format: "nl2br",
                                hideIfEmpty: true
                            }
                        ]}
                    />

                </Col>

            </Row>

            <Line />

        </>

    );

}

export default ServiceStageIncompleteMessages;
