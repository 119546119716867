import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import ClaimsApi from '../ClaimsApi';
import ClaimsEditForm from './ClaimsEditForm';
import { Alert, Box } from '@dex/bubl-dash';

const ClaimsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = ClaimsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Claims Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Box
            gutter={'auto'}
        >

            {data.status !== "pending" &&
                <Alert
                    type="warning"
                    message={"Access Denied"}
                    description={"This claim has been " + data.status + " and can no longer be edited."}
                />
            }

            {data.status === "pending" &&
                <ClaimsEditForm
                    form={form}
                    data={data}
                    handleSubmit={handleSubmit}
                    process={update}
                />
            }
        </Box>

    ), [data, form.hash, update]);

}

export default ClaimsSingleEdit;
