import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import { FieldAutocomplete, FieldRelationship, FieldText } from '@dex/bubl-dash';
import ServicesApi from '../ServicesApi';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';

const ServiceStageTerminalPersonalization: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_terminal_personalization");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"terminal_personalization"}
            heading={"Terminal Personalization"}
            editCaps={["stage_terminal_personalizationServices"]}
            editable={false}
            showForm={data.terminal_personalization !== "yes"}
            showMeta={data.terminal_personalization === "yes"}
            passed={data.terminal_personalization === "yes"}
            showSave={form.changed}
            reverse={data.terminal_personalization === "yes" ? null : reverse}
            last_stage={"terminal_selected"}
            columnA={[
                {
                    label: "Software Version",
                    key: "software_version",
                },
            ]}
            columnB={[
                {
                    label: "OS Version",
                    key: "os_version",
                },
            ]}
            columnC={[
                {
                    label: "Checklist",
                    key: "terminal_personalization_checklist",
                    render: () => "Passed"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        label={"Software Version"}
                                        name='software_version'
                                        required={true}
                                        defaultValue={form.defaults.software_version}
                                        group='software_version'
                                        preload
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        label={"OS Version"}
                                        name='os_version'
                                        required={true}
                                        defaultValue={form.defaults.os_version}
                                        group='os_version'
                                        preload
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <FieldCheckbox
                                        form={form}
                                        name={"terminal_personalization_checklist"}
                                        label={"Checklist"}
                                        required={true}
                                        defaultValue={form.defaults.terminal_personalization_checklist}
                                        list
                                        options={[
                                            { value: "terminal", label: "Terminal" },
                                            { value: "download_software", label: "Download Software" },
                                            { value: "pma_sticker", label: "PMA Sticker" },
                                            { value: "check_mid", label: `Check MID: ${data.mid}` },
                                            { value: "check_tid", label: `Check TID: ${data.tid}` },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <MetaTable
                                columnSize={{ xs: 8 }}
                                data={data}
                                columnA={[
                                    {
                                        label: "Standard Applications",
                                        key: "standard_applications",
                                    },
                                ]}

                                columnB={[
                                    {
                                        label: "Optional Applications",
                                        key: "optional_applications",
                                    },
                                ]}

                                columnC={[

                                ]}

                            />

                            <Space height={"small"} />

                            <MerchantsMidTidView
                                data={data}
                                form={form}
                                passed={data.tests}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageTerminalPersonalization;
