import React, { useMemo } from 'react';
import MassRolloutStageDeliveryNote from './MassRolloutStageDeliveryNote';
import MassRolloutStageDelivery from './MassRolloutStageDelivery';

const MassRolloutStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <MassRolloutStageDeliveryNote
                data={data}
            />

            <MassRolloutStageDelivery
                data={data}
            />

        </>

    ), [data]);

}

export default MassRolloutStages;
