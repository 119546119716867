import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldSelect, FieldTable, InputTable } from '@dex/bubl-dash';

const MigrationsAutoRunForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const columns = [
        {
            label: "Migration",
        },
        {
            label: "Clear Ids",
        },
        {
            label: "Clear Records",
        },
        {
            label: "Truncate Model",
        },
        {
            label: "Fetch IDs",
        },
        {
            label: "Fetch Records",
        },
        {
            label: "Process Data",
        },
        {
            label: "Map Relations",
        },
    ]

    const list = [
        { value: 'helpdesk>HelpdeskTickets', label: 'Helpdesk > HelpdeskTickets' },
        { value: 'leasing>Leasing', label: 'Leasing > Leasing' },
        // { value: 'debtors>Debtors', label: 'Debtors > Debtors' },
        { value: 'acquirers>Acquirers', label: 'Acquirers > Acquirers' },
        { value: 'attachment>Media', label: 'Attachment > Media' },
        { value: 'agents>Agencies', label: 'Agents > Agencies' },
        { value: 'providers>Providers', label: 'Providers > Providers' },
        { value: 'terminals>Terminals', label: 'Terminals > Terminals' },
        { value: 'engagements>EngagementPlans', label: 'Engagements > Engagement Plans' },
        { value: 'merchants>Merchants', label: 'Merchants > Merchants' },
        { value: 'rollouts>MassRollouts', label: 'Rollouts > MassRollouts' },
        { value: 'replacements>Replacements', label: 'Replacements > Replacements' },
        // { value: 'certificates>Certificats', label: 'Certificates > Certificats' },
        { value: 'installations>Installations', label: 'Installations > Installations' },
        { value: 'invoice>Invoices', label: 'Invoice > Invoices' },
        { value: 'proforma>Proforma', label: 'Proforma > Proforma' },
        { value: 'service>Services', label: 'Service > Services' },
        // { value: 'learning>Learning', label: 'Learning > Learning' },
        { value: 'claims>Claims', label: 'Claims > Claims' },
        { value: 'users>Users', label: 'Users > Users' },
    ];

    return useMemo(() => (

        <Panel
            heading={"Migration Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >


                <InputTable
                    columns={columns}
                >


                </InputTable>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Run"}
                        loading={process.loading}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default MigrationsAutoRunForm;
