import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import React, { useMemo } from 'react';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';

const ServiceStageApproveAmendment: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_amendment_approved");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    let editCaps = ["manager", "admin", "super"];

    //requestor can approve their own change merchant details amendment
    if (data.amendment_type === "change_merchant_details") editCaps = ["stage_request_amendmentServices"];

    return useMemo(() => (
        <Stage
            data={data}
            form={form}
            process={process}
            reverse={data.amendment_approved === "yes" ? null : reverse}
            name={"amendment_approved"}
            heading={"Amendment Approval"}
            editable={false}
            editCaps={editCaps}
            viewCaps={["createServices"]}
            restrictedMessage={"Pending approval by Manager."}
            showForm={data.amendment_approved !== "yes"}
            showMeta={data.amendment_approved === "yes"}
            passed={data.amendment_approved === "yes"}
            showSave={form.changed}
            last_stage={"request_amendment"}
            columnA={[
                {
                    label: "Amendment Approved",
                    key: "amendment_approved",
                    format: "titleCase",
                },
                data.amendment_approved === "no" && {
                    label: "Reason",
                    key: "approval_notes",
                },
            ].filter(Boolean)}
            columnB={[]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Amendment Approved"}
                                name="amendment_approved"
                                required={true}
                                defaultValue={form.defaults.amendment_approved}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.amendment_approved === "no" && (

                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Reason"}
                                    name='approval_notes'
                                    required={true}
                                    defaultValue={form.defaults.approval_notes}
                                />

                            </Col>

                        )}

                    </Row>
                </>
            }
        />
    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageApproveAmendment;
