import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import React, { useCallback, useMemo, useState } from 'react';
import ClaimEntryServiceSetting from '../../ClaimsEntryServices/ClaimsEntryServicesSettings';
import ClaimsEntrySalesSettings from '../../ClaimsEntrySales/ClaimsEntrySalesSettings';
import { Button } from '@dex/bubl-dash';
import { date, nl2br, titleCase } from '@dex/bubl-helpers';
import { Parser } from 'json2csv';

const ClaimsEntryByTypeView: React.FC<any> = (props: any) => {

    const { data } = props;

    let ClaimsEntrySettings: any = {};

    const type = data.type;

    if (type === 'service') {
        ClaimsEntrySettings = ClaimEntryServiceSetting;
    } else {
        ClaimsEntrySettings = ClaimsEntrySalesSettings;
    }

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, user) => {

        let filters = ClaimsEntrySettings.indexFetchParams(routeName, params, user);

        filters.where.claim_id = data.id;
        filters.limit = 10000;

        return filters;

    }

    const fetch = ClaimsEntrySettings.indexFetch();

    const handleExport = useCallback(() => {

        const params = indexFetchParams(null, {}, null);

        fetch.run({
            params: params,
            onComplete: (response) => {

                const servicefields = [
                    { label: 'Request ID', value: 'ops_id' },
                    { label: 'Merchant ID', value: 'merchant_id' },
                    { label: 'Merchant Name', value: 'merchant_name' },
                    { label: 'Type', value: row => `${titleCase(row.type)}` },
                    { label: 'Technician', value: 'technician_name' },
                    { label: 'Service Time', value: row => `${row.service_time} hours` },
                    { label: 'Travel Distance', value: 'approved_mileage' },
                    { label: 'Acquirer', value: 'acquirer_name' },
                    { label: 'SLA', value: row => `${titleCase(row.sla)}` },
                    { label: 'Claim Status', value: row => `${titleCase(row.status)}` },
                    { label: 'Payout', value: row => `RM ${row.amount}` },
                    { label: 'Notes', value: row => `${nl2br(row.notes)}` }
                ];

                const salesfields = [
                    { label: 'Merchant ID', value: 'merchant_id' },
                    { label: 'Merchant Name', value: 'merchant_name' },
                    { label: 'Location Type', value: row => `${titleCase(row.merchant_location)}` },
                    { label: 'Engagement Plan', value: 'engagement_plan_name' },
                    { label: 'Date Installed', value: row => `${date(row.date_installed)}` },
                    { label: 'Notes', value: row => `${nl2br(row.notes)}` },
                    { label: 'Commission', value: row => `RM ${row.amount}` },
                ];

                let fields: any = ""

                if (data.type === "service") fields = servicefields;
                if (data.type === "sales") fields = salesfields;

                const opts = { fields };

                try {

                    const parser = new Parser(opts);
                    const csv = parser.parse(response.data);

                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');

                    link.setAttribute('href', url);
                    link.setAttribute('download', `${data.id}-${titleCase(data.type)}-${data.provider.company_name}.csv`);
                    link.click();

                } catch (err) {

                    console.error(err);

                }

            }
        });

    }, [data.id]);

    return useMemo(() => (

        <>

            <IndexTable
                title={"Summary"}
                hideTrashSwitch={true}
                getFetch={ClaimsEntrySettings.indexFetch}
                getColumns={ClaimsEntrySettings.indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
                limit={1000}
                actionButtons={() => (
                    <>
                        <Button
                            label={"Export"}
                            type={"light"}
                            size={'small'}
                            onClick={handleExport}
                        />
                    </>
                )}
            />

        </>

    ), [data, tableState]);
}

export default ClaimsEntryByTypeView