import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';
import { userHasCap } from '@dex/bubl-dash';

const UsersInfoPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    const isStaffProfile = data.caps.includes("staff");

    return useMemo(() => (

        <Panel
            heading={"User Info"}
        >

            <MetaTable
                data={data}
                columnA={[
                    {
                        label: "IC Number",
                        key: "ic_number",
                    },
                    !isStaffProfile && {
                        label: "Company Manager",
                        key: "manager",
                        format: "titleCase",
                    },
                    {
                        label: "Service Provider",
                        key: "providers",
                        format: "ModelLink",
                        hideIfEmpty: true,
                    },
                    data.agency_id && {
                        label: "Agency",
                        key: "agency",
                        format: "ModelLink",
                        hideIfEmpty: true,
                    },
                ].filter(Boolean)}
                columnB={[
                    {
                        label: "Bank Name",
                        key: "bank_name",
                    },
                    {
                        label: "Bank Account Name",
                        key: "account_name",
                    },
                    {
                        label: "Bank Account No.",
                        key: "account_number",
                    },

                ]}
                columnC={[

                    {
                        label: "Address",
                        key: "address",
                        format: "address",
                    },
                    {
                        label: "Additional Notes",
                        key: "notes",
                    },
                ]}
            />

        </Panel>

    ), [data]);

}

export default UsersInfoPanel;
