import React, { useMemo } from 'react';

import { Box, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import DashboardMenu from './DashboardMenu/DashboardMenu';
import DashboardStats from './DashboardStats/DashboardStats';
import { useUser, userHasCap } from '@dex/bubl-dash';

const Dashboard: React.FC<any> = (props: any) => {

    const isStaff = userHasCap(['staff']);
    const isCompanyManager = userHasCap(["companyManager"]);

    const user = useUser();

    return useMemo(() => (

        <Box
            windowHeight={true}
            gutter={"big"}
        >

            <DashboardMenu />

            <Space height={40} />

            {isStaff &&
                <DashboardStats />
            }

        </Box>

    ), [props])

}

export default Dashboard;