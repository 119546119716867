import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ReplacementsApi from '../ReplacementsApi';
import { FieldRadio, FieldTextArea } from '@dex/bubl-dash';

const ReplacementStageReviewReplacementRequest: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ReplacementsApi.update(data.id, "stage_review_replacement_request");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"review_replacement_request"}
            heading={"Review Replacement Request"}
            editable={false}
            showForm={data.request_status == null}
            showMeta={data.request_status}
            showSave={form.changed}
            passed={data.request_status === "approved"}
            columnA={[{
                label: "Review Status",
                key: "request_status",
                format: 'titleCase'
            },
            (data.request_status === "denied" || data.request_status === "cancelled") && {
                label: "Review Notes",
                key: "notes",
                format: 'titleCase'
            },]
                .filter(Boolean)}
            columnB={[]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Status"}
                                name='request_status'
                                required={true}
                                defaultValue={form.defaults.request_status}
                                options={[
                                    { label: "Approved", value: "approved" },
                                    { label: "Denied", value: "denied" },
                                    { label: "Cancelled", value: "cancelled" },
                                ]}
                            />

                        </Col>

                        {(form.values.request_status === "denied" || form.values.request_status === "cancelled") &&
                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Notes"}
                                    name='notes'
                                    required={false}
                                    defaultValue={form.defaults.notes}
                                />

                            </Col>}

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ReplacementStageReviewReplacementRequest;
