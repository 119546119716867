import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import ServiceStageAssignServiceProvider from './ServiceStageAssignServiceProvider';
import ServiceStageOutbound from './ServiceStageOutbound';
import ServiceStageProgramming from './ServiceStageProgramming';
import ServiceStageQualityControl from './ServiceStageQualityControl';
import ServiceStageRecordVerified from './ServiceStageRecordVerified';
import ServiceStageRecoverTerminal from './ServiceStageRecoverTerminal';
import ServiceStageRemoteDownload from './ServiceStageRemoteDownload';
import ServiceStageRequestAccepted from './ServiceStageRequestAccepted';
import ServiceStageDetailProgrammingOnsiteUpdate from './ServiceStageDetailProgrammingOnsiteUpdate';
import ServiceStageDetailProgrammingOnsiteSwap from './ServiceStageDetailProgrammingOnsiteSwap';
import ServiceStageTerminalPersonalization from './ServiceStageTerminalPersonalization';
import ServiceStageTerminalSelected from './ServiceStageTerminalSelected';
import ServiceStageUpdateDetails from './ServiceStageUpdateDetails';
import ServiceStageReviewFailed from './ServiceStageReviewFailed';
import ServiceStageCheckComplete from './ServiceStageCheckComplete';

const ServiceStagesProgramming: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ServiceStageProgramming
                data={data}
            />

            {data.programming === "yes" && (

                <ServiceStageUpdateDetails
                    data={data}
                />

            )}

            {data.mode_of_update === "remote_download" && (

                <>

                    <ServiceStageRemoteDownload
                        data={data}
                    />

                    {data.remote_download === "yes" && (

                        <ServiceStageRecordVerified
                            data={data}
                        />

                    )}

                </>
            )}

            {data.mode_of_update === "onsite_update" && (

                <>

                    <ServiceStageAssignServiceProvider
                        data={data}
                    />

                    {data.assign_service_provider === "yes" && (

                        <ServiceStageRequestAccepted
                            data={data}
                        />

                    )}

                    {data.request_accepted === "yes" && (
                        <>
                            {data.status !== "failed" && data.status !== "cancelled" && (

                                <ServiceStageDetailProgrammingOnsiteUpdate
                                    data={data}
                                />


                            )}

                            {(data.status === "failed") && (
                                <ServiceStageReviewFailed
                                    data={data}
                                />
                            )}

                            {data.service_complete === "yes" && (
                                <ServiceStageCheckComplete
                                    data={data}
                                />
                            )}

                            {data.service_complete === "yes" && (data.check_complete === "yes") && (

                                <ServiceStageRecordVerified
                                    data={data}
                                />

                            )}

                        </>
                    )}

                </>
            )}

            {data.mode_of_update === "onsite_swap" && (

                <>

                    <ServiceStageTerminalSelected
                        data={data}
                    />

                    {data.terminal_selected === "yes" && (

                        <ServiceStageTerminalPersonalization
                            data={data}
                        />

                    )}

                    {data.terminal_personalization === "yes" && (

                        <ServiceStageQualityControl
                            data={data}
                        />

                    )}

                    {data.quality_control === "yes" && (

                        <ServiceStageAssignServiceProvider
                            data={data}
                        />

                    )}

                    {data.assign_service_provider === "yes" && (

                        <ServiceStageOutbound
                            data={data}
                        />

                    )}

                    {data.outbound === "yes" && data.status !== "failed" && data.status !== "cancelled" && (

                        <ServiceStageDetailProgrammingOnsiteSwap
                            data={data}
                        />

                    )}

                    {(data.status === "failed") && (
                        <ServiceStageReviewFailed
                            data={data}
                        />
                    )}

                    {data.service_complete === "yes" && (
                        <ServiceStageCheckComplete
                            data={data}
                        />
                    )}

                    {data.service_complete === "yes" && (data.check_complete === "yes") && (

                        <ServiceStageRecoverTerminal
                            data={data}
                        />

                    )}

                    {data.recover_assets === "yes" && (data.check_complete === "yes") && (

                        <ServiceStageRecordVerified
                            data={data}
                        />

                    )}

                </>

            )}

        </>

    ), [data]);

}

export default ServiceStagesProgramming;
