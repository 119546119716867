import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ParamTemplatesApi from './ParamTemplatesApi';
import { wpOldLink } from '../../functions/wpOldLink';


let settings;

export default settings = {
    key: 'param-templates',
    zone: 'lists',
    path: '/param-templates',
    title: "Param Templates",
    endpoint: ParamTemplatesApi.endpoint,
    modelName: 'ParamTemplates',
    idKey: 'id',
    primaryKey: 'name',
    indexCaps: ["viewParamTemplates"],
    viewCaps: ["viewParamTemplates"],
    createCaps: ["createParamTemplates"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ParamTemplatesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'acquirer',
                    preset: "link"
                }
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['name', 'device_type'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Template Name",
                key: "name",
                type: "text",
            },
            {
                label: "Acquirer",
                key: "acquirer_id",
                type: "relationship",
                model: "Acquirers"
            },
            {
                label: "Device Type",
                key: "device_type",
                type: "choice",
                options: [
                    { value: "desktop", label: "Desktop" },
                    { value: "wireless", label: "Wireless" },
                    { value: "mpos", label: "mPOS" },
                    { value: "android", label: "Android" }
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Template Name",
                key: "this",
                format: "ModelLink",
                sort: 'this'
            },
            {
                label: "Acquirer",
                key: "acquirer",
                format: "ModelLink",
            },
            {
                label: "Device Type",
                key: "device_type",
                format: "titleCase"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ParamTemplatesApi,
                settings: settings,
            },
        ];

        return columns;

    },
    singleFetch: ParamTemplatesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'acquirer',
                    preset: "link"
                }
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },
};