import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Dashboard';

const DashboardApi: baseApiReturn = baseApi(endpoint);

export default DashboardApi;

DashboardApi.getStats = (options: options): response => {

    return apiGet({
        "url": endpoint + "/getStats",
        ...options
    });

}