import ProformaInvoicesCreate from "./ProformaInvoicesCreate";
import ProformaInvoicesIndex from "./ProformaInvoicesIndex";
import ProformaInvoicesSettings from "./ProformaInvoicesSettings";
import ProformaInvoicesSingle from "./ProformaInvoicesSingle";


export default function (app) {

    const settings = ProformaInvoicesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 10,
        items: [
            {
                label: "All Proforma Invoices",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    ProformaInvoicesIndex(app, settings);

    ProformaInvoicesCreate(app, settings);

    ProformaInvoicesSingle(app, settings);

}