import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const InventoryLink: React.FC<InventoryLinkProps> = (props) => {

    const { id } = props;

    return (

        <>
            {(id) ?
                <ModelLink data={{ id: id }} modelName="Inventories" />
                :
                <>-</>
            }
        </>

    )

}

interface InventoryLinkProps {
    id: any,
    [key: string]: any,
}

export default InventoryLink;