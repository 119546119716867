import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Space } from '@dex/bubl-dash';

const LeasingSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Leasing Company Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            },

                        ]}
                        columnC={[
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },
                        ]}
                    />

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                </Panel>

            </Col>

        </Row>

    ), [data]);

}

export default LeasingSingleView;
