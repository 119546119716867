import React, { useCallback } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldDate, FieldRadio, FieldText, userHasCap, FieldUpload, FieldRelationship, useMountEffect } from '@dex/bubl-dash';
import { validateDatePassed } from '../../../functions/sharedValidation';
import { dateDay } from '@dex/bubl-helpers';
import moment from 'moment';

const ServiceStageAttemptFailed: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const isTechnician = userHasCap(["technician"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    useMountEffect(() => {

        if (form.values.attempt_date) return;

        form.handleChange({ name: "attempt_date", value: dateDay(moment()), reset: true, silent: true });

    })

    return (

        <>
            <Row gutter={8} edge={true}>

                {!isTechnician &&
                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRelationship
                            form={form}
                            label="Technician"
                            name="technician_id"
                            required={true}
                            model={"Users"}
                            preload={true}
                            where={{ roles: "technician", provider_id: data.provider_id }}
                            defaultValue={form.defaults.technician_id}
                        />

                    </Col>
                }

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldDate
                        key={form.defaults.attempt_date}
                        form={form}
                        label={"Attempt Date"}
                        name='attempt_date'
                        required={true}
                        defaultValue={form.defaults.attempt_date}
                        disabledDate={isDateDisabled}
                    />

                </Col>

            </Row>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 8 }}>

                    <FieldRadio
                        form={form}
                        label={"Reason Failed"}
                        name='reason_failed'
                        required={true}
                        defaultValue={form.defaults.reason_failed}
                        list
                        options={[
                            { label: "Merchant Not In", value: "Merchant Not In" },
                            { label: "Merchant Address Wrong", value: "Merchant Address Wrong" },
                            { label: "Merchant Refused To Accept", value: "Merchant Refused To Accept" },
                            { label: "Network Unavailable", value: "Network Unavailable" },
                            { label: "Device Faulty", value: "Device Faulty" },
                            { label: "Other", value: "Other" },
                        ]}
                    />

                    {form.values.reason_failed === "Other" &&
                        <>
                            <FieldText
                                form={form}
                                name='reason_failed_other'
                                required={true}
                                defaultValue={form.defaults.reason_failed_other}
                            />
                        </>
                    }

                </Col>

                <Col col={{ xs: 24, md: 8 }}>
                    <FieldUpload
                        form={form}
                        label={"Supporting Documents"}
                        name="supporting_documents"
                        defaultValue={form.defaults.supporting_documents}
                        folder={"uploads"}
                        limit={3}
                    />
                </Col>

            </Row>

        </>

    );

}

export default ServiceStageAttemptFailed;
