import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio, FieldRelationship, FieldSelect, Line, FieldUpload, Space, FieldDate } from '@dex/bubl-dash';
import { userHasCap } from '../../../functions/useUser';
import ServiceStageAttemptFailed from './ServiceStageAttemptFailed';
import { validateDatePassed } from '../../../functions/sharedValidation';
import ServiceStageIncompleteMessages from './ServiceStageIncompleteMessages';
import { titleCase } from '@dex/bubl-helpers';

const ServiceStageDetailFaultCall: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_service_detail_fault_call",);

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "complete";

    const isStaff = userHasCap(["staff"]);
    const isTechnician = userHasCap(["technician"]);

    const isDateDisabled = useCallback((date) => {

        return validateDatePassed(date, isTechnician);

    }, []);

    let where: any = {
        status: "in_stock",
        type: data.device_type,
        provider_id: form.values.provider_id,
    };

    let whereLabel = titleCase(data.device_type) + " - Technician Stock";

    if (data.business_model === "maintenance") {

        whereLabel += "/Mass Rollout Delivered";

        where = {
            or: [
                {
                    status: 'delivered',
                    acquirer_id: data.acquirer_id,
                    type: data.device_type,
                },
                {
                    status: "in_stock",
                    type: data.device_type,
                    provider_id: form.values.provider_id,
                }
            ]
        }
    }

    return useMemo(() => (
        <Stage
            data={data}
            form={form}
            process={process}
            name={"service_complete"}
            heading={"Service Detail (Fault Call)"}
            editable={false}
            showForm={data.service_complete !== "yes"}
            showMeta={data.service_complete}
            passed={data.service_complete === "yes"}
            editCaps={["stage_service_detail_fault_callServices"]}
            showSave={form.changed}
            reverse={data.service_complete === "yes" && isStaff ? null : reverse}
            last_stage={"accepted_service_request"}
            renderMeta={() => (
                <>
                    <ServiceStageIncompleteMessages
                        data={data}
                    />
                </>
            )}
            columnA={[
                {
                    label: "Service Complete",
                    key: "service_complete",
                    format: "titleCase",
                },
                data.service_complete === "yes" && {
                    label: "Technician",
                    key: "technician",
                    format: "modelLink",
                },
                data.service_complete === "yes" && {
                    label: "Service Date",
                    key: "service_date",
                    format: "date"
                },
                data.outbound !== "yes" && {
                    label: "Terminal Swapped",
                    key: "terminal_swapped",
                    format: "titleCase",
                },
                data.terminal_swapped === "yes" && {
                    label: "New Serial Number",
                    key: "new_serial",
                    render: (value, row) => (
                        row.new_serial || row.serial || "-"
                    )
                },
                data.outbound !== "yes" && {
                    label: "Power Adapter Swapped",
                    key: "adapter_swapped",
                    format: "titleCase",
                },
                data.adapter_swapped === "yes" && {
                    key: "adapter_type",
                    label: "Power Adapter Type",
                    format: "intl",
                    prefix: "service.adapter_type",
                },

            ].filter(Boolean)}
            columnB={[
                data.outbound !== "yes" && {
                    label: "Sim Card Swapped",
                    key: "sim_card_swapped",
                    format: "titleCase",
                },
                data.sim_card_swapped === "yes" && {
                    label: "New SIM Card No",
                    key: "sim_card_id",
                },
                data.sim_card_swapped === "yes" && {
                    label: "Telco Network",
                    key: "telco_network",
                    format: "intl",
                    prefix: "service.telco_network",
                },
                data.outbound !== "yes" && {
                    label: "Swivel Swapped",
                    key: "swivel_swapped",
                    format: "titleCase",
                },
                data.swivel_swapped === "yes" && {
                    label: "New Swivel Serial",
                    key: "swivel_serial",
                },

                data.outbound !== "yes" && {
                    label: "Router Swapped",
                    key: "router_swapped",
                    format: "titleCase",
                },
                data.router_swapped === "yes" && {
                    label: "New Router Serial",
                    key: "router_serial",
                },

                data.outbound !== "yes" && {
                    label: "Docking Swapped",
                    key: "docking_swapped",
                    format: "titleCase",
                },
                data.docking_swapped === "yes" && {
                    label: "New Docking Serial",
                    key: "docking_serial",
                },


            ].filter(Boolean)}
            columnC={[
                data.outbound !== "yes" && {
                    label: "Fault",
                    key: "fault",
                    format: "titleCase",
                },
                {
                    label: "Service Report Photo",
                    key: "service_photo",
                    format: "file",
                },
                {
                    label: "Terminal Photo Back",
                    key: "terminal_photo_back",
                    format: "file",
                },
                {
                    label: "Terminal Photo Front",
                    key: "terminal_photo_front",
                    format: "file",
                },

            ].filter(Boolean)}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                label={"Service Complete"}
                                name="service_complete"
                                required={true}
                                defaultValue={
                                    form.defaults.service_complete
                                }
                                options={[
                                    { label: "Yes", value: "yes" },
                                    // { label: "No", value: "no" },
                                    { label: "Failed", value: "failed" }
                                ]}
                            />

                        </Col>

                        {form.values.service_complete === "yes" &&
                            <>

                                <Col col={{ xs: 24, md: 8 }}>
                                </Col>
                                {!isTechnician &&
                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            form={form}
                                            label="Technician"
                                            name="technician_id"
                                            required={true}
                                            model={"Users"}
                                            preload={true}
                                            where={{ roles: "technician", provider_id: data.provider_id }}
                                            defaultValue={form.defaults.technician_id}
                                        />

                                    </Col>
                                }
                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldDate
                                        form={form}
                                        name={"service_date"}
                                        required={true}
                                        label={"Service Date"}
                                        defaultValue={form.defaults.service_date || ""}
                                        disabledDate={isDateDisabled}
                                    />

                                </Col>
                            </>
                        }

                    </Row>

                    {form.values.service_complete === "failed" &&
                        <>
                            <ServiceStageAttemptFailed
                                form={form}
                                data={data}
                            />
                        </>
                    }

                    {form.values.service_complete === "yes" && (
                        <>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Terminal Swapped"}
                                        name="terminal_swapped"
                                        required={true}
                                        defaultValue={form.defaults.terminal_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.terminal_swapped === "yes" && (

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRelationship
                                            key={form.values.technician_id}
                                            form={form}
                                            label={'Serial Number (' + whereLabel + ')'}
                                            name={'terminal_id'}
                                            required={true}
                                            model={'Terminals'}
                                            preload={true}
                                            defaultValue={data.terminal_swapped === "yes" ? form.defaults.terminal_id : ""}
                                            where={where}
                                            format={["serial", "status"]}
                                        />

                                    </Col>

                                )}

                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Sim Card Swapped"}
                                        name="sim_card_swapped"
                                        required={true}
                                        defaultValue={form.defaults.sim_card_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.sim_card_swapped === "yes" && (
                                    <>
                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"SIM Card Serial No."}
                                                name='sim_card_id'
                                                required={true}
                                                defaultValue={data.sim_card_swapped === "yes" ? form.defaults.sim_card_id : ""}
                                                endpoint='Inventories'
                                                preload
                                                where={{ type: "sim", status: 'in_stock', provider_id: data.provider_id }}
                                            />

                                        </Col>

                                        <Col col={{ xs: 24, md: 8 }}>
                                            <FieldSelect
                                                form={form}
                                                label={"Telco Network"}
                                                name="telco_network"
                                                required={true}
                                                defaultValue={form.defaults.telco_network}
                                                options={[
                                                    {
                                                        value: "maxis",
                                                        label: "Maxis",
                                                    },
                                                    {
                                                        value: "digi",
                                                        label: "Digi",
                                                    },
                                                    {
                                                        value: "digi-ultra",
                                                        label: "Digi-Ultra",
                                                    },
                                                    {
                                                        value: "celcom",
                                                        label: "Celcom",
                                                    },
                                                    {
                                                        value: "myevo",
                                                        label: "MyEvo",
                                                    },
                                                    {
                                                        value: "myevo-multisim",
                                                        label: "MyEvo - Multi Sim",
                                                    },
                                                    {
                                                        value: "tns",
                                                        label: "TNS",
                                                    },
                                                    {
                                                        value: "umobile",
                                                        label: "Umobile",
                                                    },
                                                ]}
                                            />
                                        </Col>
                                    </>
                                )}
                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Swivel Swapped"}
                                        name="swivel_swapped"
                                        required={true}
                                        defaultValue={form.defaults.swivel_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.swivel_swapped === "yes" && (
                                    <>
                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Swivel Serial No."}
                                                name='swivel_id'
                                                required={true}
                                                defaultValue={data.swivel_swapped === "yes" ? form.defaults.swivel_id : ""}
                                                endpoint='Inventories'
                                                preload
                                                where={{ type: "swivel", status: 'in_stock', provider_id: data.provider_id }}
                                            />

                                        </Col>

                                    </>
                                )}
                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Router Swapped"}
                                        name="router_swapped"
                                        required={true}
                                        defaultValue={form.defaults.router_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.router_swapped === "yes" && (
                                    <>
                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Router Serial No."}
                                                name='router_id'
                                                required={true}
                                                defaultValue={data.router_swapped === "yes" ? form.defaults.router_id : ""}
                                                endpoint='Inventories'
                                                preload
                                                where={{ type: "router", status: 'in_stock', provider_id: data.provider_id }}
                                            />

                                        </Col>

                                    </>
                                )}

                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Docking Swapped"}
                                        name="docking_swapped"
                                        required={true}
                                        defaultValue={form.defaults.docking_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.docking_swapped === "yes" && (
                                    <>
                                        <Col col={{ xs: 24, md: 8 }}>

                                            <FieldRelationship
                                                form={form}
                                                label={"Docking Serial No."}
                                                name='docking_id'
                                                required={true}
                                                defaultValue={data.docking_swapped === "yes" ? form.defaults.docking_id : ""}
                                                endpoint='Inventories'
                                                preload
                                                where={{ type: "docking", status: 'in_stock', provider_id: data.provider_id }}
                                            />

                                        </Col>

                                    </>
                                )}

                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Power Adapter Swapped"}
                                        name="adapter_swapped"
                                        required={true}
                                        defaultValue={form.defaults.adapter_swapped}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "yes",
                                            },
                                            {
                                                label: "No",
                                                value: "no",
                                            },
                                        ]}
                                    />

                                </Col>

                                {form.values.adapter_swapped === "yes" && (

                                    <Col col={{ xs: 24, md: 8 }}>

                                        <FieldRadio
                                            button
                                            form={form}
                                            label={"Power Adapter Type"}
                                            name='adapter_type'
                                            defaultValue={data.adapter_swapped === "yes" ? form.defaults.adapter_type : ""}
                                            required
                                            options={[
                                                { label: "Original", value: "original" },
                                                { label: "Third Party", value: "third_party" },
                                            ]}
                                        />

                                    </Col>

                                )}

                            </Row>

                            <Line top={"small"} bottom={"small"} />

                            <Row Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldRadio
                                        form={form}
                                        label={"Fault"}
                                        name="fault"
                                        required={true}
                                        defaultValue={form.defaults.fault}
                                        list
                                        options={[
                                            {
                                                label: "Power Adapter",
                                                value: "Power Adapter",
                                            },
                                            {
                                                label: "Three / Two Pin Plug Cable",
                                                value: "Three / Two Pin Plug Cable",
                                            },
                                            {
                                                label: "Phone Line Cable",
                                                value: "Phone Line Cable",
                                            },
                                            {
                                                label: "No Line / No Dial Tone",
                                                value: "No Line / No Dial Tone",
                                            },
                                            {
                                                label: "Terminal No Power",
                                                value: "Terminal No Power",
                                            },
                                            {
                                                label: "Keypad Not Function / Stuck / Ugly",
                                                value: "Keypad Not Function / Stuck / Ugly",
                                            },
                                            {
                                                label: "Chip Malfunction",
                                                value: "Chip Malfunction",
                                            },
                                            {
                                                label: "LCD display Black Mark / Missing Line / No Backlight",
                                                value: "LCD display Black Mark / Missing Line / No Backlight",
                                            },
                                            {
                                                label: "Terminal Can't Charge The Battery",
                                                value: "Terminal Can't Charge The Battery",
                                            },
                                            {
                                                label: "Can't Read SIM / SAM",
                                                value: "Can't Read SIM / SAM",
                                            },
                                            {
                                                label: "No Signal Bar (Wireless)",
                                                value: "No Signal Bar (Wireless)",
                                            },
                                            {
                                                label: "Touchscreen Not Function",
                                                value: "Touchscreen Not Function",
                                            },
                                            {
                                                label: "Printing Not Clear / Can't Printing",
                                                value: "Printing Not Clear / Can't Printing",
                                            },
                                            {
                                                label: "Printer Roller Missing",
                                                value: "Printer Roller Missing",
                                            },
                                            {
                                                label: "Printer Cover Broken",
                                                value: "Printer Cover Broken",
                                            },
                                            {
                                                label: "Cover SAM / SIM Missing",
                                                value: "Cover SAM / SIM Missing",
                                            },
                                            {
                                                label: "Body Cover Crack",
                                                value: "Body Cover Crack",
                                            },
                                            {
                                                label: "Display Cover Crack / Broken",
                                                value: "Display Cover Crack / Broken",
                                            },
                                            {
                                                label: "Printing Missing Line",
                                                value: "Printing Missing Line",
                                            },
                                            {
                                                label: "No or Weak 3G Signal",
                                                value: "No or Weak 3G Signal",
                                            },
                                            {
                                                label: "QR Scanner Issue",
                                                value: "QR Scanner Issue",
                                            },
                                            {
                                                label: "3G Moderm Issue",
                                                value: "3G Moderm Issue",
                                            },
                                            {
                                                label: "Terminal Time/Date not accurate",
                                                value: "Terminal Time/Date not accurate",
                                            },
                                            {
                                                label: "Merchant update address",
                                                value: "Merchant update address",
                                            },
                                            {
                                                label: "Update Software (certain error appear)",
                                                value: "Update Software (certain error appear)",
                                            },
                                            {
                                                label: "Auto Settlement Issue",
                                                value: "Auto Settlement Issue",
                                            },
                                            {
                                                label: "WiFi Terminal unable to scan WiFi",
                                                value: "WiFi Terminal unable to scan WiFi",
                                            },
                                            {
                                                label: "PSTN line issue",
                                                value: "PSTN line issue",
                                            },
                                            {
                                                label: "Charging issue",
                                                value: "Charging issue",
                                            },
                                            {
                                                label: "Contact card reader issue",
                                                value: "Contact card reader issue",
                                            },
                                            {
                                                label: "Tampered",
                                                value: "Tampered",
                                            },
                                            {
                                                label: "Blank Screen",
                                                value: "Blank Screen",
                                            },
                                            {
                                                label: "Terminal Hang",
                                                value: "Terminal Hang",
                                            },
                                            {
                                                label: "LCD Broken",
                                                value: "LCD Broken",
                                            },
                                            {
                                                label: "Merchant own network issue",
                                                value: "Merchant own network issue",
                                            },
                                            {
                                                label: "Power Plug Damage",
                                                value: "Power Plug Damage",
                                            },
                                            {
                                                label: "None Of The Above",
                                                value: "None Of The Above",
                                            },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Service Report Photo"}
                                        name={'service_photo'}
                                        required
                                        defaultValue={form.defaults.service_photo}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Back"}
                                        name={'terminal_photo_back'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_back}
                                        limit={2}
                                    />

                                    <Space height={"small"} />

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Terminal Photo Front"}
                                        name={'terminal_photo_front'}
                                        required
                                        defaultValue={form.defaults.terminal_photo_front}
                                        limit={2}
                                    />

                                </Col>

                            </Row>

                        </>

                    )}

                </>

            }
        />
    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageDetailFaultCall;