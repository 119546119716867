import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Panel, useApp, } from '@dex/bubl-dash';
import ServicesApi from '../ServicesApi';
import ServicesImportCheckForm from './ServicesImportCheckForm';
import ServicesImportValidateForm from './ServicesImportValidateForm';
import ServicesImportReviewForm from './ServicesImportReviewForm';
import { get } from '@dex/bubl-helpers';

const ServicesBatchUploadForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;
    const [step, setStep] = useState("check");

    const service = ServicesApi.create({ timeout: 30000 }, 'batch_upload_check');

    const app = useApp();

    const [nav] = app.store('nav');

    const type = get(nav, 'currentRoute.state.params.type', '');

    const handleCheck = useCallback((values) => {

        service.run({
            data: values,
            timeout: 360000,
        })

    }, []);

    // eslint-disable-next-line
    let data = props.data || {};

    console.log(data)

    useEffect(() => {

        let step = "check";

        if (service.data) step = "validate";
        if (process.data) step = "review";

        setStep(step);

    }, [service.loading, process.loading])

    const handleReset = useCallback(() => {

        form.reset();
        process.reset();
        service.reset();

        setStep("check");

    }, []);

    return useMemo(() => (

        <Panel
            heading={"Batch Upload - " + app.intl("service.type." + type)}
        >

            {step === "check" && (

                <ServicesImportCheckForm
                    type={type}
                    form={form}
                    process={service}
                    handleSubmit={handleCheck}
                    handleReset={handleReset}
                />

            )}

            {step === "validate" && service.data?.list?.length && (

                <ServicesImportValidateForm
                    type={type}
                    form={form}
                    services={service.data}
                    process={process}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />

            )}

            {step === "review" && (

                <ServicesImportReviewForm
                    type={type}
                    services={process.data}
                    handleReset={handleReset}
                />

            )}

        </Panel >

    ), [form.hash, step, service.loading, process.loading]);

}

export default ServicesBatchUploadForm;
