import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';

const ServiceStageQualityControl: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_quality_control");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"quality_control"}
            heading={"Quality Control"}
            editCaps={["stage_quality_controlServices"]}
            editable={false}
            showForm={data.quality_control !== "yes"}
            showMeta={data.quality_control === "yes"}
            passed={data.quality_control === "yes"}
            showSave={form.changed}
            reverse={data.quality_control === "yes" ? null : reverse}
            last_stage={"terminal_personalization"}
            columnA={[
                {
                    label: "Checklist",
                    key: "quality_control_checklist",
                    render: () => "Passed"
                },
            ]}
            columnB={[
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldCheckbox
                                form={form}
                                name={"quality_control_checklist"}
                                label={"Checklist"}
                                required={true}
                                defaultValue={form.defaults.quality_control_checklist}
                                list
                                options={[
                                    { value: "terminal", label: "Terminal" },
                                    { value: "download_software", label: "Download Software" },
                                    { value: "pma_sticker", label: "PMA Sticker" },
                                    { value: "check_mid", label: `Check MID: ${data.mid}` },
                                    { value: "check_tid", label: `Check TID: ${data.tid}` },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <MetaTable
                                columnSize={{ xs: 8 }}
                                data={data}
                                columnA={[
                                    {
                                        label: "Standard Applications",
                                        key: "standard_applications",
                                    },
                                ]}

                                columnB={[
                                    {
                                        label: "Optional Applications",
                                        key: "optional_applications",
                                    },
                                ]}

                                columnC={[

                                ]}

                            />

                            <Space height={"small"} />

                            <MerchantsMidTidView
                                data={data}
                                passed={data.tests}
                                form={form}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageQualityControl;
