import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { FieldRadio } from '@dex/bubl-dash';

const InstallationStageDocumentsReturned: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_documents_returned");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"documents_returned"}
            heading={"Documents Returned"}
            editable={false}
            editCaps={["stage_documents_returnedInstallations"]}
            showForm={data.documents_returned !== "yes"}
            showMeta={data.documents_returned === "yes"}
            passed={data.documents_returned === "yes"}
            showSave={form.values.documents_returned === "yes"}
            columnA={[
                {
                    key: "documents_returned",
                    label: "Documents Returned",
                    format: "titleCase"
                },
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                label={"Documents Returned"}
                                name='documents_returned'
                                required={true}
                                defaultValue={form.defaults.documents_returned}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" }
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default InstallationStageDocumentsReturned;
