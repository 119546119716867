import MigrationDataCreate from "./MigrationDataCreate";
import MigrationDataIndex from "./MigrationDataIndex";
import MigrationDataSettings from "./MigrationDataSettings";
import MigrationDataSingle from "./MigrationDataSingle";


export default function (app) {

    const settings = MigrationDataSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 13,
        items: [
            {
                label: "All Migration Data",
                path: settings.path,
                caps: settings.indexCaps,
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.indexCaps,
            },
        ],
    });

    MigrationDataIndex(app, settings);

    MigrationDataCreate(app, settings);

    MigrationDataSingle(app, settings);

}