import InstallationsCreate from "./InstallationsCreate";
import InstallationsIndex from "./InstallationsIndex";
import InstallationsSettings from "./InstallationsSettings";
import InstallationsSingle from "./InstallationsSingle";

export default function (app) {

    const settings = InstallationsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    InstallationsIndex(app, settings);

    InstallationsCreate(app, settings);

    InstallationsSingle(app, settings);

}