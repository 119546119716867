import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import ServicesSingleView from "./Components/ServicesSingleView";
import ServicesSingleEdit from "./Components/ServicesSingleEdit";
import ServicesSingleCancel from './Components/ServicesSingleCancel';
import ServicesActivity from './Components/ServicesActivity';
import ActivityPage from '@dex/bubl-dash/src/lib/pages/ActivityPage';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: ServicesSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Activity",
        zone: settings.zone,
        path: settings.key + "/:id/activity",
        caps: settings.viewCaps,
        component: ActivityPage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
        },
    });

    app.addRoute({
        name: settings.key + "Cancel",
        zone: settings.zone,
        path: settings.key + "/:id/cancel",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: ServicesSingleCancel,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: ServicesSingleEdit,
        },
    });

}