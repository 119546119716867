import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import React, { useState } from 'react';
import BankAccountsApi from '../../views/BankAccounts/BankAccountsApi';
import { useMountEffect } from '@dex/bubl-dash';
import { loop } from '@dex/bubl-helpers';

const FieldBankAccounts: React.FC<FieldBankAccountsProps> = (props) => {

    const { state, ...rest } = props;

    const [options, setOptions] = useState<any>([]);

    const accounts = BankAccountsApi.get();

    useMountEffect(() => {

        accounts.run({
            params: { limit: 1000, order: ["created.date DESC"] },
            cacheKey: "BankAccounts",
            cacheTTL: 300000, // 30 minutes
            onComplete: (response) => {

                const options: any = [];

                loop(response.data, (item, key) => {

                    const value = item.bank_name + " " + item.account_no;
                    const label = item.bank_name + " " + item.account_no;

                    options.push({ value: value, label: label });

                });

                setOptions(options);

            }
        })

    });

    return (

        <>

            {!accounts.loading &&

                <FieldSelect
                    {...rest}
                    options={options}
                />

            }

        </>

    )

}

interface FieldBankAccountsProps {
    [key: string]: any,
}

export default FieldBankAccounts;