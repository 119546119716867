import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import InventoriesSingleSingleView from "./Components/InventoriesSingleView";
import InventoriesSingleSingleEdit from "./Components/InventoriesSingleEdit";
import InventoriesSingleSingleTransfer from './Components/InventoriesSingleTransfer';
import ActivityPage from '@dex/bubl-dash/src/lib/pages/ActivityPage';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: InventoriesSingleSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: InventoriesSingleSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Transfer",
        zone: settings.zone,
        path: settings.key + "/:id/transfer",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: InventoriesSingleSingleTransfer,
        },
    });

    app.addRoute({
        name: settings.key + "Activity",
        zone: settings.zone,
        path: settings.key + "/:id/activity",
        caps: settings.viewCaps,
        component: ActivityPage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
        },
    });
}