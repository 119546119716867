import ServicesCreate from "./ServicesCreate";
import ServicesIndex from "./ServicesIndex";
import ServicesSettings from "./ServicesSettings";
import ServicesSingle from "./ServicesSingle";


export default function (app) {

    const settings = ServicesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    // app.addMenu({
    //     key: settings.key,
    //     zone: settings.zone,
    //     label: settings.title,
    //     addPath: settings.path + "/create",
    //     addCaps: settings.createCaps,
    //     priority: 13,
    //     items: [
    //         {
    //             label: "All Services",
    //             path: settings.path,
    //             caps: settings.indexCaps,
    //         },
    //         {
    //             label: "Fault Call",
    //             path: settings.path + "/type/fault_call",
    //             caps: settings.indexCaps,
    //         },
    //         {
    //             label: "Retrieval",
    //             path: settings.path + "/type/retrieval",
    //             caps: settings.indexCaps,
    //         },
    //         {
    //             label: "Paper Roll",
    //             path: settings.path + "/type/paper_roll",
    //             caps: settings.createCaps,
    //         },
    //         {
    //             label: "Programming",
    //             path: settings.path + "/type/programming",
    //             caps: settings.indexCaps,
    //         },
    //         {
    //             label: "Training",
    //             path: settings.path + "/type/training",
    //             caps: settings.indexCaps,
    //         },
    //         {
    //             label: "Maintenance",
    //             path: settings.path + "/type/maintenance",
    //             caps: settings.createCaps,
    //         },
    //         {
    //             label: "Amendment",
    //             path: settings.path + "/type/amendment",
    //             caps: settings.createCaps,
    //         },
    //         {
    //             label: "Annual Visitation",
    //             path: settings.path + "/type/annual_visitation",
    //             caps: settings.createCaps,
    //         },
    //         {
    //             label: "Swap Plan",
    //             path: settings.path + "/type/swap_plan",
    //             caps: settings.indexCaps,
    //         },
    //     ],
    // });

    ServicesIndex(app, settings);

    ServicesCreate(app, settings);

    ServicesSingle(app, settings);

}