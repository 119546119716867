import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import ProformaInvoicesApi from '../ProformaInvoicesApi';
import Stage from '../../../elements/Stage/Stage';
import { Alert, FieldCheckbox, FieldRadio, FieldTextArea, ModelLink, useApp } from '@dex/bubl-dash';
import { formatNum } from '@dex/bubl-helpers';

const ProformaInvoicesStagesGenerateInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ProformaInvoicesApi.update(data.id, "stage_generate_invoice");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"generate_invoice"}
            heading={"Convert To Tax Invoice"}
            editCaps={["stage_generate_invoiceProformaInvoices"]}
            editable={false}
            showForm={data.status === "due" || data.status === "approved"}
            showMeta={data.status !== "due" && data.status !== "approved"}
            showSave={form.changed}
            passed={data.status !== "due" && data.status !== "approved"}
            columnA={[

                {
                    key: "invoice_id",
                    label: "Invoice No.",
                    render: (value, row) => (
                        <ModelLink data={{ id: value }} modelName="Invoices" />
                    )
                }

            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"generate_invoice"}
                                label={"Generate Tax Invoice"}
                                required={true}
                                defaultValue={form.defaults.generate_invoice}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>
                        {form.values.generate_invoice === "yes" &&
                            <Col col={{ xs: 24, md: 16 }}>

                                <Alert
                                    type='info'
                                    message='Generate Tax Invoice'
                                    description={"A tax invoice for RM " + formatNum(data.total) + " will be generated on save."}
                                />

                            </Col>
                        }

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ProformaInvoicesStagesGenerateInvoice;
