import React, { useCallback, useMemo, useState } from "react";
import styles from "./UpdateStatusPanel.module.scss";
import { Button, Col, FieldSelect, FieldUpload, Form, Line, Panel, Row, useApp, useForm } from "@dex/bubl-dash";
import HelpdeskTicketsApi from "../../views/HelpdeskTickets/HelpdeskTicketsApi";
import FieldTextArea from "@dex/bubl-dash/src/lib/components/Fields/FieldTextArea";

const UpdateStatusPanel: React.FC<UpdateStatusPanelProps> = (props) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({ status: data.status, priority: data.priority });

    const update = HelpdeskTicketsApi.update(data.id, "updateConversation", {
        onComplete: (data) => {

            app.alert("Ticket Saved", "success");
            app.navigate(nav, 'update', {});

        },
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Add Response"}
        >

            <Form form={form} onSubmit={handleSubmit} loading={update.loading}>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 16 }}>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Status"}
                                    name="status"
                                    required={true}
                                    defaultValue={form.defaults.status}
                                    options={[
                                        { label: "Open", value: "open" },
                                        { label: "Resolved", value: "resolved" },
                                        { label: "Unresolved", value: "unresolved" },
                                        { label: "Closed", value: "closed" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Priority"}
                                    name="priority"
                                    required={true}
                                    defaultValue={form.defaults.priority}
                                    options={[
                                        { label: "Normal", value: "normal" },
                                        { label: "High", value: "high" },
                                        { label: "Urgent", value: "urgent" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Your Response"}
                                    name='message'
                                    required={true}
                                />

                            </Col>

                        </Row>

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldUpload
                            form={form}
                            label={"Attachments"}
                            name="attachments"
                            required={false}
                            limit={5}
                            defaultValue={form.defaults.attachments}
                            folder={"uploads"}
                        />

                    </Col>

                </Row>

                <Line />

                <Button
                    label={"Submit Response"}
                    loading={update.loading}
                />

            </Form>

        </Panel>

    ), [form.hash, update]);

};

interface UpdateStatusPanelProps {
    [key: string]: any;
}

export default UpdateStatusPanel;
