import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MerchantsApi from '../MerchantsApi';
import { Line, ButtonRow, Button, Panel, Alert, FieldUpload } from '@dex/bubl-dash';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import MerchantsSettings from '../MerchantsSettings';

const MerchantsSingleCancel: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const { data } = props;

    const form = useForm({ cancel_application_reason: data.cancel_application_reason });

    const canCancel = app.userCan(['cancelMerchants']);

    const update = MerchantsApi.update(data.id, "cancel", {
        onComplete: (response) => {

            app.alert('Merchant Saved', 'success');

            //navigate to single view after saving and replace current route
            const route = { "type": "Navigation/NAVIGATE", routeName: MerchantsSettings.key + "View", params: { id: data.id } }
            app.navigate(nav, 'repalce', route);

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Cancel Merchant Application"}
                >

                    {!canCancel &&
                        <Alert
                            type={"warning"}
                            message={"Access Denied"}
                            description={"You do not have permission to cancel this merchant application."}
                        />
                    }

                    {canCancel &&
                        <Form
                            form={form}
                            onSubmit={handleSubmit}
                            loading={update.loading}
                        >

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ xs: 24 }}>

                                            <FieldRadio
                                                form={form}
                                                name='cancel_application'
                                                label="Cancel Application"
                                                required={true}
                                                defaultValue={form.defaults.cancel_application}
                                                options={[
                                                    { label: "Yes", value: "yes" },
                                                    { label: "No", value: "no" },
                                                ]}
                                            />


                                        </Col>
                                        <Col col={{ xs: 24, md: 14 }}>


                                            {form.values.cancel_application === "yes" && (
                                                <FieldTextArea
                                                    form={form}
                                                    label={"Reason For Cancellation"}
                                                    name='cancel_application_reason'
                                                    required={true}
                                                    defaultValue={form.defaults.cancel_application_reason}
                                                />
                                            )}



                                        </Col>

                                        <Col col={{ xs: 24, md: 10 }}>

                                            {form.values.cancel_application === "yes" && (
                                                <FieldUpload
                                                    form={form}
                                                    label={"Attachments"}
                                                    name='cancel_attachments'
                                                    required={false}
                                                    defaultValue={form.defaults.cancel_attachments}
                                                    limit={5}
                                                    folder={'admin-media'}
                                                />

                                            )}
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Line />

                            <ButtonRow>
                                <Button
                                    label={"Save"}
                                    loading={update.loading}
                                />
                                <Button
                                    label={"Reset"}
                                    type={"faded"}
                                    onClick={form.reset}
                                />
                            </ButtonRow>

                        </Form>
                    }

                </Panel>

            </Col>

        </Row>

    ), [data, form.hash, update]);

}

export default MerchantsSingleCancel;
