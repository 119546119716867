import ProvidersCreate from "./ProvidersCreate";
import ProvidersIndex from "./ProvidersIndex";
import ProvidersSettings from "./ProvidersSettings";
import ProvidersSingle from "./ProvidersSingle";


export default function (app) {

    const settings = ProvidersSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        addPath: settings.path + "/create",
        addCaps: settings.createCaps,
        priority: 13,
        items: [
            {
                label: "All Providers",
                path: settings.path,
                caps: settings.indexCaps,
            },
        ],
    });

    ProvidersIndex(app, settings);

    ProvidersCreate(app, settings);

    ProvidersSingle(app, settings);

}