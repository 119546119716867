import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MerchantsMidTidForm from '../../Merchants/Components/MerchantsMidTidForm';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import MidTidLink from '../../../elements/MidTidLink/MidTidLink';

const ServiceStageUpdateDetails: React.FC<any> = (props: any) => {

    const { data, swap_plan } = props;

    const form = useForm(data);

    const app = useApp();

    let modeOfDownloadOptions = [
        { value: "remote_download", label: app.intl("service.mode.remote_download") },
        { value: "onsite_update", label: app.intl("service.mode.onsite_update") },
        { value: "onsite_swap", label: app.intl("service.mode.onsite_swap") },
    ];

    if (swap_plan) modeOfDownloadOptions = modeOfDownloadOptions.filter(mode => mode.value === "onsite_swap");

    const process = ServicesApi.update(data.id, "stage_update_details");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"update_details"}
            heading={"Update Merchant Details"}
            editCaps={["stage_update_detailsServices"]}
            editable={false}
            showForm={data.update_details !== "yes"}
            showMeta={data.update_details === "yes"}
            passed={data.update_details === "yes"}
            showSave={form.changed}
            reverse={data.update_details === "yes" ? null : reverse}
            last_stage={"programming"}
            columnA={[
                {
                    label: "Mode Of Update",
                    key: "mode_of_update",
                    format: "intl",
                    prefix: "service.mode"
                },
            ]}
            columnB={[
                {
                    label: "MID",
                    key: "mid",
                    render: (value) => <MidTidLink data={data} />
                },
            ]}
            columnC={[
                {
                    label: "TID",
                    key: "tid",
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Mode Of Update"}
                                name='mode_of_update'
                                required={true}
                                defaultValue={form.defaults.mode_of_update}
                                options={modeOfDownloadOptions}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <MerchantsMidTidForm
                                prefix={"amend."}
                                form={form}
                                data={data}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageUpdateDetails;
