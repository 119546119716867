import React, { useMemo } from 'react';
import { Button, Col, Heading, NumberPanel, Row, Space, WhiteSpace } from '@dex/bubl-dash';
import { dateTime } from '@dex/bubl-helpers';
import { MdRefresh } from "react-icons/md";

import './DashboardStats.module.scss';

const DashboardStatsGrid: React.FC<any> = (props: any) => {

    const { stats, fetch } = props;

    return useMemo(() => (

        <>
            {stats.data && stats.data.stats && stats.data.stats.length > 0 &&
                <div styleName="stats">

                    {stats.data.stats.map((group, index) => (
                        <Row gutter={"auto"} edge key={index}>

                            <Col col={{ xs: 24 }}>
                                {index > 0 && <Space height={8} />}
                                <Row gutter={"auto"} edge>
                                    <Col col={{ xs: 12 }} justify='center'>
                                        <Heading space={false}>{group.name}</Heading>
                                    </Col>
                                    {!index &&
                                        <Col col={{ xs: 12 }} justify='center' align='end'>
                                            {dateTime(stats.data.date)} <WhiteSpace />
                                            <Button
                                                label={<><MdRefresh /> Refresh</>}
                                                size='small'
                                                type='light'
                                                onClick={fetch.bind(null, true)}
                                                loading={stats.loading}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Col>

                            {group.items.map((item, index) => (
                                <Col
                                    key={index}
                                    col={{ xs: 12, sm: 6, md: 6, lg: 6 }}
                                >
                                    <NumberPanel
                                        footer={item.name}
                                        number={item.value}
                                    />
                                </Col>
                            ))}

                        </Row>
                    ))}

                </div>
            }
        </>

    ), [stats.loading]);


}

export default DashboardStatsGrid;