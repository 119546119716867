import React, { useMemo } from 'react';
import { DataTable, DataTableCell, FieldSwitch, InputTableCell, InputTableRow, MetaTable, Space, useApp } from '@dex/bubl-dash';
import { merchantMidTidList, merchantMidTidValues } from '../../../functions/sharedMerchant';
import { get, slug } from '@dex/bubl-helpers';

const MerchantsMidTidView: React.FC<any> = (props: any) => {

    const { data, form, passed } = props;

    const app = useApp();

    const [settings] = app.store("appSettings");

    const idList = useMemo(() => {

        return merchantMidTidList(settings, data, "read");

    }, []);

    const values = useMemo(() => {

        return merchantMidTidValues(idList, data);

    }, []);

    const columns: any = [
        form ? {
            label: "Tested",
            key: "test",
        } : null,
        {
            label: "Scheme",
            key: "scheme",
        },
        {
            label: "MID",
            key: "mid"
        },
        {
            label: "TID",
            key: "tid"
        },
        {
            label: "Min Amount",
            key: "min"
        },
        {
            label: "Max Amount",
            key: "max"
        },
        !form ? {
            label: "Remarks",
            key: "remarks"
        } : undefined,
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <MetaTable
                data={values}
                columnA={[
                    {
                        label: "Primary MID",
                        key: "mid",
                    },
                    {
                        label: "Primary TID",
                        key: "tid",
                    },
                ]}
                columnB={[
                    {
                        label: "Wifi Password",
                        key: "wifi_password",
                        default: null,
                    },
                    {
                        label: "QR Master Key",
                        key: "qr_master_key",
                        default: null,
                    },
                ]}
                columnC={[
                    {
                        label: "Config Notes",
                        key: "config_notes",
                        format: "nl2br",
                    },
                ]}
            />

            <Space />

            <DataTable
                data={null}
                columns={columns}
                className={"mini-table input-table"}
            >

                {values.list.map((row: any, index: number) => (

                    <InputTableRow key={index}>

                        {form &&
                            <InputTableCell style={{ width: 160, paddingLeft: 12 }}>
                                {row.test &&
                                    <FieldSwitch
                                        form={form}
                                        name={`tests.${slug(row.key)}`}
                                        defaultValue={get(passed, slug(row.key))}
                                    />
                                }
                            </InputTableCell>
                        }

                        <DataTableCell style={{ width: 160 }}>
                            {row.label}
                        </DataTableCell>

                        <DataTableCell style={{ width: 160 }}>
                            {row.mid}
                        </DataTableCell>

                        <DataTableCell style={{ width: 160 }}>
                            {row.tid}
                        </DataTableCell>

                        <DataTableCell style={{ width: 160 }}>
                            {row.min}
                        </DataTableCell>

                        <DataTableCell style={{ width: 160 }}>
                            {row.max}
                        </DataTableCell>

                        {!form &&
                            <DataTableCell style={{ width: 160 }}>
                                {row.remarks}
                            </DataTableCell>
                        }

                    </InputTableRow>

                ))}

            </DataTable>

        </>

    ), [form?.hash]);

}

export default MerchantsMidTidView;
