import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const MigrationDataSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Migration Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Type",
                                key: "type",
                                format: "link",
                            },
                            {
                                label: "Status",
                                key: "status",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Old Id",
                                key: "old_id",
                            },
                            {
                                label: "New Id",
                                key: "new_id",
                            },

                        ]}
                        columnC={[

                        ]}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default MigrationDataSingleView;
