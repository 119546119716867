import React, { useCallback } from 'react';
import { FieldCheckbox, Form, useForm } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';

const OpsMenuFilter: React.FC<any> = (props: any) => {

    const { menu } = props;

    const form = useForm({ [menu.name]: ["-"] });

    const [key, setKey] = React.useState(0);

    const handleChange = useCallback((name, prev, value) => {

        if (!value || value.lenth === 0) {

            value = ["-"];

        } else if (!prev.includes("-") && value.includes("-")) {

            value = ["-"];

        } else if (value.length > 1 && value.includes("-")) {

            value = value.filter((v) => v !== "-");

        }

        form.handleChange({ name: name, value: value, reset: true });

        menu.setFilters(name, value);

        setKey((key) => key + 1);

    }, [form.hash]);

    const value = get(form.value, menu.name, ["-"]);
    const defaultValue = get(form.defaults, menu.name, ["-"]);

    return (

        <>
            <Form
                form={form}
                autoDiscardChanges={true}
            >

                <FieldCheckbox
                    key={key}
                    form={form}
                    name={menu.name}
                    onChange={handleChange.bind(null, menu.name, form.values[menu.name])}
                    defaultValue={defaultValue}
                    options={menu.items}
                    list
                />

            </Form>

        </>

    )

}

export default OpsMenuFilter;
