import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import { FieldCheckbox, FieldRadio, MetaTable, Space } from '@dex/bubl-dash';
import AddonsApi from '../../Addons/AddonsApi';
import { formatNum, loop } from '@dex/bubl-helpers';

const ServicesStageAddOns: React.FC<any> = (props: any) => {

    const { data, apiService } = props;

    const form = useForm(data);

    const process = apiService.update(data.id, "stage_select_addons");

    const addons = AddonsApi.get();

    useEffect(() => {

        addons.run({
            params: {
                where: {
                    device_types: { inq: data.device_type },
                    status: "active"
                }
            }
        });

    }, []);

    const options = useMemo(() => {

        const options: any = [];

        if (!addons.data) return;

        loop(addons.data.data, (item) => {

            options.push({
                value: item.id,
                label: item.addon_name + " - " + formatNum(item.selling_price, 2, "RM "),
            });

        })

        return options;

    }, [addons.data]);

    return useMemo(() => (

        <Stage
            data={data}
            meta={data.selected_addons}
            form={form}
            process={process}
            editCaps={["stage_select_addonsServices"]}
            name={"select_addons"}
            heading={"Addons"}
            editable={data.select_addons && data.prepare_invoice !== "yes"}
            showForm={(!data.select_addons || data.select_addons === "no")}
            showMeta={data.select_addons}
            showSave={form.changed}
            passed={data.select_addons}
            renderMeta={() => (
                <>
                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                key: "select_addons",
                                label: "Selected Addons",
                                format: "titleCase",
                            }
                        ]}
                    />

                    <Space height={"small"} />

                </>

            )}
            columnB={[
                {
                    key: "name",
                    label: "Addon Name",
                },
            ]}
            columnC={[
                {
                    key: "selling_price",
                    label: "Selling Price",
                    format: "number",
                    prefix: "RM ",
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Select Addons Complete?"}
                                name='select_addons'
                                defaultValue={form.defaults.select_addons}
                                required={true}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                    { label: "Skip", value: "skip" },
                                ]}
                            />

                        </Col>

                        {form.values.select_addons !== "skip" &&
                            <Col col={{ xs: 24, md: 16 }}>

                                <FieldCheckbox
                                    form={form}
                                    list
                                    label={"Available Add-Ons"}
                                    name='addons_ids'
                                    required={false}
                                    model={'Addons'}
                                    defaultValue={form.defaults.addons_ids}
                                    options={options}
                                />

                            </Col>
                        }

                    </Row>
                </>
            }
        />

    ), [props, form.hash, options, process.loading]);

}

export default ServicesStageAddOns;
