import UsersCreateEditForm from "./Components/UsersCreateEditForm";
import UsersSingleViewMain from "./UsersSingleViewMain";
import UsersSingleEditMain from "./UsersSingleEditMain";

export default function (app) {

    app.mods.UsersCreateEditForm = UsersCreateEditForm;

    app.mods.UsersSingleViewMain = UsersSingleViewMain;
    app.mods.UsersSingleEditMain = UsersSingleEditMain;

    app.mods.UsersSettings = (settings) => {

        if (!settings._singleFetchParams) settings._singleFetchParams = settings.singleFetchParams;
        if (!settings._indexFetchParams) settings._indexFetchParams = settings.indexFetchParams;
        if (!settings._indexColumns) settings._indexColumns = settings.indexColumns;

        settings.singleFetchParams = (routeName, params, user, app) => {

            const filters = settings._singleFetchParams(routeName, params, user, app);

            filters.include = filters.include || [];

            filters.include.push({ relation: 'providers', preset: 'link' });
            filters.include.push({ relation: 'agency', preset: 'link' });
            filters.include.push({ relation: 'acquirer', preset: 'link' });

            return filters;

        };

        settings.indexFetchParams = (routeName, params, user, app) => {

            const filters = settings._indexFetchParams(routeName, params, user, app);

            filters.where = filters.where || {};

            filters.include = filters.include || [];

            if (params.role === "technician") filters.include.push({ relation: 'providers', preset: 'link' });
            if (params.role === "advisor") filters.include.push({ relation: 'agency', preset: 'link' });
            if (params.role === "acquirer") filters.include.push({ relation: 'acquirer', preset: 'link' });

            if (params.role === "staff") {
                filters.where.roles = { inq: ['manager', 'helpdesk', 'executive', 'sales_admin', 'operation', 'finance',] };
            }

            return filters;

        };

        settings.indexColumns = (routeName, params, user, app) => {

            const columns = settings._indexColumns(routeName, params, user, app);

            if (params.role === "technician") {

                columns.splice(1, 0, {
                    key: 'providers',
                    label: 'Provider',
                    format: 'ModelLink',
                    elipsis: true,
                    style: { maxWidth: 270 }
                });

                columns.splice(4, 0, {
                    key: 'manager',
                    label: 'Manager',
                    format: "titleCase"
                });

            } else if (params.role === "advisor") {

                columns.splice(1, 0, {
                    key: 'agency',
                    label: 'Agency',
                    format: 'ModelLink',
                    elipsis: true,
                });

                columns.splice(4, 0, {
                    key: 'manager',
                    label: 'Manager',
                    format: "titleCase"
                });

            } else if (params.role === "acquirer") {

                columns.splice(1, 0, {
                    key: 'acquirer',
                    label: 'Acquirer',
                    format: 'ModelLink',
                    elipsis: true,
                });

                columns.splice(4, 0, {
                    key: 'manager',
                    label: 'Manager',
                    format: "titleCase"
                });

            }

            return columns;

        }

        return settings;

    };

}