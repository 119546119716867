import { ModelLink } from '@dex/bubl-dash';
import React from 'react';

const DockingLink: React.FC<DockingLinkProps> = (props) => {

    const { data } = props;

    return (

        <>
            {(data && data.id) ?
                <ModelLink data={data} primaryKey={"docking_id"} />
                :
                <>-</>
            }
        </>

    )

}

interface DockingLinkProps {
    data: any,
    [key: string]: any,
}

export default DockingLink;