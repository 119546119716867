import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldRadio, FieldSelect } from '@dex/bubl-dash';


const MigrationsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Migration Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 12 }}>

                        <FieldSelect
                            form={form}
                            label={"From WP Type > To Model Name"}
                            name='fromTo'
                            required={true}
                            defaultValue={form.defaults.fromTo}
                            options={[
                                { value: 'helpdesk>HelpdeskTickets', label: 'Helpdesk > HelpdeskTickets' },
                                { value: 'leasing>Leasing', label: 'Leasing > Leasing' },
                                // { value: 'debtors>Debtors', label: 'Debtors > Debtors' },
                                { value: 'acquirers>Acquirers', label: 'Acquirers > Acquirers' },
                                { value: 'attachment>Media', label: 'Attachment > Media' },
                                { value: 'agents>Agencies', label: 'Agents > Agencies' },
                                { value: 'providers>Providers', label: 'Providers > Providers' },
                                { value: 'terminals>Terminals', label: 'Terminals > Terminals' },
                                { value: 'engagements>EngagementPlans', label: 'Engagements > Engagement Plans' },
                                { value: 'merchants>Merchants', label: 'Merchants > Merchants' },
                                { value: 'rollouts>MassRollouts', label: 'Rollouts > MassRollouts' },
                                { value: 'replacements>Replacements', label: 'Replacements > Replacements' },
                                // { value: 'certificates>Certificats', label: 'Certificates > Certificats' },
                                { value: 'installations>Installations', label: 'Installations > Installations' },
                                { value: 'invoice>Invoices', label: 'Invoice > Invoices' },
                                { value: 'proforma>ProformaInvoices', label: 'Proforma > ProformaInvoices' },
                                { value: 'service>Services', label: 'Service > Services' },
                                // { value: 'learning>Learning', label: 'Learning > Learning' },
                                { value: 'claims>Claims', label: 'Claims > Claims' },
                                { value: 'users>Users', label: 'Users > Users' },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            label={"Source"}
                            name='source'
                            required={true}
                            defaultValue={form.defaults.source}
                            options={[
                                { label: 'Staging', value: 'staging' },
                                { label: 'Production', value: 'production' },
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default MigrationsCreateEditForm;
