import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import React, { useMemo, useState } from 'react'
import InvoiceSettings from '../../Invoices/InvoicesSettings';

const MerchantBilling: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = InvoiceSettings.indexFetchParams(routeName, params, users);

        filters.where.debtor_id = data.id;

        return filters;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Billing"}
                hideTrashSwitch={true}
                getFetch={InvoiceSettings.indexFetch}
                getColumns={InvoiceSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data, tableState]);
}

export default MerchantBilling