import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio } from '@dex/bubl-dash';

const ServiceStageRequestAccepted: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_accepted_service_request");

    const reverse = ServicesApi.update(data.id, "stage_reverse");

    const canReverse = data.status === "in_progress";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"accepted_service_request"}
            heading={"Service Request Accepted"}
            editCaps={["stage_accepted_service_requestServices"]}
            viewCaps={["viewServices"]}
            editable={false}
            showForm={data.request_accepted !== "yes"}
            showMeta={data.request_accepted === "yes"}
            passed={data.request_accepted === "yes"}
            showSave={form.changed}
            reverse={data.request_accepted === "yes" ? null : reverse}
            last_stage={"assign_provider"}
            columnA={[
                {
                    label: "Request Accepted",
                    key: "request_accepted",
                    format: "titleCase"
                },

            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                form={form}
                                label={"Request Accepted"}
                                name='request_accepted'
                                required={true}
                                defaultValue={form.defaults.request_accepted}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageRequestAccepted;
