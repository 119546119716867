import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldNumber, FieldRadio, FieldText, FieldUpload } from '@dex/bubl-dash';

const ServiceStageMileageClaim: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_travel_distance");

    return useMemo(() => (
        <Stage
            data={data}
            form={form}
            process={process}
            name={"travel_distance"}
            heading={"Mileage Claim"}
            editCaps={["stage_claim_mileageServices"]}
            editable={data.travel_distance === "yes" && !['pending', 'approved', 'claimed'].includes(data.claim_status)}
            showForm={data.travel_distance !== "yes" && !['pending', 'approved', 'claimed'].includes(data.claim_status)}
            showMeta={data.travel_distance}
            passed={data.travel_distance === "yes" || ['pending', 'approved', 'claimed'].includes(data.claim_status)}
            showSave={form.changed}
            columnA={[
                {
                    key: "travel_distance",
                    label: "Distance Traveled Over 30 KM",
                    format: "titleCase",
                },
                data.travel_distance === "yes" && {
                    key: "travel_distance_km",
                    label: "Distance Traveled",
                    prefix: "KM ",
                },
            ].filter(Boolean)}
            columnB={[
                data.travel_distance === "yes" && {
                    key: "travel_distance_photo",
                    label: "Journey Map",
                    format: "file",
                },
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>
                        <Col col={{ xs: 24, md: 16 }}>
                            <FieldRadio
                                form={form}
                                label={"Distance Traveled Over 30 KM"}
                                name="travel_distance"
                                required={true}
                                defaultValue={form.defaults.travel_distance}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />
                        </Col>

                        {form.values.travel_distance === "yes" && (
                            <>
                                <Col col={{ xs: 24, md: 12 }}>
                                    <FieldNumber
                                        form={form}
                                        label={"Distance Traveled"}
                                        name="travel_distance_km"
                                        required={true}
                                        suffix={" KM"}
                                        defaultValue={
                                            form.defaults.travel_distance_km
                                        }
                                    />
                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>
                                    <FieldUpload
                                        form={form}
                                        folder={"uploads"}
                                        label={"Journey Map"}
                                        name={"travel_distance_photo"}
                                        required={true}
                                        defaultValue={
                                            form.defaults
                                                .travel_distance_photo
                                        }
                                        limit={20}
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </>
            }
        />
    ), [props, form.hash, process.loading]);

}

export default ServiceStageMileageClaim;
