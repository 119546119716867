import React, { useCallback, useMemo, useState } from 'react';
import styles from "./ParticipantPanel.module.scss";
import { Button, ButtonRow, FieldList, FieldRelationship, FieldText, Form, Line, Panel, useApp, useForm } from '@dex/bubl-dash';
import HelpdeskTicketsApi from '../../views/HelpdeskTickets/HelpdeskTicketsApi';

const ParticipantPanel: React.FC<ParticipantPanelProps> = (props) => {
    const [participant, setParticipant] = useState(false);

    const { data } = props;

    const app = useApp();

    const form = useForm(data);

    const update = HelpdeskTicketsApi.update(data.id, "updateParticipant", {
        onComplete: (data) => {

            app.alert('Participant Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    return useMemo(() => (

        <Panel
            heading={"Participants"}
        >
            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >
                <FieldList
                    form={form}
                    name={'participants_ids'}
                    required={false}
                    addButtonLabel={"Add Participant"}
                >

                    {form.values.participants_ids?.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <FieldRelationship
                                form={form}
                                name={`participants_ids[${n}]`}
                                required={false}
                                model={'Users'}
                                preload={true}
                                defaultValue={form.defaults.participants_ids[n]}
                                onChange={() => setParticipant(true)}
                            />

                        </React.Fragment>

                    ))}

                </FieldList>

                {participant && (
                    <>
                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Save"}
                                loading={update.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />
                        </ButtonRow>
                    </>
                )}

            </Form>

        </Panel>

    ), [form.hash]);

}

interface ParticipantPanelProps {
    [key: string]: any,
}

export default ParticipantPanel;