import { ModelLink } from '@dex/bubl-dash';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Col, Row, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { format } from 'path';
import React, { useMemo } from 'react';
import { render } from 'react-dom';

const ClaimsEntrySingleView: React.FC<any> = (props: any) => {

    const { claim, type } = props;

    const getColumns = () => {

        if (type === 'sales') {

            return [
                {
                    label: "#",
                    key: "_index",
                    style: { maxWidth: "40px" }
                },
                {
                    label: "Merchant",
                    key: "merchant",
                    style: { maxWidth: "200px" },
                    elipsis: true,
                    render: (value: any, row: any) => (
                        <>
                            <ModelLink data={{ id: row.merchant_id }} modelName="Merchants" />
                            <br />
                            <small style={{ fontSize: ".88em" }}>{row.merchant_name}</small>
                        </>
                    )
                },
                {
                    label: "Location Type",
                    key: "merchant_location",
                    format: "titleCase"
                },
                {
                    label: "Engagement Plan",
                    key: "engagement_plan_name",
                    format: "titleCase",
                    style: { maxWidth: "150px" },
                    elipsis: true,
                },
                {
                    label: "Date Installed",
                    key: "date_installed",
                    format: "dateDay",
                },
                {
                    label: "Notes",
                    key: "notes",
                    style: { maxWidth: "200px", width: "200px" },
                },
                {
                    label: "Commission",
                    key: "amount",
                    format: "number",
                    prefix: "RM ",
                },
                {
                    label: "Claim Status",
                    key: "status",
                    format: 'status',
                },
            ];

        } else if (type === 'service') {

            return [
                {
                    label: "#",
                    key: "_index",
                    style: { maxWidth: "40px" }
                },
                {
                    label: "Request ID",
                    key: "ops_id",
                    render: (value: any, row: any) => (
                        <ModelLink data={{ id: row.ops_id }} modelName={row.ops_type} />
                    )
                },
                {
                    label: "Merchant",
                    key: "merchant_name",
                    style: { maxWidth: "150px" },
                    elipsis: true,
                    render: (value: any, row: any) => (
                        <>
                            <ModelLink data={{ id: row.merchant_id }} modelName="Merchants" />
                            <br />
                            <small style={{ fontSize: ".88em" }}>{row.merchant_name}</small>
                        </>
                    )
                },
                // {
                //     label: "Service Status",
                //     key: "ops.status",
                //     format: "status",
                //     prefix: "services.status"
                // },
                {
                    label: "Type",
                    key: "type",
                    format: "intl",
                    prefix: "service.type",
                    style: { maxWidth: "100px" },
                    elipsis: true,
                },
                {
                    label: "Date Verified",
                    key: "date_complete",
                    format: "dateDay",
                },
                {
                    label: "Technician",
                    key: "technician_name",
                    style: { maxWidth: "180px" },
                    elipsis: true,
                    render: (value: any, row: any) => (
                        <ModelLink data={{ id: row.technician_id, fullName: row.technician_name }} modelName="Users" />
                    )
                },
                {
                    label: "Service Time",
                    key: "service_time",
                    format: "number",
                    suffix: " hours"
                },
                {
                    label: "Travel Distance",
                    key: "mileage",
                    format: "number",
                    suffix: " KM"
                },
                {
                    label: "Acquirer",
                    key: "acquirer_name",
                },
                {
                    label: "SLA",
                    key: "sla",
                    format: "titleCase"
                },
                {
                    label: "Payout",
                    key: "amount",
                    format: "number",
                    prefix: "RM "
                },
                {
                    label: "Claim Status",
                    key: "status",
                    format: "status",
                    prefix: "claim_entry_services.status"
                },
            ];

        } else {

            return [];

        }

    }


    const dataWithIndex = useMemo(() => {
        return claim.entries.map((entry, index) => ({
            ...entry,
            _index: index + 1
        }));
    }, [claim.entries]);

    return (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <MetaTable
                        data={{
                            type: type,
                            agent: claim.agent,
                            agency: claim.agency,
                            provider: claim.provider,
                            total: claim.total
                        }}
                        columnA={[
                            {
                                label: "Claim Type",
                                key: "type",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            claim.agent && {
                                label: "Advisor",
                                key: "agent",
                                format: "modelLink",
                            },
                            claim.agency && {
                                label: "Agency",
                                key: "agency",
                                format: "modelLink",
                            },
                            claim.provider && {
                                label: "Provider",
                                key: "provider",
                                format: "modelLink",
                            },
                        ].filter(Boolean)}
                        columnC={[
                            {
                                label: "Total Payout",
                                key: "total",
                                format: "number",
                                prefix: "RM "
                            },
                        ]}

                    />

                </Col>

                <Col col={{ xs: 24 }}>
                    <Space height={"small"} />
                </Col>

                <Col col={{ xs: 24 }}>

                    <DataTable
                        data={dataWithIndex}
                        columns={getColumns()}
                    />

                </Col>

            </Row>

        </>

    );
}

export default ClaimsEntrySingleView