import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import AcquirersApi from './AcquirerApi';
import { wpOldLink } from '../../functions/wpOldLink';


let settings;

export default settings = {
    key: 'acquirers',
    zone: 'lists',
    path: '/acquirers',
    title: "Acquirers",
    endpoint: AcquirersApi.endpoint,
    modelName: 'Acquirers',
    idKey: 'id',
    primaryKey: 'company_name',
    indexCaps: ["viewAcquirers"],
    viewCaps: ["viewAcquirers"],
    createCaps: ["createAcquirers"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: AcquirersApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['company_name', 'person', 'phone', 'email'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            // {
            //     label: "Company Name",
            //     key: "company_name",
            //     type: "text",
            // },
            {
                label: "Contact Person",
                key: "person",
                type: "text",
            },
            {
                label: "Phone",
                key: "phone",
                type: "text",
            },
            {
                label: "Email",
                key: "email",
                type: "text",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Company Name",
                key: "this",
                format: "ModelLink",
                sort: 'company_name'
            },
            {
                label: "Contact Person",
                key: "person",
                render: (value, row) => {
                    return <>{value}<br />{row.phone}<br />{row.email}</>
                }
            },
            {
                label: "Address",
                key: "billing_address",
                format: "address",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: AcquirersApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: AcquirersApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
            {
                label: "Activity",
                path: path + "/activity"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },
};