import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Summary';

const SummaryApi: baseApiReturn = baseApi(endpoint);

export default SummaryApi;

SummaryApi.getSummaryStats = (options: options): response => {

    return apiGet({
        "url": endpoint + "/getSummaryStats",
        ...options
    });

}