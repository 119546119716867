import React, { useMemo } from 'react';
import ClaimStageMakePayment from './ClaimStageMakePayment';
import ClaimStageReview from './ClaimStageReview';

const ClaimStages: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <ClaimStageReview
                data={data}
            />

            {(data.review === "yes" && data.status !== "denied") && (

                <ClaimStageMakePayment
                    data={data}
                />

            )}

        </>

    ), [data]);

}

export default ClaimStages;
