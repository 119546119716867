import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Seperator } from '@dex/bubl-dash';

const ParamTemplatesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Param Template Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Template Name",
                                key: "name",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Acquirer",
                                key: "acquirer",
                                format: "ModelLink"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Device Type",
                                key: "device_type",
                                format: "titleCase"
                            }
                        ]}
                    />

                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"Params"}
                    />

                    <MetaTable
                        data={data.params}
                        columnA={[
                            {
                                label: "Name",
                                key: "name",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Value",
                                key: "value",
                            }
                        ]}
                        columnC={[
                            {
                                label: "Custom Value",
                                key: "custom",
                                conditions: (row) => row.value === "custom"
                            }
                        ]}
                    />

                </Panel>

            </Col>

            <Col
                className={'side'}
            >

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default ParamTemplatesSingleView;
