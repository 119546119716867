import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { FieldRadio, FieldRelationship } from '@dex/bubl-dash';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const ServiceStagePreparePackage: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_prepare_package");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"prepare_package"}
            heading={"Prepare Package"}
            editCaps={["stage_prepare_packageServices"]}
            editable={false}
            showForm={data.prepare_package !== "yes"}
            showMeta={data.prepare_package === "yes"}
            passed={data.prepare_package === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Paper Roll",
                    key: "roll_size",
                },
                {
                    label: "Additional Notes",
                    key: "notes",
                },
            ]}
            columnB={[
                {
                    label: "Collection Method",
                    key: "collection_method",
                    format: "intl",
                    prefix: "service.collection_method",
                },
                {
                    label: "Acquirer",
                    key: "acquirer",
                    format: "modelLink",
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldRadio
                                form={form}
                                label={"Roll Size"}
                                name='roll_size'
                                required={true}
                                defaultValue={form.defaults.roll_size}
                                options={[
                                    { label: "5", value: "5" },
                                    { label: "10", value: "10" },
                                    { label: "20", value: "20" },
                                    { label: "40", value: "40" },
                                    { label: "100", value: "100" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Acquirer",
                                        key: "acquirer",
                                        format: "modelLink"
                                    },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Collection Method"}
                                name='collection_method'
                                required={true}
                                defaultValue={form.defaults.collection_method}
                                options={[
                                    { label: "Self Collect", value: "self_collect" },
                                    { label: "Postage", value: "postage" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <FieldTextArea
                                form={form}
                                label={"Additional Notes"}
                                name='notes'
                                required={false}
                                defaultValue={form.defaults.notes}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStagePreparePackage;
