import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import EngagementPlansApi from './EngagementPlansApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';


let settings;

export default settings = {
    key: 'engagement-plans',
    zone: 'finance',
    path: '/engagement-plans',
    title: "Engagement Plans",
    endpoint: EngagementPlansApi.endpoint,
    modelName: 'EngagementPlans',
    idKey: 'id',
    primaryKey: 'plan_name',
    indexCaps: ["viewEngagementPlans"],
    viewCaps: ["viewEngagementPlans"],
    createCaps: ["createEngagementPlans"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
            },
            {
                label: "Disabled",
                path: settings.path + "/status/disabled",
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: EngagementPlansApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'acquirer',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        let { status } = params || {};

        if (status) filters.where.status = { inq: [status] };

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "engagementPlans", label: "EngagementPlans" }
        ];

        if (!user || !user.caps.includes("exportEngagementPlans")) return [];

        return formats;

    },

    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'original_id', 'plan_name', 'business_model',];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Plan Name",
                key: "plan_name",
                type: "text"
            },
            {
                label: "Business Model",
                key: "business_model",
                type: "text"
            },
            {
                label: "Acquirer",
                key: "acquirer_id",
                type: "relationship",
                model: "Acquirers"
            },
            {
                label: "Device Type",
                key: "device_type",
                type: "text"
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "active", label: "Active" },
                    { value: "disabled", label: "Disabled" },
                ]
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Plan Name",
                key: "this",
                format: "ModelLink",
                render: (value, row) => <OriginalModelLink data={row} />
            },
            {
                label: "Business Model",
                key: "business_model",
                format: "status",
                prefix: "engagement_plans.business_model"
            },
            {
                label: "Acquirer",
                key: "acquirer",
                format: "ModelLink"
            },
            {
                label: "Device Type",
                key: "device_type",
                format: "titleCase"
            },
            {
                label: "Status",
                key: "status",
                format: "status"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: EngagementPlansApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: EngagementPlansApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'acquirer',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string | any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        if (data) {
            title = <>
                {title}
                <WhiteSpace /> <Status status={data.business_model} prefix='engagement_plans.business_model' />
                <WhiteSpace /> <Status status={data.device_type} prefix='engagement_plans.device_type' />
                <WhiteSpace /> <Status status={data.status} prefix='engagement_plans.status' />
            </>
        }

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },

};