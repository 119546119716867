import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';

import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';
import { Line, userHasCap } from '@dex/bubl-dash';

const ServiceStageRemoteDownload: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_remote_download");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    const isStaff = userHasCap(['staff']);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"remote_download"}
            heading={"Remote Download"}
            editCaps={["stage_remote_downloadServices"]}
            editable={false}
            showForm={data.remote_download !== "yes"}
            showMeta={data.remote_download === "yes"}
            passed={data.remote_download === "yes"}
            showSave={form.changed}
            reverse={data.remote_download === "yes" && isStaff ? null : reverse}
            last_stage={"update_details"}
            columnA={[
                {
                    label: "Update Complete",
                    key: "remote_download",
                    format: "titleCase"
                },
            ]}
            columnB={[
                {
                    label: "Params File",
                    key: "params_file",
                    format: "file"
                },
            ]}
            columnC={[
                {
                    label: "Attachments",
                    key: "remote_download_attachments",
                    format: "file"
                },
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldRadio
                                form={form}
                                label={"Update Complete"}
                                name='remote_download'
                                required={true}
                                defaultValue={form.defaults.remote_download}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.remote_download === "yes" && (
                            <>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Params File"}
                                        name={`params_file`}
                                        required={false}
                                        defaultValue={form.defaults.params_file}
                                        limit={1}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldUpload
                                        form={form}
                                        folder={'ops-media'}
                                        label={"Attachments"}
                                        name={`remote_download_attachments`}
                                        required={false}
                                        defaultValue={form.defaults.remote_download_attachments}
                                        limit={20}
                                    />

                                </Col>
                            </>
                        )}


                        <Col col={{ xs: 24, md: 24 }}>

                            <Line top={"small"} />

                            <MetaTable
                                columnSize={{ xs: 8 }}
                                data={data}
                                columnA={[
                                    {
                                        label: "Software Version",
                                        key: "software_version",
                                    },
                                    {
                                        label: "Standard Applications",
                                        key: "standard_applications",
                                    },
                                ]}

                                columnB={[
                                    {
                                        label: "OS Version",
                                        key: "os_version",
                                    },
                                    {
                                        label: "Optional Applications",
                                        key: "optional_applications",
                                    },
                                ]}

                                columnC={[
                                    {
                                        label: "Installation Address",
                                        key: "installation_address",
                                        format: "address",
                                    },
                                ]}

                            />


                            <Space height={"small"} />

                            <MerchantsMidTidView
                                data={data}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default ServiceStageRemoteDownload;
