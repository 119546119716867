import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Stage from '../../../elements/Stage/Stage';

const MerchantStageBeginInstallation: React.FC<any> = (props: any) => {

    const { data, apiService } = props;

    const form = useForm(data);

    const process = apiService.update(data.id, "stage_begin_installation");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            editCaps={["stage_begin_installationMerchants"]}
            name={"begin_installation"}
            heading={"Begin Installation"}
            editable={data.begin_installation === "yes" && data.status === "new_merchant"}
            showForm={data.begin_installation !== "yes" && data.status === "new_merchant"}
            showMeta={data.begin_installation}
            showSave={form.changed}
            passed={data.begin_installation === "yes"}
            columnA={[
                {
                    key: "begin_installation",
                    label: "Begin Installation",
                    format: "titleCase"
                }
            ]}
            columnB={[
                {
                    key: "installation",
                    label: "Installation ID",
                    format: "ModelLink"
                }
            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldRadio
                                form={form}
                                name={"begin_installation"}
                                label={"Begin Installation"}
                                defaultValue={form.defaults.begin_installation}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form, process.loading]);

}

export default MerchantStageBeginInstallation;
