import React, { useCallback, useEffect, useMemo } from 'react';
import SummaryApi from '../SummaryApi';
import DashboardStatsGrid from '../../Dashboard/DashboardStats/DashboardStatsGrid';

const SummaryStats: React.FC<any> = (props: any) => {

    const { type, filter } = props;

    const stats = SummaryApi.getSummaryStats();

    const fetch = useCallback((refresh) => {

        stats.run({
            params: {
                refresh: refresh,
                type: type,
                filter: filter,
            }
        });

    }, [type, filter]);

    useEffect(() => {

        fetch(false);

    }, [filter])

    return useMemo(() => (

        <>
            <DashboardStatsGrid
                stats={stats}
                fetch={fetch}
            />
        </>

    ), [stats.loading]);


}

export default SummaryStats;