import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldHidden, FieldRepeater, FieldSelect, FieldSwitch, Space, useApp } from '@dex/bubl-dash';
import { get, slug } from '@dex/bubl-helpers';

const SettingsApplications: React.FC<any> = (props: any) => {

    const { form } = props;

    const app = useApp();

    const [settings] = app.store("appSettings", {});

    const isSuper = app.userCan(["super"]);

    return useMemo(() => (

        <>

            <Space />

            {["Standard", "Optional", "QR"].map((label, index) => {

                const key = slug(label);

                return (

                    <>

                        <Seperator
                            heading={label + " Applications"}
                            bottom={"small"}
                            top={index ? "small" : false}
                        />

                        <FieldRepeater
                            form={form}
                            name={key}
                            required={false}
                            addButtonLabel={"Add Application"}
                            primaryName={"key"}
                            collapseAll={true}
                        >

                            {(form.values[key] || []).map((row, n) => (

                                <Row gutter={8} edge={true}>

                                    <Col col={{ xs: 16 }}>

                                        <FieldText
                                            form={form}
                                            label={`Key / Label`}
                                            name={`${key}[${n}].key`}
                                            defaultValue={row.key}
                                            required
                                            disabled={!isSuper && form.defaults[key] && form.defaults[key][n]?.key}
                                            hidden={!isSuper && form.defaults[key] && form.defaults[key][n]?.key}
                                        />

                                    </Col>
                                    <Col col={{ xs: 8 }}>

                                    </Col>
                                    <Col col={{ xs: 8, md: 4 }}>

                                        <FieldSwitch
                                            form={form}
                                            label={`Enabled By Default`}
                                            name={`${key}[${n}].default`}
                                            defaultValue={row.default}
                                        />

                                    </Col>
                                    <Col col={{ xs: 8, md: 4 }}>

                                        <FieldSwitch
                                            form={form}
                                            label={`Has MID/TID`}
                                            name={`${key}[${n}].hasMidTid`}
                                            defaultValue={row.hasMidTid}
                                        />

                                    </Col>

                                    <Col col={{ xs: 8, md: 4 }}>

                                        <FieldSwitch
                                            form={form}
                                            label={`Has Remarks`}
                                            name={`${key}[${n}].hasRemarks`}
                                            defaultValue={row.hasRemarks}
                                        />

                                    </Col>

                                    <Col col={{ xs: 8, md: 4 }}>

                                        <FieldSwitch
                                            form={form}
                                            label={`Transaction Testing`}
                                            name={`${key}[${n}].testTransaction`}
                                            defaultValue={row.testTransaction}
                                        />

                                    </Col>

                                    {row.hasMidTid &&
                                        <Col col={{ xs: 8, md: 4 }}>

                                            <FieldSwitch
                                                form={form}
                                                label={`Use Primary MID/TID`}
                                                name={`${key}[${n}].usePrimary`}
                                                defaultValue={row.usePrimary}
                                            />

                                        </Col>
                                    }

                                    {row.hasMidTid &&
                                        <Col col={{ xs: 8, md: 4 }}>

                                            <FieldSwitch
                                                form={form}
                                                label={`Has Transaction Limits`}
                                                name={`${key}[${n}].hasLimits`}
                                                defaultValue={row.hasLimits}
                                            />

                                        </Col>
                                    }

                                    {row.hasMidTid &&
                                        <Col col={{ xs: 24, md: 16 }}>

                                            <FieldSelect
                                                form={form}
                                                label={`Prefix`}
                                                name={`${key}[${n}].prefix`}
                                                defaultValue={row.prefix || "none"}
                                                options={[
                                                    { label: "None", value: "none" },
                                                    { label: "8 Digit MID Prefix", value: "primary" },
                                                    { label: "AMEX EzPay MID Prefix", value: "amex" },
                                                ]}
                                            />

                                        </Col>
                                    }


                                    {row.hasMidTid &&
                                        <Col col={{ xs: 24, md: 16 }}>

                                            <FieldSelect
                                                form={form}
                                                label={`MID/TID List (Optional)`}
                                                name={`${key}[${n}].idList`}
                                                defaultValue={row.idList}
                                                options={[
                                                    { label: "3 Months", value: "3" },
                                                    { label: "6 Months", value: "6" },
                                                    { label: "9 Months", value: "9" },
                                                    { label: "12 Months", value: "12" },
                                                    { label: "18 Months", value: "18" },
                                                    { label: "24 Months", value: "24" },
                                                    { label: "30 Months", value: "30" },
                                                    { label: "36 Months", value: "36" },
                                                    { label: "42 Months", value: "42" },
                                                    { label: "48 Months", value: "48" },
                                                    { label: "60 Months", value: "60" },
                                                ]}
                                                isMulti
                                            />

                                        </Col>
                                    }

                                </Row>

                            ))}

                        </FieldRepeater>

                    </>

                )

            })}

        </>

    ), [form.hash]);

}

export default SettingsApplications;
