import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import AcquirersUsersSingleView from './AcquirersUsersSingleView';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { userHasCap, userHasRole } from '@dex/bubl-dash';



const AcquirersSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const pathDefaults = encodeURIComponent(JSON.stringify({ roles: ["acquirer"], "acquirer_id": [data.id] }));
    const isStaff = userHasCap(['staff']);
    const isCompanyManager = userHasCap(['companyManager']);




    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Acquirer Details"}
                    actions={(
                        <>
                            {isStaff &&
                                <Button
                                    label="Add User"
                                    size={"small"}
                                    path={"/users/create?defaults=" + pathDefaults}

                                />
                            }
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "company_name",
                                format: "link",
                            },
                            {
                                label: "Contact Person",
                                key: "person",
                            },
                            {
                                label: "Email",
                                key: "email",
                            },
                            {
                                label: "Phone",
                                key: "phone",
                            },

                        ]}
                        columnB={[
                            {
                                label: "Billing Name",
                                key: "billing_name",
                            },
                            {
                                label: "Billing Address",
                                key: "billing_address",
                                format: "address"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Additional Notes",
                                key: "notes",
                            },
                            {
                                label: "Software Version",
                                key: "software_version",
                            },
                            {
                                label: "Online Portal Link",
                                key: "portal",
                            },
                        ]}
                    />

                    <Space />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Business Registration Number (BRN)",
                                key: "brn",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Tax Identification Number (TIN)",
                                key: "tin",
                            },
                        ]}
                        columnC={[
                            {
                                label: "SST No.",
                                key: "sst",
                            },
                        ]}
                    />

                </Panel>

                {(isCompanyManager || isStaff) &&
                    <>
                        <Space />

                        <AcquirersUsersSingleView
                            data={data}
                        />
                    </>
                }

            </Col>

        </Row>

    ), [data]);

}

export default AcquirersSingleView;
