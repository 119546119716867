import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import AgenciesApi from './AgenciesApi';
import { wpOldLink } from '../../functions/wpOldLink';


let settings;

export default settings = {
    key: 'agencies',
    zone: 'lists',
    path: '/agencies',
    title: "Agencies",
    endpoint: AgenciesApi.endpoint,
    modelName: 'Agencies',
    idKey: 'id',
    primaryKey: 'company_name',
    indexCaps: ["viewAgencies"],
    viewCaps: ["viewAgencies"],
    createCaps: ["createAgencies"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Add New",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: AgenciesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['company_name'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Contact Person",
                key: "full_name",
                type: "text",
            },
            {
                label: "Phone",
                key: "phone",
                type: "text",
            },
            {
                label: "Email",
                key: "email",
                type: "text",
            },
            {
                label: "Agency Level",
                key: "agency_level",
                type: "choice",
                options: [
                    { value: "1", label: "Level 1" },
                    { value: "2", label: "Level 2" },
                    { value: "3", label: "Level 3" },
                    { value: "4", label: "Level 4" },
                    { value: "5", label: "Level 5" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Company Name",
                key: "this",
                format: "ModelLink",
                sort: 'company_name'
            },
            {
                label: "Level",
                key: "agency_level",
                format: "number"
            },
            {
                label: "Contact Person",
                key: "person.full_name",
                render: (value, row) => {
                    return <>{value}<br />{row.person.phone}<br />{row.person.email}</>
                }
            },
            {
                label: "Address",
                key: "billing_address",
                format: "address",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: AgenciesApi,
                settings: settings,
            }
        ];

        return columns;

    },


    singleFetch: AgenciesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view",
                caps: settings.viewCaps
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.createCaps
            },
            {
                label: "Activity",
                path: path + "/activity"
            },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },
};