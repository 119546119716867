import React, { useCallback, useMemo } from 'react';
import { Button, Col, Heading, Link, NumberPanel, Row, Space, WhiteSpace, useMountEffect } from '@dex/bubl-dash';
import { dateTime } from '@dex/bubl-helpers';
import { MdRefresh } from "react-icons/md";
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import DashboardApi from '../DashboardApi';

import './DashboardStats.module.scss';
import DashboardStatsGrid from './DashboardStatsGrid';

const DashboardStats: React.FC<any> = (props: any) => {

    const app = useApp();

    const stats = DashboardApi.getStats();

    const fetch = useCallback((refresh) => {

        stats.run({
            params: { refresh }
        });

    }, []);

    useMountEffect(() => {

        fetch(false);

    })

    return useMemo(() => (

        <>
            <DashboardStatsGrid
                stats={stats}
                fetch={fetch}
            />
        </>

    ), [stats.loading]);


}

export default DashboardStats;