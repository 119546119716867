import React, { useMemo } from 'react';
import { Col, FieldCheckbox, FieldRadio, FieldSelect, FieldTextArea, Row, Space, useApp, useForm } from '@dex/bubl-dash';
import { userHasCap } from '../../../functions/useUser';
import Stage from '../../../elements/Stage/Stage';
import InvoicesApi from '../../Invoices/InvoicesApi';


const InvoicesStagesVoidInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const process = InvoicesApi.update(data.id, "stage_void");

    const form = useForm(data);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"void_status"}
            editCaps={["stage_voidInvoices"]}
            heading={"Void Invoice"}
            editable={false}
            showForm={(data.status !== "credit" || data.status === "due") && data.status !== "void"}
            showMeta={data.status === "void" || data.status === "credit"}
            showSave={form.changed}
            passed={data.status === "void"}
            columnA={[
                {
                    key: "status",
                    label: "Status",
                },
                {
                    key: "void_note",
                    label: "Notes",
                }
            ]}
            columnB={[

            ]}
            fields={

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={"status"}
                                label={"Status"}
                                defaultValue={form.defaults.status}
                                options={[
                                    { label: "Void This Invoice", value: "void" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                name={"void_note"}
                                label={"Note"}
                                defaultValue={form.defaults.void_note}
                            />

                        </Col>

                    </Row>

                </Col>
            }
        />

    ), [data, form.hash]);

}

export default InvoicesStagesVoidInvoice;
