import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldDate from '@dex/bubl-dash/src/lib/components/Fields/FieldDate';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import ClaimsApi from '../ClaimsApi';
import ClaimsEntrySingleView from './ClaimsEntrySingleView';
import { FieldRelationship, FieldSwitch } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import FieldHidden from '@dex/bubl-dash/src/lib/components/Fields/FieldHidden';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const ClaimsCreateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, hash } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const autoGenerate = ClaimsApi.create({}, "autoGenerate");

    const handleAutoGenerate = useCallback((data) => {

        autoGenerate.run({
            data: data
        })

    }, []);

    const handleReset = useCallback(() => {

        autoGenerate.reset();

    }, [autoGenerate.hash]);

    const claims = autoGenerate.data;

    return useMemo(() => (


        <Col
            col={{ xs: 24 }}
        >

            <Panel
                heading={"Auto Generate Claims"}
            >

                {claims !== null &&

                    <Form
                        form={form}
                        onSubmit={handleSubmit}
                        loading={process.loading}
                    >

                        <FieldHidden
                            form={form}
                            name="type"
                            defaultValue={form.values.type}
                        />
                        <FieldHidden
                            form={form}
                            name="date"
                            defaultValue={form.values.date}
                        />

                        {claims.map((claim, index) => {

                            if (form.values.type === "sales") {

                                return (
                                    <React.Fragment key={index}>

                                        <FieldSwitch
                                            form={form}
                                            label={claim.agent.fullName + (claim.agency && claim.agency.company_name !== claim.agent.fullName ? " - " + claim.agency.company_name + "" : "")}
                                            name={`ids[${claim.agent.id}]`}
                                            required={false}
                                        />

                                        {get(form.values, `ids[${claim.agent.id}]`) && (

                                            <>

                                                <FieldTextArea
                                                    form={form}
                                                    label={"Notes"}
                                                    name={`notes[${claim.agent.id}]`}
                                                    wrapClass={"w-400"}
                                                />

                                                <Line />

                                                <ClaimsEntrySingleView
                                                    claim={claim}
                                                    type="sales"
                                                />

                                                <Space />

                                            </>

                                        )}

                                    </React.Fragment>

                                );

                            } else {

                                return (
                                    <React.Fragment key={index}>

                                        <FieldSwitch
                                            form={form}
                                            label={claim.provider.company_name}
                                            name={`ids[${claim.provider.id}]`}
                                            required={false}
                                        />

                                        {get(form.values, `ids[${claim.provider.id}]`) && (

                                            <>

                                                <FieldTextArea
                                                    form={form}
                                                    label={"Notes"}
                                                    name={`notes[${claim.provider.id}]`}
                                                    wrapClass={"w-400"}
                                                />

                                                <Line />

                                                <ClaimsEntrySingleView
                                                    claim={claim}
                                                    type="service"
                                                />

                                                <Space />

                                            </>

                                        )}

                                    </React.Fragment>

                                );

                            }

                        })}

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Save"}
                                loading={autoGenerate.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={claims ? handleReset : form.reset}
                            />
                        </ButtonRow>

                    </Form>

                }

                {claims === null &&

                    <Form
                        form={form}
                        onSubmit={handleAutoGenerate}
                        loading={autoGenerate.loading}
                    >

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24 }}>

                                <FieldRadio
                                    button
                                    form={form}
                                    label={"Type"}
                                    name="type"
                                    required={true}
                                    defaultValue={form.defaults.type}
                                    options={[
                                        { label: "Merchant Advisor Claim", value: "sales" },
                                        { label: "Service & Installation Claim", value: "service" },
                                    ]}
                                />

                            </Col>

                            {form.values.type === "sales" &&
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldDate
                                        form={form}
                                        label={"Installation Date"}
                                        name={"date"}
                                        required={true}
                                        defaultValue={form.defaults.date}
                                        dateMode={"month"}
                                        dateFormat={"MMMM YYYY"}
                                    />

                                </Col>
                            }

                            {form.values.type === "service" &&
                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldDate
                                        form={form}
                                        label={"Cut-Off Date (Documents Verified)"}
                                        name={"date"}
                                        required={true}
                                        defaultValue={form.defaults.date}
                                    />

                                </Col>
                            }

                            {form.values.type === "service" &&

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldRelationship
                                        form={form}
                                        label={"Service Provider (Optional)"}
                                        name='provider_id'
                                        required={false}
                                        model={'Providers'}
                                        preload={true}
                                        defaultValue={form.defaults.provider_id}
                                    />

                                </Col>
                            }

                        </Row>

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Auto Generate Claims"}
                                loading={autoGenerate.loading}
                            />
                            <Button
                                label={"Reset"}
                                type={"faded"}
                                onClick={form.reset}
                            />
                        </ButtonRow>

                    </Form>
                }

            </Panel>
        </Col>

    ), [form.hash, autoGenerate.loading, process.loading]);

}

export default ClaimsCreateForm;
