import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import InstallationsApi from '../InstallationsApi';
import { FieldCheckbox, MetaTable, Space } from '@dex/bubl-dash';
import MerchantsMidTidView from '../../Merchants/Components/MerchantsMidTidView';

const InstallationStageQualityControl: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = InstallationsApi.update(data.id, "stage_quality_control");

    const checklist = [
        { value: "terminal", label: "Terminal" },
        { value: "software", label: "Download Software" },
        { value: "pma_sticker", label: "PMA Sticker" },
        { value: "paper_roll", label: "Paper Roll" },
        { value: "power_cord", label: "Power Cord & Adapter" },
        { value: "phone_line_spliter", label: "Phone Line & Spliter" },
        { value: "user_guide", label: "User Guide" },
        { value: "verify_transaction_slip", label: "Verify Transaction Slip" },
        { value: "mid", label: `Check MID : ${data.merchant.mid}` },
        { value: "tid", label: `Check TID : ${data.merchant.tid}` },
        // { value: "sim_card_no", label: `SIM Card No : ${data.sim_card_no}` },
    ];
    if (data.serial) checklist.push({ value: "serial", label: `Serial No. : ${data.serial}` });
    if (data.sim_card_serial) checklist.push({ value: "sim_card_no", label: `SIM Card No. : ${data.sim_card_serial}` });
    if (data.swivel_serial) checklist.push({ value: "swivel_serial", label: `SWIVEL Serial No. : ${data.swivel_serial}` });
    if (data.router_serial) checklist.push({ value: "router_serial", label: `Router Serial No. : ${data.router_serial}` });
    if (data.docking_serial) checklist.push({ value: "docking_serial", label: `Docking Serial No. : ${data.docking_serial}` });
    const validateValues = useCallback((values) => {

        if (!values.quality_control_checklist || checklist.length !== values.quality_control_checklist.length)
            throw new Error("Please complete all items in the checklist.");

    }, []);

    const reverse = InstallationsApi.update(data.id, "stage_reverse");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            validate={validateValues}
            name={"quality_control"}
            heading={"Quality Control"}
            editCaps={["stage_quality_controlInstallations"]}
            editable={false}
            showForm={data.quality_control !== "yes"}
            showMeta={data.quality_control === "yes"}
            passed={data.quality_control === "yes"}
            showSave={form.changed}
            reverse={data.quality_control === "yes" ? null : reverse}
            last_stage={"terminal_personalization"}
            columnA={[
                {
                    label: "Checklist",
                    key: "quality_control_checklist",
                    render: () => "Passed",
                }
            ]}
            columnB={[

            ]}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 12 }}>

                            <FieldCheckbox
                                form={form}
                                name={"quality_control_checklist"}
                                label={"Checklist"}
                                required={true}
                                defaultValue={form.defaults.quality_control_checklist}
                                list
                                options={checklist}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            <MetaTable
                                columnSize={{ xs: 8 }}
                                data={data}
                                columnA={[
                                    {
                                        label: "Standard Applications",
                                        key: "merchant.standard_applications",
                                    },
                                ]}

                                columnB={[
                                    {
                                        label: "Optional Applications",
                                        key: "merchant.optional_applications",
                                    },
                                ]}

                                columnC={[
                                ]}

                            />

                            <Space height={"small"} />

                            <MerchantsMidTidView
                                data={data.merchant}
                                form={form}
                                passed={data.tests}
                            />

                        </Col>

                    </Row>
                </>
            }
        />

    ), [props, form.hash, process.loading, reverse.loading]);

}

export default InstallationStageQualityControl;
