import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import { loop } from '@dex/bubl-helpers';
import useApp from '@dex/bubl-dash/src/lib/functions/useApp';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import FieldAddress from '@dex/bubl-dash/src/lib/components/Fields/FieldAddress';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { FieldAutocomplete, FieldSwitch, Seperator } from '@dex/bubl-dash';
import { merchantApplications } from '../../../functions/sharedMerchant';

const ServiceStageProgramming: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_programming");

    const reverse = ServicesApi.update(data.id, "stage_reverse");
    const canReverse = data.status === "outbound";

    const app = useApp();

    const [settings] = app.store("appSettings");

    const applications = useMemo(() => {


        const { applications, defaults } = merchantApplications(settings);

        // if (data.type === "swap_plan") {

        // form.handleChange({ name: "standard_applications", value: defaults.standard, reset: true, silent: true });
        // form.handleChange({ name: "optional_applications", value: defaults.optional, reset: true, silent: true });
        // form.handleChange({ name: "qr_applications", value: defaults.qr, reset: true, silent: true });

        // }

        return applications;

    }, [settings]);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            reverse={false}
            name={"programming"}
            heading={"Programming"}
            editCaps={["stage_programmingServices"]}
            editable={false}
            showForm={data.programming !== "yes"}
            showMeta={data.programming === "yes"}
            passed={data.programming === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Change Software",
                    key: "change_software",
                    format: "titleCase"
                },
                data.change_software === "yes" && {
                    label: "Software Version",
                    key: "software_version",
                },
                data.change_software === "yes" && {
                    label: "OS Version",
                    key: "os_version",
                },

            ].filter(Boolean)}
            columnB={[
                {
                    label: "Change Application / MID & TID",
                    key: "change_schemes",
                    format: "titleCase"
                },
                data.change_schemes === "yes" && {
                    label: "Standard Applications",
                    key: "standard_applications",
                },
                data.change_schemes === "yes" && {
                    label: "Optional Applications",
                    key: "optional_applications",
                },

            ].filter(Boolean)}
            columnC={[
                {
                    label: "Change Address",
                    key: "change_address",
                    format: "titleCase"
                },
                data.change_address === "yes" && {
                    label: "Installation Address",
                    key: "installation_address",
                    format: "address"
                },
                {
                    label: "Change Merchant",
                    key: "change_merchant",
                    format: "titleCase"
                },
                data.change_merchant === "yes" && {
                    label: "Company Name",
                    key: "company_name",
                },
                data.change_merchant === "yes" && {
                    label: "Trading Name",
                    key: "trading_name",
                    hideIfEmpty: true,
                }
            ].filter(Boolean)}
            fields={
                <>
                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Change Software"}
                                name='change_software'
                                required={true}
                                defaultValue={form.defaults.change_software}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.change_software === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        label={"Software Version"}
                                        name='software_version'
                                        required={false}
                                        defaultValue={form.defaults.software_version}
                                        group='software_version'
                                        preload
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 8 }}>

                                    <FieldAutocomplete
                                        form={form}
                                        label={"OS Version"}
                                        name='os_version'
                                        required={false}
                                        defaultValue={form.defaults.os_version}
                                        group='os_version'
                                        preload
                                    />

                                </Col>

                            </>

                        )}
                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Change Applications / MID & TID"}
                                name='change_schemes'
                                required={true}
                                defaultValue={form.defaults.change_schemes}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.change_schemes === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldCheckbox
                                        form={form}
                                        label={"Standard Applications"}
                                        name='standard_applications'
                                        required={false}
                                        defaultValue={form.defaults.standard_applications}
                                        options={applications.standard}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>


                                    <FieldCheckbox
                                        form={form}
                                        label={"Optional Applications"}
                                        name='optional_applications'
                                        required={false}
                                        defaultValue={form.defaults.optional_applications}
                                        options={applications.optional}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>


                                    <FieldCheckbox
                                        form={form}
                                        label={"QR Applications"}
                                        name='qr_applications'
                                        required={false}
                                        defaultValue={form.defaults.qr_applications}
                                        options={applications.qr}
                                    />

                                </Col>

                            </>

                        )}

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Change Merchant Details"}
                                name='change_merchant'
                                required={true}
                                defaultValue={form.defaults.change_merchant}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.change_merchant === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Company Name"}
                                        name='company_name'
                                        required={true}
                                        defaultValue={form.defaults.company_name}
                                        transform={'uppercase'}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Trading Name"}
                                        name='trading_name'
                                        required={false}
                                        defaultValue={form.defaults.trading_name}
                                        transform={'uppercase'}
                                    />

                                </Col>

                            </>

                        )}
                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                label={"Change Address"}
                                name='change_address'
                                required={true}
                                defaultValue={form.defaults.change_address}
                                options={[
                                    { label: "Yes", value: "yes" },
                                    { label: "No", value: "no" },
                                ]}
                            />

                        </Col>

                        {form.values.change_address === "yes" && (

                            <>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldAddress
                                        form={form}
                                        label={"Billing Address"}
                                        name='billing_address'
                                        required={true}
                                        defaultValue={form.defaults.billing_address}
                                        allowedCountries={["Malaysia"]}
                                        transform={'uppercase'}
                                        maxLength={{
                                            line1: 120,
                                            line2: 120,
                                            city: 13,
                                            zipcode: 5,
                                        }}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 16 }}>

                                    <FieldSwitch
                                        form={form}
                                        label={"Installation Address same as Billing Address?"}
                                        name='installation_same'
                                        required={false}
                                        defaultValue={form.defaults.installation_same}
                                    />

                                </Col>

                                {!form.values.installation_same &&

                                    <Col col={{ xs: 24, md: 16 }}>

                                        <FieldAddress
                                            form={form}
                                            label={"Installation Address"}
                                            name='installation_address'
                                            required={false}
                                            defaultValue={form.defaults.installation_address}
                                            allowedCountries={["Malaysia"]}
                                            transform={'uppercase'}
                                            maxLength={{
                                                line1: 120,
                                                line2: 120,
                                                city: 13,
                                                zipcode: 5,
                                            }}
                                        />

                                    </Col>

                                }


                            </>

                        )}
                    </Row>

                    <Row gutter={8} edge={true}>



                        <Col col={{ xs: 24, md: 16 }}>

                            <FieldTextArea
                                form={form}
                                label={"Additional Notes"}
                                name='notes'
                                required={false}
                            />

                        </Col>

                    </Row>

                </>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ServiceStageProgramming;
