import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import Stage from '../../../elements/Stage/Stage';
import ServicesApi from '../ServicesApi';
import { Alert, FieldCheckbox, FieldHidden, FieldRadio, FieldTextArea } from '@dex/bubl-dash';
import { date, loop, titleCase } from '@dex/bubl-helpers';

const ServiceStageCheckComplete: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ServicesApi.update(data.id, "stage_check_complete");

    let checklist: any = [];

    if (data.type === "retrieval" || data.type === "training") {

        checklist = [
            {
                value: "Service Date",
                label: "Service Date - " + date(data.service_date),
                message: "Service Date is incorrect, please update."
            },
            {
                value: "Service Report Photo",
                label: "Service Report Photo",
                message: "Service Report Photo is incorrect, please reupload."
            },
        ];

    }
    else if (data.type === "annual_visitation") {

        checklist = [
            {
                value: "Service Date",
                label: "Service Date - " + date(data.service_date),
                message: "Service Date is incorrect, please update."
            },
            {
                value: "Service Report Photo",
                label: "Service Report Photo",
                message: "Service Report Photo is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Back",
                label: "Terminal Photo Back",
                message: "Terminal Photo Back is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Front",
                label: "Terminal Photo Front",
                message: "Terminal Photo Front is incorrect, please reupload."
            },
            {
                value: "Transaction Slips",
                label: "Transaction Slips",
                message: "Transaction Slips is incorrect, please reupload."
            },
        ];

    } else if ((data.type === "programming" && data.mode_of_update === "onsite_update")) {

        checklist = [
            {
                value: "Service Date",
                label: "Service Date - " + date(data.service_date),
                message: "Service Date is incorrect, please update."
            },
            {
                value: "Service Report Photo",
                label: "Service Report Photo",
                message: "Service Report Photo is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Back",
                label: "Terminal Photo Back",
                message: "Terminal Photo Back is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Front",
                label: "Terminal Photo Front",
                message: "Terminal Photo Front is incorrect, please reupload."
            },
        ];

    } else if (data.type === "fault_call" || data.type === "swap_plan" || (data.type === "programming" || data.mode_of_update === "onsite_swap")) {

        checklist = [
            {
                value: "Service Date",
                label: "Service Date - " + date(data.service_date),
                message: "Service Date is incorrect, please update."
            },
            data.terminal_swapped && {
                value: "Terminal Swapped",
                label: "Terminal Swapped - " + titleCase(data.terminal_swapped),
                message: "Terminal Swapped is incorrect, please update."
            },
            data.sim_card_swapped && {
                value: "Sim Card Swapped",
                label: "Sim Card Swapped - " + titleCase(data.sim_card_swapped),
                message: "Sim Card Swapped is incorrect, please update."
            },
            data.swivel_swapped && {
                value: "Swivel Swapped",
                label: "Swivel Swapped - " + titleCase(data.swivel_swapped),
                message: "Swivel Swapped is incorrect, please update."
            },
            data.router_swapped && {
                value: "Router Swapped",
                label: "Router Swapped - " + titleCase(data.router_swapped),
                message: "Router Swapped is incorrect, please update."
            },
            data.docking_swapped && {
                value: "Docking Swapped",
                label: "Docking Swapped - " + titleCase(data.docking_swapped),
                message: "Docking Swapped is incorrect, please update."
            },
            data.adapter_swapped && {
                value: "Power Adapter Swapped",
                label: "Power Adapter Swapped - " + titleCase(data.adapter_swapped),
                message: "Power Adapter Swapped is incorrect, please update."
            },
            {
                value: "Service Report Photo",
                label: "Service Report Photo",
                message: "Service Report Photo is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Back",
                label: "Terminal Photo Back",
                message: "Terminal Photo Back is incorrect, please reupload."
            },
            {
                value: "Terminal Photo Front",
                label: "Terminal Photo Front",
                message: "Terminal Photo Front is incorrect, please reupload."
            },
        ].filter(Boolean);

    } else {
        return
    }



    const [messages, setMessages] = useState<any>(data.check_messages);

    const onChange = useCallback((values) => {

        const messages = [];

        if (!values) values = [];

        loop(checklist, (item) => {

            if (values.includes(item.value)) return;

            // @ts-ignore
            if (item.message) messages.push(item.message);

        });

        setMessages(messages);


    }, []);

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"check_complete"}
            heading={"Check Complete"}
            editable={false}
            editCaps={["stage_check_completeServices"]}
            showForm={data.check_complete !== "yes"}
            showMeta={data.check_complete}
            passed={data.check_complete === "yes"}
            showSave={form.changed}
            columnA={[
                {
                    label: "Check Complete",
                    key: "check_checklist",
                    render: () => "Passed"
                },
            ].filter(Boolean)}
            columnB={[]}
            columnC={[]}
            fields={
                <>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldCheckbox
                                form={form}
                                name={"check_checklist"}
                                label={"Checklist"}
                                required={true}
                                defaultValue={form.defaults.check_checklist}
                                options={checklist}
                                onChange={onChange}
                                list
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 12 }}>

                            {messages && messages.length > 0 &&
                                <>
                                    <Alert
                                        type='warning'
                                        message={"Incomplete Items"}
                                        description={
                                            <>
                                                {messages.map((message, index) => (
                                                    <p key={index}>- {message}</p>
                                                ))}
                                            </>
                                        }
                                    />

                                    <FieldHidden
                                        form={form}
                                        name={"check_messages"}
                                        value={messages}
                                    />

                                    <FieldTextArea
                                        form={form}
                                        label={"Remarks To Technician"}
                                        name='check_remarks'
                                        required={false}
                                        defaultValue={form.defaults.check_remarks}
                                    />
                                </>
                            }

                        </Col>

                    </Row>

                </>
            }
        />

    ), [data, form.hash, process.hash, messages]);
}

export default ServiceStageCheckComplete;
