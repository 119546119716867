import { FiExternalLink } from "react-icons/fi";

export const wpOldLink = (data) => {

    if (!data) return null;
    if (!data.old_id) return null;

    return {
        label: <><FiExternalLink size={16} /></ >,
        path: `https://alt.pmadvisors.my/?p=${data.old_id}`,
        target: "_blank",
        caps: ['staff'],
        size: "small",
        type: "ghost",
        title: "View in old system",
    }

};