import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import ParamTemplatesCreateEditForm from "./Components/ParamTemplatesCreateEditForm";
import ParamTemplatesApi from "./ParamTemplatesApi";

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: ParamTemplatesCreateEditForm,
                getCreate: ParamTemplatesApi.create,
                getClone: ParamTemplatesApi.getOne,
                defaults: { params: [] },
                successMessage: "Param Template",
                successRouteName: settings.key + "View",
                idKey: settings.idKey,
            },
        },
    });

}