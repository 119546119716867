import SinglePage from '@dex/bubl-dash/src/lib/pages/SinglePage';
import MerchantsSingleView from './Components/MerchantsSingleView';
import MerchantsSingleEdit from './Components/MerchantsSingleEdit';
import MerchantsSingleReuse from './Components/MerchantsSingleReuse';
import MerchantsSingleCancel from './Components/MerchantsSingleCancel';
import MerchantsActivity from './Components/MerchantsActivity';
import ActivityPage from '@dex/bubl-dash/src/lib/pages/ActivityPage';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "View",
        zone: settings.zone,
        path: settings.key + "/:id/view",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
                getButtons: settings.singleButtons,
            },
            page: MerchantsSingleView,
        },
    });

    app.addRoute({
        name: settings.key + "Edit",
        zone: settings.zone,
        path: settings.key + "/:id/edit",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: MerchantsSingleEdit,
        },
    });

    app.addRoute({
        name: settings.key + "Cancel",
        zone: settings.zone,
        path: settings.key + "/:id/cancel",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: MerchantsSingleCancel,
        },
    });

    app.addRoute({
        name: settings.key + "Reuse",
        zone: settings.zone,
        path: settings.key + "/:id/reuse",
        caps: settings.viewCaps,
        component: SinglePage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
            page: MerchantsSingleReuse,
        },
    });

    app.addRoute({
        name: settings.key + "Activity",
        zone: settings.zone,
        path: settings.key + "/:id/activity",
        caps: settings.viewCaps,
        component: ActivityPage,
        componentProps: {
            getFetch: settings.singleFetch,
            getFetchParams: settings.singleFetchParams,
            header: {
                getTitle: settings.singleTitle,
                getTabs: settings.singleTabs,
            },
        },
    });

}