import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import useForm from '@dex/bubl-dash/src/lib/functions/useForm';
import ProformaInvoicesApi from '../ProformaInvoicesApi';
import Stage from '../../../elements/Stage/Stage';
import { FieldCheckbox, FieldRadio, FieldTextArea, useApp } from '@dex/bubl-dash';

const ProformaInvoicesStagesVoidInvoice: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm(data);

    const process = ProformaInvoicesApi.update(data.id, "stage_void");

    return useMemo(() => (

        <Stage
            data={data}
            form={form}
            process={process}
            name={"void_status"}
            heading={"Void Invoice"}
            editCaps={["stage_voidProformaInvoices"]}
            editable={false}
            showForm={data.payment_verified !== "yes" && data.status !== "void"}
            showMeta={data.status === "void"}
            showSave={form.changed}
            passed={data.status === "void"}
            columnA={[
                {
                    key: "status",
                    label: "Status",
                    format: "titleCase"
                },
                {
                    key: "void_note",
                    label: "Notes",
                    format: "nl2br"
                }
            ]}
            columnB={[

            ]}
            fields={

                <Col col={{ xs: 24, md: 16 }}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={"status"}
                                label={"Status"}
                                defaultValue={form.defaults.status}
                                options={[
                                    { label: "Void This Invoice", value: "void" },
                                ]}
                            // disable={form.values.status !== "void"}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                name={"void_note"}
                                required={true}
                                label={"Note"}
                                defaultValue={form.defaults.void_note}
                            />

                        </Col>

                    </Row>

                </Col>
            }
        />

    ), [props, form.hash, process.loading]);

}

export default ProformaInvoicesStagesVoidInvoice;
