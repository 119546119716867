import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Link from '@dex/bubl-dash/src/lib/components/Link/Link';

import './DashboardMenu.module.scss';
import { Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';

const DashboardMenu: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store('nav');

    const [user] = app.store('currentUser');

    const zones = app.getZones(user);

    const [activeZone, setActiveZone] = app.store('activeZone');

    return useMemo(() => (

        <div styleName="menu">

            <div styleName="zones">

                {zones.map((zone, index) => {

                    if (zone.hidden) return (<React.Fragment key={index}></React.Fragment>);

                    return (

                        <Link styleName="zone" key={index} path={zone.path} onClick={() => { setActiveZone(zone) }}>
                            <i>
                                {zone.icon &&
                                    <>
                                        {/* @ts-ignore */}
                                        <SVG src={zone.icon} styleName={"icon"} />
                                    </>
                                }
                            </i>
                            <span>{zone.label}</span>
                        </Link>

                    )

                })}

            </div>

        </div>

    ), [props, activeZone])

}

export default DashboardMenu;