import React from 'react';
import { DataTable, Seperator } from '@dex/bubl-dash';

const ServicesSingleHistory: React.FC<any> = (props: any) => {

    const { data, heading } = props;

    const columns = [
        {
            label: "Date",
            key: "date",
            format: "date",
            style: { width: "120px" },
        },
        {
            label: "Type",
            key: "type",
            style: { width: "140px" },
        },
        {
            label: "Remarks",
            key: "remarks",
            format: "nl2br",
            style: { width: "300px" },
        },
        {
            label: "Attachments",
            key: "attachments",
            format: "file",
            style: { width: "220px" },
        },
        {
            label: "Submitted",
            key: "onandby",
            format: "onandby",
            style: { width: "160px" },
        },
    ];

    return (

        <>

            {data.history && data.history.length > 0 &&
                <>

                    <Seperator
                        heading={heading}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <DataTable
                        className={"mini-table"}
                        columns={columns}
                        data={data.history}
                    />

                </>
            }
        </>

    );

}

export default ServicesSingleHistory;
